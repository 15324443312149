import RedsysDataphone from '@/integrations/dataphone/redsysDataphone.js'
import AdyenDataphone from '@/integrations/dataphone/adyenDataphone'
import DevDataphone from '@/integrations/dataphone/devDataphone.js'
import { nextTick } from 'vue'
import { Capacitor } from '@capacitor/core'
import { useConfigStore } from '@/store/config'
import { useDialog } from '@last/core-ui/paprika'
import i18n from '@/i18n'

class Dataphone {
  constructor() {}
  async init(config, preferredConfigId) {
    const platform = Capacitor.getPlatform()
    try {
      let configStore = useConfigStore()

      if (!import.meta.env.PROD || platform === 'web') {
        this.device = new DevDataphone()
      } else if (configStore.currentAdyenDataphone) {
        this.device = new AdyenDataphone(configStore.currentAdyenDataphone)
      } else {
        const preferredDataphone = preferredConfigId
        if (!configStore.config.paymentMethods.includes('Dataphone')) return
        for (let dataphoneConfig of config.dataphoneConfigs) {
          if (
            dataphoneConfig.id === preferredDataphone ||
            (!preferredDataphone && dataphoneConfig.business) ||
            platform === 'web'
          ) {
            // config preferredDataphone or first dataphone, to avoid duplicating instances, we check if it's already created
            this.device =
              this.device ?? new RedsysDataphone(dataphoneConfig, platform)
            break
          }
        }
        if (!this.device) return
      }
      this.device.init()
    } catch {
      throw new Error('No dataphone capability')
    }
  }

  async charge({ amount, paymentId, tabId }) {
    if (this.initialized()) {
      let dialogInstance
      try {
        let cancelled = false
        const dialog = useDialog()
        dialogInstance = dialog({
          icon: 'loading-spinner',
          iconAnimation: 'animate-spin',
          title: i18n.global.t('payment-screen.payment-in-process'),
          content: i18n.global.t('payment-screen.check-the-dataphone'),
          mainLabel: i18n.global.t('payment-screen.cancel-operation'),
          dismissable: false,
          hiddenClose: true,
          onConfirm: () => {
            cancelled = true
          }
        })
        const metadata = await this.device.charge({ amount, paymentId, tabId })
        if (cancelled) {
          this.device.cancel({ paymentId, tabId })
          throw new Error('Payment cancelled')
        }
        await nextTick()
        dialogInstance.close()
        return metadata
      } catch (e) {
        dialogInstance.close()
        throw e
      }
    }
  }

  async refund(payment, tabId) {
    if (this.initialized()) {
      let res = await this.device.refund(payment, tabId)
      return res
    } else {
      return { error: 'Not initialized' }
    }
  }

  initialized() {
    return this.device?.initialized || false
  }
}

export default new Dataphone()

import type { DirectiveBinding, ObjectDirective } from 'vue'

type BindingData = {
  color?: string
  duration?: string
}

interface ExtendedDirective extends ObjectDirective<HTMLElement, BindingData> {
  pointerdownEvent: (e: PointerEvent) => void
  transitionEndEvent: (e: TransitionEvent) => void
  originalBackgroundColor: string
  transition: boolean
}

const getDir = (binding: DirectiveBinding<BindingData>) => {
  return binding.dir as ExtendedDirective
}

export const vTapHint: ObjectDirective<HTMLElement, BindingData> = {
  mounted(el, binding) {
    const backgroundColorRGB = getStyle(el, 'backgroundColor')
    const backgroundColorHex = convert(backgroundColorRGB)
    getDir(binding).originalBackgroundColor = backgroundColorHex
    getDir(binding).pointerdownEvent = () => pointerdownEvent(el, binding)
    el.addEventListener('pointerdown', getDir(binding).pointerdownEvent)
  },
  beforeUnmount(el, binding) {
    el.removeEventListener('pointerdown', getDir(binding).pointerdownEvent)
  }
}

const pointerdownEvent = (
  el: HTMLElement,
  binding: DirectiveBinding<BindingData>
) => {
  getDir(binding).transitionEndEvent = () => transitionEndEvent(el, binding)
  if (getDir(binding).transition) {
    removeTransitionStyles(el)
    el.removeEventListener('transitionend', getDir(binding).transitionEndEvent)
    getDir(binding).transition = false
  }

  getDir(binding).transition = true
  addTransitionStyles(el, binding)
  el.addEventListener('transitionend', getDir(binding).transitionEndEvent)
}

const transitionEndEvent = (
  el: HTMLElement,
  binding: DirectiveBinding<BindingData>
) => {
  removeTransitionStyles(el)
  el.removeEventListener('transitionend', getDir(binding).transitionEndEvent)
  getDir(binding).transition = false
}

const addTransitionStyles = (
  el: HTMLElement,
  binding: DirectiveBinding<BindingData>
) => {
  el.style.backgroundColor = binding.value.color || '#cccccc'
  setTimeout(() => {
    el.style.transition = `background-color ${binding.value.duration || '500ms'} ease-in-out`
    el.style.backgroundColor = getDir(binding).originalBackgroundColor
  }, 0)
}

const removeTransitionStyles = (el: HTMLElement): void => {
  el.style.transition = null as unknown as string
  el.style.backgroundColor = null as unknown as string
  el.getBoundingClientRect()
}

const getStyle = (el: HTMLElement, styleProp: string): string => {
  return (
    document?.defaultView
      ?.getComputedStyle(el, null)
      .getPropertyValue(styleProp) || ''
  )
}

const convert = (rgb: string): string => {
  const match = rgb.match(/^rgb\((\d+), \s*(\d+), \s*(\d+)\)$/)
  if (!match) return rgb
  function hexCode(i: string) {
    return ('0' + parseInt(i).toString(16)).slice(-2)
  }
  return '#' + hexCode(match[1]) + hexCode(match[2]) + hexCode(match[3])
}

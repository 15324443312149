<template>
  <l-modal
    :title="$t('ordering.send-kitchen-note')"
    size="small"
    @close="$emit('close')"
  >
    <div
      v-for="course in filteredCourses"
      :key="course"
      class="flex items-center bg-n-700 rounded-xl py-6 px-4 mb-4"
      @click="sendKitchenNote(course)"
    >
      {{ course }}
      <icon name="arrow-right" class="ml-auto text-v-300" />
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { LModal, Icon } from '@last/core-ui/paprika'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import sync from '@/sync/service'
import { useCatalogStore } from '@/store/catalog'
import { useTabsStore } from '@/store/tabs'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useTabs } from '@/composables/useTabs'

const props = defineProps<{
  tabId: string
}>()

const emit = defineEmits(['close'])

const catalogStore = useCatalogStore()
const tabsStore = useTabsStore()

const { allCourses } = storeToRefs(catalogStore)
const { kitchenOrders } = storeToRefs(tabsStore)
const { tab } = useTabs(props.tabId)

const filteredCourses = computed(() => {
  const allCoursesMapped = allCourses.value.map(course => {
    return course.label
  })

  const kitchenOrdersCourses = new Set(
    tab.value.kitchenOrders.flatMap(id =>
      kitchenOrders.value[id].versions.flatMap(version =>
        version.products.map(prod => prod.course)
      )
    )
  )

  const kitchenOrdersCoursesSorted = new Set(
    allCoursesMapped.filter(course => kitchenOrdersCourses.has(course))
  )

  const kitchenOrdersCoursesNew = new Set(
    [...kitchenOrdersCourses].filter(
      course => !allCoursesMapped.includes(course)
    )
  )
  return new Set([...kitchenOrdersCoursesSorted, ...kitchenOrdersCoursesNew])
})

function sendKitchenNote(courseLabel: string) {
  TicketPrinter.printKitchenNote(courseLabel, tab.value)
  sync.record('kitchenNoteAdded', { courseLabel, tab: tab.value })
  emit('close')
}
</script>

<template>
  <full-screen :title="$t('end-shift.title')" @close="goBack">
    <tills-view
      v-if="!closingTillId"
      @print-preview="printX(true)"
      @start-closing-till="startClosingTill"
      @leave="leave"
    />

    <close-till
      v-if="closingTillId"
      :till-id="closingTillId"
      @close="closingTillId = null"
    />
  </full-screen>
</template>

<script setup lang="ts">
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import FullScreen from '../core/FullScreen.vue'
import sync from '@/sync/service'
import TillsView from './TillsView.vue'
import CloseTill from './CloseTill.vue'
import moment from 'moment'
import { useTillStore } from '@/store/till'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useDialog } from '@last/core-ui/paprika'
import { useI18n } from 'vue-i18n'
import { getLocationShiftsQuantity } from '@/api/shift'

const router = useRouter()
const tillStore = useTillStore()
const { startedShifts } = storeToRefs(tillStore)
const dialog = useDialog()
const { t } = useI18n()

const closingTillId = ref(null)
const multipleTurns = ref(false)
const waitingForClosure = ref(false)

onMounted(async () => {
  const today = moment().subtract(2, 'hours')
  const response = await getLocationShiftsQuantity({
    startDate: today.format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD')
  })
  multipleTurns.value = response.data > 1
})

function goBack() {
  if (startedShifts.value.length === 0) {
    router.push({ name: 'employees' })
  } else {
    router.go(-1)
  }
}

function leave() {
  dialog({
    title: t('end-shift.title'),
    content: t('report.z-report-confirmation'),
    mainLabel: t('end-shift.close-day'),
    secondaryLabel: t('end-shift.close-shift'),
    onConfirm: () => closeDay(),
    onSecondary: () => closeShift()
  })
}

function printX(preview = false) {
  TicketPrinter.printXReport(preview)
}

async function printZ() {
  await TicketPrinter.printZReport()
}

async function printXAndZ() {
  await TicketPrinter.printXReport()
  await TicketPrinter.printZReport()
}

function startClosingTill(till: any) {
  closingTillId.value = till.id
}

function closeShift() {
  if (waitingForClosure.value) {
    return
  }
  waitingForClosure.value = true
  printX()
  waitingForClosure.value = false
  router.push({ name: 'employees' })
}

function closeDay() {
  if (waitingForClosure.value) {
    return
  }
  sync.record('dayLastTillClosed', {})
  waitingForClosure.value = true
  sync.observeEnd(async () => {
    if (multipleTurns.value) {
      await printXAndZ()
    } else {
      await printZ()
    }
    waitingForClosure.value = false
    router.push({ name: 'employees' })
  })
}
</script>

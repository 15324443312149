<template>
  <div data-testid="l-pin-input">
    <div class="flex flex-shrink-0 flex-row gap-x-2.5">
      <div
        v-for="(num, index) of new Array(length)"
        :key="index"
        data-testid="l-pin-input__pin"
        class="flex flex-shrink-0 cursor-pointer rounded-[10px] md:hover:border md:hover:border-v-300 text-v-300"
        :class="[
          {
            'w-12 h-12': size === 'small',
            'w-14 h-14': size === 'medium',
            'border border-v-300': isFocused && focusedPosition === index,
            border: hasError
          },
          ...useBackgroundColorsClasses(),
          ...useErrorClasses(hasError)
        ]"
        @click="onClick"
      >
        <span class="w-full h-full flex justify-center items-center">
          <span v-if="passwordVisible && model[index]" data-testid="char">
            {{ model[index] }}
          </span>
          <span
            v-if="!passwordVisible && model[index]"
            class="block rounded-full w-2.5 h-2.5 bg-v-300"
            data-testid="dot"
          ></span>
        </span>
      </div>
      <div v-if="showEye" class="flex items-center">
        <div class="w-6 h-6">
          <vue3-lottie
            ref="eyeAnim"
            class="cursor-pointer"
            :animation-data="EyeAnimation"
            :loop="false"
            :auto-play="false"
            :direction="animationDirection"
            data-testid="l-pin-input__eye"
            @click="changeAnimationType"
          />
        </div>
      </div>
    </div>
    <input
      ref="input"
      v-model="model"
      class="text opacity-0 absolute"
      @input="manageInput"
      @focus="manageFocus"
      @blur="manageBlur"
      @keydown="resetCursorPosition"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, defineModel, useTemplateRef } from 'vue'
import { useBackgroundColorsClasses, useErrorClasses } from '../classes'
import { Vue3Lottie } from 'vue3-lottie'
import EyeAnimation from '../../assets/lottie/lottie-visibility-v-300.json'

type Props = {
  length?: number
  size?: 'small' | 'medium'
  forceUppercase?: boolean
  hasError?: boolean
  showEye?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  length: 4,
  size: 'medium',
  forceUppercase: false,
  hasError: false,
  showEye: true
})

const model = defineModel<string>({ default: '' })
const input = ref<HTMLInputElement>()
const isFocused = ref<boolean>(false)
const focusedPosition = ref<number>(0)

const passwordVisible = ref(false)
const eyeAnim = useTemplateRef('eyeAnim')
const animationDirection = ref<'forward' | 'reverse'>('forward')

const emit = defineEmits(['complete'])

onMounted(() => {
  if (model.value.length > props.length) {
    model.value = model.value.slice(0, props.length)
  }
  if (props.forceUppercase) {
    model.value = model.value.toUpperCase()
  }
})

watch(
  () => props.forceUppercase,
  value => {
    if (value) {
      model.value = model.value.toUpperCase()
    }
  }
)
function onClick(): void {
  input.value!.focus()
  input.value!.setSelectionRange(model.value.length, model.value.length)

  calculateCursorPosition()
}

function manageBlur(): void {
  isFocused.value = false
}

function manageFocus(): void {
  isFocused.value = true
}

function resetCursorPosition(): void {
  input.value!.setSelectionRange(model.value.length, model.value.length)
}

function manageInput(): void {
  focusedPosition.value = model.value.length
  let newValue = model.value
  if (model.value.length >= props.length) {
    newValue = `${model.value.substring(0, props.length - 1)}${model.value[model.value.length - 1]}`

    emit('complete')
  }
  calculateCursorPosition()

  if (props.forceUppercase) {
    newValue = newValue.toUpperCase()
  }
  model.value = newValue
}

function changeAnimationType() {
  passwordVisible.value = !passwordVisible.value
  if (passwordVisible.value) {
    animationDirection.value = 'forward'
    eyeAnim.value?.playSegments([0, 16], true)
  } else {
    animationDirection.value = 'reverse'
    eyeAnim.value?.playSegments([16, 0], true)
  }
}

function calculateCursorPosition(): void {
  let newPosition = 0
  if (model.value.length >= props.length) {
    newPosition = props.length - 1
  } else if (model.value.length > 0) {
    newPosition = model.value.length
  }

  focusedPosition.value = newPosition
}
</script>

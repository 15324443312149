import { PrinterDriver } from '../types'

/**
 * Virtual printer to emulate a KDS screen
 */
export default class KDS implements PrinterDriver {
  constructor() {}

  printImage() {
    return undefined
  }

  openCashDrawer() {
    return
  }
}

<template>
  <transition-group
    name="product-list"
    class="product-list grid gap-2 overflow-scroll scrolling-touch relative"
    tag="div"
    @before-leave="beforeLeave"
  >
    <menu-product
      v-for="product in filteredProducts"
      :key="product.id"
      :product="product"
      :component-style="style"
      class="w-full sm:w-auto"
      :selected-quantity="productTotalSelected(product.id)"
      @selected="
        (product, quantity) => $emit('selectedProduct', product, quantity)
      "
    />
  </transition-group>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import MenuProduct from '@/components/ordering/MenuProduct.vue'
import catalog from '@last/core/src/catalog'
import moment from 'moment'
import app from '@/app'
import { useTabs } from '@/composables/useTabs'
import { CatalogProduct } from '@/types'

const props = defineProps<{
  products?: CatalogProduct[]
}>()

defineEmits(['selectedProduct'])

const route = useRoute()
const tabId = route.params.tabId as string

const { tab } = useTabs(tabId)

const { allProducts } = useTabs(tabId)

const style = computed(() => (app.isMobile ? 'row' : 'square'))

const filteredProducts = computed(() => {
  return (props.products || [])
    .filter(p => p.enabled)
    .filter(p => isAvailableOnSchedule(p))
})

function productTotalSelected(parentProductId: string) {
  const result = Object.values(allProducts.value)
    .filter(product => product.parentProduct === parentProductId)
    .reduce((total, product) => {
      total += product.quantity
      return total
    }, 0)
  return result
}

function isAvailableOnSchedule(product: CatalogProduct) {
  const maybeSchedulingTime = tab.value?.schedulingTime
  return catalog.isEnabledOn(
    product,
    maybeSchedulingTime ? moment(maybeSchedulingTime) : moment()
  )
}

function beforeLeave(element: Element) {
  const el = element as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.product-list {
  grid-template-columns: repeat(auto-fill, minmax(9.375rem, 1fr));
}

@media screen and (max-width: 640px) {
  .product-list {
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  }
}

.product-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.product-list-enter-active {
  transition: all 0.3s ease;
}

.product-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.product-list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}

.reservation-list-move {
  transition: all 0.2s ease;
}
</style>

import axios from 'axios'
import { lastUtils } from '@last/core'

const getTokenUrl = 'https://api.glovoapp.com/oauth/token'
const refreshTokenUrl = 'https://api.glovoapp.com/oauth/refresh'
const getStoresUrl = 'https://api.glovoapp.com/cs/sa/me'
const baseOrdersUrl = 'https://api.glovoapp.com/cs/sa/store_addresses/orders'

export default class GlovoScrapper {
  constructor(user, password) {
    this.user = user
    this.password = password
    this.scrapInterval = null
    this.headers = {
      accept: 'application/json, text/plain, */*',
      'accept-language': 'es-ES,es;q=0.9',
      'Content-Type': 'application/json',
      'Glovo-App-Platform': 'web',
      'Glovo-App-Type': 'partners',
      'Glovo-App-Version': '1.762.0',
      'Glovo-Language-Code': 'en'
    }
  }

  async startScrapping(initialData, updateToken, removeOrder, sendNewOrder) {
    this.initCallbacks(updateToken, removeOrder, sendNewOrder)
    await this.initializeData(initialData)
    this.startTimer()
  }

  initCallbacks(updateToken, removeOrder, sendNewOrder) {
    if (updateToken) this.tokenCallback = updateToken
    if (removeOrder) this.removeOrderCallback = removeOrder
    if (sendNewOrder) this.newOrderCallback = sendNewOrder
  }

  checkValidData(data) {
    if (!data.accessToken) return false
    if (!data.refreshToken) return false
    if (Object.keys(data.orders).length === 0) return false

    return true
  }

  async initializeData(data) {
    this.storeIds = data.storeIds
    if (this.checkValidData(data)) {
      this.orders = data.orders
      this.setGlovoToken(data)
    } else {
      this.orders = {}
      await this.getGlovoToken()
    }
  }

  startTimer() {
    clearInterval(this.scrapInterval)
    this.scrapInterval = setInterval(() => {
      this.handleScrappedOrders()
    }, 15 * 1000)
  }

  async handleScrappedOrders() {
    let orders = await this.getGlovoOrders()
    let newOrders = (Object.values(orders).flat() || []).reduce(
      (res, newOrder) => {
        res[newOrder.id] = newOrder
        return res
      },
      {}
    )
    Object.keys(this.orders).forEach(oldOrderId => {
      if (oldOrderId in newOrders) return
      this.removeOrderCallback(this.orders[oldOrderId])
      delete this.orders[oldOrderId]
    })

    Object.keys(newOrders).forEach(async newOrderId => {
      if (newOrderId in this.orders) return
      let orderInfo = await this.getGlovoOrder(newOrderId)
      let phoneNumber = await this.getCustomerDetails(newOrderId)
      orderInfo.customerDetails.phoneNumber = phoneNumber

      this.newOrderCallback(orderInfo)
      this.orders[newOrderId] = orderInfo
    })
  }

  setGlovoToken(data) {
    this.refreshToken = data.refreshToken
    this.headers['Authorization'] = data.accessToken
    if (!this.tokenCallback) return
    this.tokenCallback(data)
  }

  haveTokenExpired(error) {
    return error === 'invalid_token'
  }

  async retryRequestIfTokenExpired(e, callBack) {
    if (this.haveTokenExpired(e.response?.data?.errorCode)) {
      await this.refreshGlovoToken()
      await lastUtils.sleep(500)
      await callBack()
    }
  }

  async refreshGlovoToken() {
    try {
      let data = {
        grantType: 'REFRESH_TOKEN',
        refreshToken: this.refreshToken
      }
      let response = await axios.post(refreshTokenUrl, data, {
        headers: this.headers
      })
      this.setGlovoToken(response.data)
    } catch (e) {
      throw new Error(e)
    }
  }

  async getGlovoToken() {
    try {
      let data = {
        userType: 'STORE_ADMIN',
        username: this.user,
        grantType: 'PASSWORD',
        password: this.password
      }
      let response = await axios.post(getTokenUrl, data, {
        headers: this.headers
      })
      this.setGlovoToken(response.data)
    } catch (e) {
      throw new Error(e)
    }
  }

  async getGlovoStores() {
    try {
      let response = await axios.get(getStoresUrl, { headers: this.headers })
      this.storeIds = response.data.store.storeAddresses
    } catch (e) {
      await this.retryRequestIfTokenExpired(e, this.getGlovoStores())
    }
  }

  async getGlovoOrders() {
    let getOrdersUrl = `${baseOrdersUrl}?storeAddressIds=${encodeURIComponent(
      this.storeIds
    )}`
    try {
      let response = await axios.get(getOrdersUrl, {
        data: {},
        headers: this.headers
      })
      return response.data
    } catch (e) {
      await this.retryRequestIfTokenExpired(e, this.getGlovoOrders())
    }
  }

  async getGlovoOrder(orderId) {
    let getOrderUrl = `https://api.glovoapp.com/api/v0/partner_orders/${orderId}`
    try {
      let response = await axios.get(getOrderUrl, { headers: this.headers })
      return response.data
    } catch (e) {
      await this.retryRequestIfTokenExpired(e, this.getGlovoOrder(orderId))
    }
  }

  async getCustomerDetails(orderId) {
    let getCustomerUrl = `https://api.glovoapp.com/api/v0/partner_orders/${orderId}/customer/phone_number`
    try {
      let response = await axios.get(getCustomerUrl, { headers: this.headers })
      return response.data.data.phoneNumber
    } catch (e) {
      await this.retryRequestIfTokenExpired(e, this.getCustomerDetails(orderId))
    }
  }
}

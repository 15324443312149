<template>
  <div class="flex flex-col h-screen pb-safe overflow-hidden">
    <top-bar @back="router.go(-1)">
      <template #center>
        <tab-selector
          :selected-tab="selectedTab"
          :show-floorplan-button="showFloorplanButton"
          @update:selected-tab="updateSelectedTab"
        />
      </template>
    </top-bar>
    <keep-alive>
      <table-tabs v-if="showingFloorplan" />
      <tab-browser
        v-else
        :tabs-type="selectedTab"
        :pre-selected-tab-id="tabBrowserSelectedTabId"
      />
    </keep-alive>
  </div>
</template>

<script setup lang="ts">
import { useIdle } from '@vueuse/core'
import { watch } from 'vue'
import { useRouter } from 'vue-router'

import TopBar from '@/components/core/TopBar.vue'
import TableTabs from '@/components/tables/TableTabs.vue'
import TabBrowser from '@/components/tabs/TabBrowser.vue'
import TabSelector from '@/components/TabSelector.vue'
import { useHome } from '@/composables/useHome'
import { useConfigStore } from '@/store/config'

const { config } = useConfigStore()
const router = useRouter()

const {
  selectedTab,
  showFloorplanButton,
  tabBrowserSelectedTabId,
  showingFloorplan,
  updateSelectedTab
} = useHome()

if (config.timeoutSeconds) {
  const { idle } = useIdle(config.timeoutSeconds * 1000)

  watch(idle, idleValue => {
    if (idleValue && router.currentRoute.value.name !== 'employees') {
      router.push({ name: 'employees' })
    }
  })
}
</script>

import api from '@/api'
import { CouriersReport } from '@/types'

export function getCourierReport() {
  return api.get<CouriersReport>('/reports/couriers').then(res => res.data)
}

export function resetCourierReport(courierId: string) {
  return api
    .post(`/reports/courier/${courierId}/reset`, {})
    .then(res => res.data)
}

export function getReportZ(data?: { startDate: string; endDate: string }) {
  return api.get('/reports/z', data).then(res => res.data)
}

<template>
  <div>
    <l-field :label="$t('new-delivery.select-enterprise-label')">
      <l-select v-model="form.source" :options="deliveryCompanies" />
    </l-field>

    <template v-if="form.source !== 'OwnDelivery'">
      <l-option-selector
        v-model="form.pickupType"
        class="mb-4"
        :options="pickupTypeOptions"
      />

      <l-field :label="$t('new-delivery.client-name-label')">
        <l-input
          v-model="form.name"
          :placeholder="$t('new-delivery.client-name-placeholder')"
        />
      </l-field>

      <div class="flex gap-4">
        <l-field class="flex-1" :label="$t('new-delivery.order-id-label')">
          <l-input
            v-model="form.orderId"
            :placeholder="$t('new-delivery.order-id-placeholder')"
          />
        </l-field>

        <l-field class="flex-1" :label="$t('new-delivery.pickup-label')">
          <l-date-picker
            v-model="form.schedulingTime"
            :placeholder="$t('new-delivery.schedule-now')"
          />
        </l-field>
      </div>

      <l-field :label="$t('new-delivery.courier-name-label')">
        <l-input
          v-model="form.courierName"
          :placeholder="$t('new-delivery.courier-name-placeholder')"
        />
      </l-field>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  LField,
  LInput,
  LSelect,
  LOptionSelector,
  LDatePicker
} from '@last/core-ui/paprika'
import { computed } from 'vue'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { deliveryCompanies } from './deliverySources'
import { NewTabForm } from './types'

const form = defineModel<NewTabForm>({
  default: {}
})

const { t } = useI18n()

const pickupTypeOptions = computed(() => [
  {
    label: t('new-delivery.pickup-type-own-delivery'),
    value: 'ownDelivery'
  },
  {
    label: t('new-delivery.pickup-type-delivery'),
    value: 'delivery'
  }
])

onMounted(() => {
  form.value.source = deliveryCompanies[0].value
  form.value.pickupType = 'delivery'
})
</script>

<template>
  <div class="pt-safe">
    <div class="px-4 py-3 flex text-n-0 bg-n-900 items-center h-14">
      <div v-if="currentEmployee" class="flex-1 flex items-center">
        <slot name="left">
          <div v-if="showBack" class="ml-6 text-v-400" @click="$emit('back')">
            <div class="p-3 -m-3">
              <icon name="arrow-left" size="medium" />
            </div>
          </div>
          <router-link v-else :to="{ name: 'pos' }">
            <img src="/icon.png" alt="icon" class="h-12" />
          </router-link>
          <icon name="user" class="ml-6 mr-2" size="small" />
          {{ currentEmployee.name }}
          <div v-if="tills.cash.length > 1" class="flex items-center ml-4">
            <icon name="till" class="mr-2" size="small" />
            {{ selectedCashTill?.name }}
          </div>
        </slot>
      </div>
      <div class="flex justify-center">
        <slot name="center" />
      </div>
      <div class="flex-1 flex justify-end text-n-0 items-center">
        <l-button v-if="!isConnected" icon="wifi-x" size="small" color="red" />
        <slot name="right">
          <div v-if="trialDaysLeft || gracePeriodDaysLeft" class="mr-5 text-sm">
            {{ trialDaysLeft || gracePeriodDaysLeft }}
          </div>
          <div class="flex justify-end p-3">
            <router-link
              v-if="!showBack"
              :to="{ name: 'search' }"
              class=""
              @click="$emit('close')"
            >
              <icon name="zoom" size="medium" />
            </router-link>
          </div>
          <div
            class="relative cursor-pointer"
            @click="openNotificationsSideBar"
          >
            <div
              v-if="notificationsAmount > 0"
              class="absolute z-10 right-0 top-[-0.2rem]"
            >
              <l-badge
                class="cursor-pointer"
                mode="alert"
                type="small"
                :value="notificationsAmount"
              />
            </div>
            <vue3-lottie
              ref="bellAnim"
              width="1.8rem"
              height="1.8rem"
              :animation-data="BellAnimation"
              :loop="true"
              :auto-play="false"
            />
          </div>
          <div class="p-3 cursor-pointer" @click="showMenu = true">
            <icon name="burger" class="w-6 h-6" size="medium" />
          </div>
        </slot>
      </div>
    </div>

    <side-menu :open="showMenu" @close="showMenu = false" />
  </div>
</template>

<script setup lang="ts">
import SideMenu from '@/components/core/SideMenu.vue'
import { Icon, LBadge, LButton } from '@last/core-ui/paprika'
import { useAuthStore } from '@/store/auth'
import { useTillStore } from '@/store/till'
import { useConfigStore } from '@/store/config'
import { useStatusStore } from '@/store/status'
import { storeToRefs } from 'pinia'
import { defineProps, ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { Vue3Lottie } from 'vue3-lottie'
import BellAnimation from '@/assets/notification-V4.json'
import { useNotifications } from '@/composables/useNotifications'

type Props = {
  showBack?: boolean
}

withDefaults(defineProps<Props>(), {
  showBack: false
})

defineEmits(['back', 'close'])

const { t } = useI18n()

const bellAnim = ref<typeof Vue3Lottie>()

const { currentEmployee, billingStatus, daysLeft } = storeToRefs(useAuthStore())
const { selectedCashTill } = storeToRefs(useTillStore())
const { tills } = storeToRefs(useConfigStore())
const { isConnected } = storeToRefs(useStatusStore())

const { openNotificationsSideBar, notificationsAmount } = useNotifications()

const showMenu = ref(false)

watch(notificationsAmount, val => {
  if (bellAnim.value && val > 0) {
    bellAnim.value.play()
  } else if (bellAnim.value) {
    bellAnim.value.stop()
  }
})

const trialDaysLeft = computed(() => {
  if (billingStatus.value.stillInTrial)
    return t('topbar.trial-days-left', {
      days: daysLeft.value
    })
  return null
})

const gracePeriodDaysLeft = computed(() => {
  if (billingStatus.value.gracePeriodTriggered)
    return t('topbar.grace-period-days-left', {
      days: daysLeft.value
    })
  return null
})
</script>

<template>
  <div class="h-screen sm:bg-corner-gradient pt-safe">
    <div
      v-if="shiftsEnabled && selectedCashTill && tills.cash.length > 1"
      class="px-12 pt-4 flex justify-center"
    >
      <div class="w-full sm:w-auto">
        <l-select
          v-model="currentlySelectedCashTill"
          :options="tillOptions"
          icon="till"
          size="small"
          icon-position="left"
        />
      </div>
    </div>
    <div v-if="isElectron" class="absolute top-0 right-0 m-8 flex">
      <l-button icon="minus" type="text" @click="minimizeApp()" />
      <l-button icon="turn-off" type="text" @click="closeApp()" />
    </div>
    <div
      v-if="selectionType === 'names'"
      class="flex flex-col items-center pt-20 pb-32 overflow-hidden"
    >
      <div class="text-3xl text-center text-n-0 font-text font-bold mb-2 px-4">
        {{ $t('employees.title') }}
      </div>
      <div
        class="overflow-scroll scrolling-touch gap-4 p-4 max-w-[33.5rem]"
        :class="[
          listEmployees.length < 3
            ? 'flex flex-row items-center'
            : 'grid grid-cols-2 sm:grid-cols-3'
        ]"
      >
        <div
          v-for="(employee, index) in listEmployees"
          :key="employee.id"
          class="flex flex-col bg-n-700 border border-n-600 rounded-md font-text text-n-0 cursor-pointer hover:scale-110 transition-transform w-32"
          :class="{
            'opacity-50': !shiftStarted && !canStartShift(employee.id)
          }"
          @click="safeSelectEmployee(employee.id)"
        >
          <div class="mt-6 mb-4">
            <div
              class="uppercase mx-auto text-3xl font-bold w-14 h-14 flex items-center justify-center rounded-full"
              :class="[getColorClass(index)]"
            >
              {{ shortName(employee.name) }}
            </div>
          </div>
          <div class="flex-1 mb-4 text-center px-2 line-clamp-2">
            {{ employee.name }}
          </div>
          <div class="items-end w-full px-2">
            <div
              class="radius-6 w-full h-1 mb-2"
              :class="[getColorClass(index)]"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectionType === 'pin'" class="pt-16 h-full">
      <div class="text-3xl text-center text-n-0 font-text font-bold">
        {{ $t('employees.pin-title') }}
      </div>
      <pin-pad class="max-h-96" @complete="pinEntered" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { LButton, LSelect } from '@last/core-ui/paprika'
import PinPad from '@/components/core/PinPad.vue'
import app from '@/app'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import { useTablesStore } from '@/store/tables'
import { useAuthStore } from '@/store/auth'
import { useTillStore } from '@/store/till'
import { useConfigStore } from '@/store/config'
import { useCatalogStore } from '@/store/catalog'
import { usePromotionsStore } from '@/store/promotions'
import { useCouriersStore } from '@/store/couriers'
import { useStatusStore } from '@/store/status'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useNotifications } from '@/composables/useNotifications'
import { managerConfirmation } from '@/plugins/managerConfirmation'

const { notifyError } = useNotifications()
const { t } = useI18n()

const tablesStore = useTablesStore()
const authStore = useAuthStore()
const tillStore = useTillStore()
const configStore = useConfigStore()
const catalogStore = useCatalogStore()
const promotionsStore = usePromotionsStore()
const couriersStore = useCouriersStore()
const statusStore = useStatusStore()

const { refreshFloorplans } = tablesStore
const { refreshEmployees, selectEmployee, refreshBillingStatus } = authStore
const { listEmployees } = storeToRefs(authStore)
const { setTill } = tillStore
const {
  selectedCashTillIsStarted,
  askForForTillStartAmount,
  selectedCashTill,
  shiftsEnabled
} = storeToRefs(tillStore)
const { refreshConfig } = configStore
const { tills, config } = storeToRefs(configStore)
const { refreshCatalogs } = catalogStore
const { refreshPromotions } = promotionsStore
const { refreshCouriers } = couriersStore
const { refreshErrorMessages } = statusStore

const selectionType = ref(config.value.employees.selectionType)
const isElectron = ref(app.isElectron)
const shiftStarted = ref(
  !(shiftsEnabled.value && !selectedCashTillIsStarted.value)
)

const router = useRouter()

const currentlySelectedCashTill = ref<string>(selectedCashTill.value?.id || '')

onMounted(async () => {
  await refreshEmployees()
  refreshCatalogs()
  refreshErrorMessages()
  refreshFloorplans()
  await refreshBillingStatus()
  await refreshConfig()
  if (config.value.organizationConfig.promotions) {
    refreshPromotions()
  }
  if (config.value.organizationConfig.deliveryFleet) {
    refreshCouriers()
  }

  selectFirstAvailableTillIfNeeded()
})

function selectFirstAvailableTillIfNeeded(): void {
  if (
    tills?.value.cash &&
    tills.value.cash[0]?.id &&
    (!selectedCashTill.value?.id ||
      !tills?.value.cash.map(t => t.id).includes(selectedCashTill.value?.id) ||
      !currentlySelectedCashTill.value)
  ) {
    currentlySelectedCashTill.value = tills.value.cash[0].id
  }
}

function saveSelectedTill() {
  const cashTill = tills.value.cash.find(
    till => till.id === currentlySelectedCashTill.value
  )
  setTill(cashTill!)
}

function shortName(name: string) {
  return name
    .split(' ')
    .map(n => Array.from(n)[0])
    .slice(0, 2)
    .join('')
}

async function selectEmployeeAndContinue(employeeId: string) {
  saveSelectedTill()
  selectEmployee(employeeId)
  if (askForForTillStartAmount.value) {
    if (
      selectionType.value === 'pin' ||
      (await managerConfirmation('SHIFT_MANAGER'))
    ) {
      router.push({ name: 'startShift' })
    }
  } else {
    router.push({ name: 'pos' })
  }
}

function canStartShift(employeeId: string) {
  return authStore.hasPrivilege(employeeId, 'SHIFT_MANAGER')
}

function safeSelectEmployee(employeeId: string) {
  if (shiftStarted.value || canStartShift(employeeId)) {
    selectEmployeeAndContinue(employeeId)
    return true
  } else {
    notifyError({
      title: t('employees.not-shift-starter'),
      icon: 'close'
    })
    return false
  }
}

function pinEntered(pin: string) {
  listEmployees.value.some(employee => {
    if (pin === employee.accessPin) {
      return safeSelectEmployee(employee.id)
    }
  })
}

async function closeApp() {
  if (CashMachine.isEnabled()) {
    await CashMachine.close()
  }
  app.quit()
}

function minimizeApp() {
  app.minimize()
}

const tillOptions = computed(() => {
  return tills.value.cash.map(till => ({
    label: till.name,
    value: till.id
  }))
})

function getColorClass(index: number): string {
  return `bg-list-${(index % 13) + 1}`
}
</script>

import { useConfigStore } from '@/store/config'
import { lastUtils } from '@last/core'
import { computed } from 'vue'

export const useMoney = () => {
  const config = useConfigStore()
  const currencyCode = config.config?.currencyCode || 'EUR'

  function currency(value: number): string {
    return lastUtils.currencyFilter(value, currencyCode)
  }

  const currencySymbol = computed(() => {
    return lastUtils.currencySymbol(currencyCode)
  })

  function formatNumber(value: number): string {
    const regexp = /^([\d,.]+)/gm
    const withCurrency = lastUtils.currencyFilter(value, currencyCode)
    const cleanValue = regexp.exec(withCurrency)

    return cleanValue ? cleanValue[0] : ''
  }

  function mostProbableHandedAmounts(price: number) {
    const denominations = [50, 20, 10, 5]

    function getCombination(amount: number) {
      const combination = []
      for (const bill of denominations) {
        while (amount >= bill) {
          amount -= bill
          combination.push(bill)
        }
      }
      return combination
    }

    function calculateScore(combination: number[], extraMoney: number): number {
      return combination.length * 10 + extraMoney
    }

    const nextHigherAmount = Math.ceil(price / 5) * 5

    let possiblePayments = []
    for (let amount = nextHigherAmount; amount <= price + 50; amount += 5) {
      if (amount === price) continue
      const combination = getCombination(amount)
      const extraMoney = amount - price
      const score = calculateScore(combination, extraMoney)
      possiblePayments.push({ amount, combination, extraMoney, score })
    }

    possiblePayments = possiblePayments
      .sort((a, b) => a.score - b.score)
      .slice(0, 3)

    possiblePayments.sort((a, b) => a.amount - b.amount)

    return possiblePayments.map(item => item.amount * 100)
  }

  function mostProbableHandedTips(amount: number): number[] {
    const roundedAmount = Math.ceil(amount) * 100
    const fivePercent = amount * 0.05 * 100
    const tenPercent = amount * 0.1 * 100
    const fifteenPercent = amount * 0.15 * 100

    if (amount % 1 != 0) {
      return [
        roundedAmount,
        amount * 100 + fivePercent,
        amount * 100 + tenPercent
      ]
    } else {
      return [
        amount * 100 + fivePercent,
        amount * 100 + tenPercent,
        amount * 100 + fifteenPercent
      ]
    }
  }

  return {
    currency,
    currencySymbol,
    formatNumber,
    mostProbableHandedAmounts,
    mostProbableHandedTips
  }
}

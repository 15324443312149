import moment from 'moment'
import { lastUtils } from '@last/core'

export function date(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm')
  }
}

export function day(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY')
  }
}

export function time(value) {
  if (value) {
    return moment(value).format('HH:mm')
  }
}

export function currency(value) {
  const currencyCode = 'EUR'
  return lastUtils.currencyFilter(value, currencyCode)
}

export function ellapsedTime(value) {
  if (value) {
    const now = moment(new Date())
    const start = moment(value)
    const duration = moment.duration(now.diff(start))
    return (
      Math.floor(duration.asHours()) +
      moment.utc(duration.asMilliseconds()).format(':mm')
    )
  }
}

<template>
  <l-select
    v-model="localModel"
    :options="mappedOptions"
    icon="time"
    icon-position="left"
  />
</template>

<script setup lang="ts">
import { format, set } from 'date-fns'
import { computed, defineModel, ref, watch } from 'vue'

import { LSelect } from '@last/core-ui/paprika'

type Props = {
  /** List of available times. Default is 24 hours with 15 minutes interval. Format H:mm */
  times?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  times: () => []
})

const model = defineModel<string | null>({
  default: null
})
const date = defineModel<Date | null>('date', {
  default: null
})

const localModel = ref<string | null>(null)

watch(
  [model, date],
  value => {
    const currentDate = value[0] ?? value[1]
    if (!currentDate) {
      localModel.value = null
      return
    }
    localModel.value = formatTime(
      typeof currentDate === 'string' ? new Date(currentDate) : currentDate
    )
  },
  {
    immediate: true
  }
)

function formatTime(value: Date) {
  const minute = (15 * Math.round(value.getMinutes() / 15)) % 60
  const correctedTime = set(value, { minutes: minute })
  return format(correctedTime, 'H:mm')
}

watch(localModel, value => {
  if (value) {
    date.value = set(model.value ?? new Date(), {
      hours: parseInt(value.split(':')[0]),
      minutes: parseInt(value.split(':')[1])
    })
    model.value = date.value.toISOString()
  } else {
    model.value = null
  }
})

const optionTimes = computed(() => {
  if (!props.times.length) {
    const times = []
    for (let i = 0; i < 24; i++) {
      times.push(`${i}:00`)
      times.push(`${i}:15`)
      times.push(`${i}:30`)
      times.push(`${i}:45`)
    }
    return times
  }
  return props.times
})

const mappedOptions = computed(() => {
  return optionTimes.value.map(time => ({ label: time, value: time }))
})
</script>

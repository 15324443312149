<template>
  <div class="bg-n-700 rounded-lg px-4 py-6 cursor-pointer" @click="onClick">
    <div class="flex flex-row items-center">
      <div class="flex-1 text-n-0 text-base font-medium">
        {{ name }}
      </div>
      <div class="flex-none"><icon class="text-n-0" name="arrow-right" /></div>
    </div>
    <div class="flex flex-row items-center">
      <div class="flex-1 text-n-200 text-sm">
        {{ description }}
      </div>
      <div class="flex-none text-n-0 text-base font-medium">
        {{ detail }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { Icon } from '@last/core-ui/paprika'

import { useMoney } from '@/composables/useMoney'
import { useTabs } from '@/composables/useTabs'
import { Booking, Tab } from '@/types'

type Props = {
  item: Tab | Booking
  type: 'tab' | 'customer' | 'reservation'
}

const props = defineProps<Props>()
const router = useRouter()
const { t } = useI18n()
const { currency } = useMoney()

const name = computed(() => {
  const nameArray: string[] = []
  if (props.type === 'tab') {
    const item = props.item as Tab
    if (item.code) nameArray.push(item.code)
    if (item.name) nameArray.push(item.name)
  } else if (props.type === 'reservation') {
    const item = props.item as Booking
    const tmpArray: string[] = []
    if (item.name) tmpArray.push(item.name)
    if (item.surname) tmpArray.push(item.surname)
    if (tmpArray.length > 0) nameArray.push(tmpArray.join(' '))
  }

  return nameArray.join(' - ')
})

const description = computed(() => {
  const descriptionArray: string[] = []
  if (props.type === 'tab') {
    const item = props.item as Tab
    if (item.pickupType === 'takeAway') {
      descriptionArray.push(t('tabs.take-away'))
    } else {
      if (item.source) {
        if (item.source === 'OwnDelivery') {
          descriptionArray.push(t('bill.own-delivery'))
        } else if (item.source === 'Restaurant') {
          descriptionArray.push(t('tabs.restaurant'))
        } else {
          descriptionArray.push(item.source)
        }
      }
      if (item.tableName) descriptionArray.push(item.tableName)
    }
  } else if (props.type === 'reservation') {
    const item = props.item as Booking
    if (item.phoneNumber) descriptionArray.push(item.phoneNumber)
  }

  return descriptionArray.join(' - ')
})

const detail = computed(() => {
  if (props.type === 'tab') {
    const { total } = useTabs(props.item.id)
    return currency(total.value)
  } else if (props.type === 'reservation') {
    const item = props.item as Booking
    if (item.dateTime) {
      return moment(item.dateTime).format('DD/MM/YYYY HH:mm')
    }
  }

  return ''
})

function onClick() {
  if (props.type === 'tab') {
    router.push({
      name: 'pos',
      query: { preSelectedTabId: (props.item as Tab).id }
    })
  } else if (props.type === 'reservation') {
    router.push({ name: 'reservations' })
  }
}
</script>

<template>
  <div class="p-8 bg-n-700 rounded-2xl text-n-0 flex flex-col gap-4">
    <div class="pb-4">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.general') }}
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.first-bill') }}</div>
        <div>{{ report.firstBill || '-' }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.last-bill') }}</div>
        <div>{{ report.lastBill || '-' }}</div>
      </div>
    </div>

    <div class="pb-4">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.tabs-per-source') }}
      </div>
      <div
        v-for="(total, source) in report.tabsPerSource"
        :key="source"
        class="flex justify-between font-body font-normal text-sm py-1 px-4"
      >
        <div>{{ source }}</div>
        <div>{{ total }}</div>
      </div>
    </div>

    <div class="pb-4">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.breakdown') }}
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.subtotal') }}</div>
        <div>{{ currency(report.breakdown.taxableBase) }}</div>
      </div>
      <div
        v-for="(taxRate, taxPercentage) in report.breakdown.taxRates"
        :key="taxPercentage"
        class="flex justify-between font-body font-normal text-sm py-1 px-4"
      >
        <div>
          {{ `${report.breakdown.taxLabel} (${taxPercentage}%)` }}
        </div>
        <div>{{ currency(taxRate.tax) }}</div>
      </div>

      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.total') }}</div>
        <div>{{ currency(report.breakdown.total) }}</div>
      </div>

      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div :class="{ 'font-bold': report.breakdown.pending > 0 }">
          {{ $t('report.pending') }}
        </div>
        <div>{{ currency(report.breakdown.pending) }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.discounts') }}</div>
        <div>{{ currency(report.breakdown.discounts) }}</div>
      </div>
    </div>

    <div class="pb-4">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.payments') }}
      </div>
      <div
        v-for="(amount, method) in report.payments"
        :key="method"
        class="flex justify-between font-body font-normal text-sm py-1 px-4"
      >
        <div>{{ method }}</div>
        <div>{{ currency(amount) }}</div>
      </div>
    </div>

    <div class="pb-4">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.tips') }}
      </div>
      <div
        v-for="(amount, method) in report.tips"
        :key="method"
        class="flex justify-between font-body font-normal text-sm py-1 px-4"
      >
        <div>{{ method }}</div>
        <div>{{ currency(amount) }}</div>
      </div>
    </div>

    <div class="pb-4">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.payments-without-tips') }}
      </div>
      <div
        v-for="(amount, method) in report.paymentsWithoutTips"
        :key="method"
        class="flex justify-between font-body font-normal text-sm py-1 px-4"
      >
        <div>{{ method }}</div>
        <div>{{ currency(amount) }}</div>
      </div>
    </div>

    <div class="pb-4 border-b border-n-600">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.pays-in-and-out') }}
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.pay-in') }}</div>
        <div>{{ currency(report.totalIns) }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.pay-out') }}</div>
        <div>{{ currency(report.totalOuts) }}</div>
      </div>
    </div>

    <div class="pb-4 border-b border-n-600">
      <div class="font-heading font-medium text-base py-1 px-4">
        {{ $t('report.stats') }}
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.total-tabs') }}</div>
        <div>{{ report.stats.totalTabs }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div :class="{ 'font-bold': report.stats.openTabs > 0 }">
          {{ $t('report.open-tabs') }}
        </div>
        <div>{{ report.stats.openTabs }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.total-seats') }}</div>
        <div>{{ report.stats.totalSeats }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.average-seats') }}</div>
        <div>{{ report.stats.averageSeats }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.average-per-tab') }}</div>
        <div>{{ currency(report.stats.averagePerTab) }}</div>
      </div>
      <div class="flex justify-between font-body font-normal text-sm py-1 px-4">
        <div>{{ $t('report.average-per-seat') }}</div>
        <div>{{ currency(report.stats.averagePerSeat) }}</div>
      </div>
    </div>

    <div class="flex justify-center">
      <l-button class="w-[270px]" @click="$emit('print')">
        {{ $t('report.print') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import { LButton } from '@last/core-ui/paprika'
import { useMoney } from '@/composables/useMoney'

withDefaults(
  defineProps<{
    report: any
  }>(),
  { report: null }
)

defineEmits(['print'])

const { currency } = useMoney()
</script>

<template>
  <div>
    <div
      :id="option.value?.toString()"
      class="rounded-lg text-n-600 dark:text-n-0 text-xs flex"
      :class="[
        size === 'x-small' ? 'p-2' : 'px-4 py-3',
        {
          'hover:bg-n-50 dark:hover:bg-n-700 cursor-pointer':
            !option.notSelectable
        }
      ]"
      @click.stop="selectOption(option)"
    >
      <l-checkbox
        v-if="props.option.checkbox && !isColor"
        small
        class="mr-2"
        :class="[props.option.icon && 'flex-grow']"
        :model-value="model.selected"
        :half="
          (selectedChildren.length > 0 && !allChildSelected) ||
          (partiallySelected && !partiallyUnselected)
        "
        :label="label"
        @update:model-value="() => selectOption(props.option)"
      />
      <l-radio
        v-if="props.option.radio"
        small
        class="mr-2"
        :model-value="model.selected"
        :label="!isColor ? label : undefined"
        @update:model-value="() => selectOption(props.option)"
      />
      <div
        v-if="isColor"
        :style="{ background: label }"
        class="color w-full cursor-pointer rounded"
        :class="[size === 'x-small' ? 'h-4' : 'h-7 mx-6 my-2']"
      />
      <icon
        v-if="props.option.icon"
        :original-color="props.option.originalIconColor"
        :name="props.option.icon"
        :class="[!props.option.checkbox && 'mr-2']"
      />
      <div
        :class="{
          'text-v-300': markSelected && model.selected,
          'font-bold': option.title
        }"
        v-if="!props.option.checkbox && !props.option.radio && !isColor"
      >
        {{ label }}
      </div>
    </div>
    <div v-if="props.option.children">
      <div
        v-for="child in props.option.children"
        :key="child.value"
        class="pl-4 rounded-lg"
      >
        <dropdown-option
          v-model="childrenByValue![child.value]"
          :option="child"
          @update:model-value="() => selectChild(childrenByValue![child.value])"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel, ref } from 'vue'
import { LCheckbox, Icon, LRadio } from '@last/core-ui/paprika'
import type { Option, Model } from './types'
import type { Size } from '@last/core-ui/paprika/components/types'

type Props = {
  option: Option
  selected?: boolean
  hasCheckbox?: boolean
  hasRadio?: boolean
  multiselect?: boolean
  markSelected?: boolean
  size?: Size
  partiallySelected?: boolean
}

const model = defineModel<Model>({
  required: true
})

const selectedChildren = computed(() => {
  if (!model.value?.children) return []
  return model.value?.children
    ?.filter(child => child.selected)
    .map(child => child.value)
})

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  half: false,
  selected: false,
  markSelected: true,
  size: 'medium',
  hasCheckbox: false,
  hasRadio: false,
  partiallySelected: false
})

const emit = defineEmits<{
  partialRemoved: [option: string | number]
}>()

const partiallyUnselected = ref<boolean>(false)

const selectOption = (option: Option) => {
  if (option.notSelectable) return

  if (props.partiallySelected && !partiallyUnselected.value) {
    partiallyUnselected.value = true
    emit('partialRemoved', option.value)
    return
  }

  const newModel = { ...model.value }
  if (option.checkbox) {
    newModel.selected = !newModel?.selected
  } else {
    newModel.selected = true
  }
  if (newModel.children) {
    newModel.children = newModel.children.map(child => ({
      ...child,
      selected: props.multiselect && !allChildSelected.value
    }))
  }
  model.value = newModel
  option.onClick?.()
}

const selectChild = (selectedChild: Model) => {
  const newModel = { ...model.value }
  if (!newModel.children) return
  newModel.children = newModel.children.map(child => {
    if (child.value === selectedChild.value) return selectedChild
    if (!props.multiselect) return { ...child, selected: false }
    return child
  })
  newModel.selected =
    newModel.children.every(child => child.selected) && props.multiselect

  model.value = newModel
}

const childrenByValue = computed(() =>
  model.value.children?.reduce(
    (acc, child) => {
      acc[child.value] = child
      return acc
    },
    {} as Record<string, Model>
  )
)

const allChildSelected = computed(() => {
  return Boolean(
    model.value.children &&
      model.value.children.every(child =>
        selectedChildren.value.includes(child.value)
      )
  )
})

const label = computed(() => {
  return props.option.label
})

const isColor = computed(() => {
  const colorRegex = '#([0-9]|[A-F]){6}'
  return label.value.match(colorRegex)
})
</script>

<template>
  <swipe-out>
    <template #default>
      <div
        class="w-full flex justify-center gap-4 px-5 py-4 text-n-0 font-body"
      >
        <quantity-selector
          v-if="editingQuantity && !disabledQuantitySelector"
          :quantity="product.quantity"
          :is-maxed="quantityIsMaxed"
          :min="0"
          @click-outside="editingQuantity = false"
          @update:quantity="updateQuantity"
        />
        <div
          v-else
          class="w-5 h-5 text-center leading-5"
          @click="editingQuantity = true"
        >
          {{ product.quantity }}
        </div>
        <div class="w-full" @click="emit('selected')">
          <div class="flex justify-between leading-5">
            <div>
              {{ product.name }}
            </div>
            <div
              v-if="product.priceImpact"
              class="font-bold font-text text-xs leading-5"
            >
              {{ currency(product.priceImpact) }}
            </div>
          </div>
          <div class="text-n-200 text-sm">
            {{ description }}
          </div>
          <div v-if="product.comments" class="text-n-200 text-sm">
            + {{ product.comments }}
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <div
        class="w-14 h-full flex items-center justify-center px-3 bg-r-500"
        @click="deleteProduct"
      >
        <icon name="trash" size="medium" class="text-n-0" />
      </div>
    </template>
  </swipe-out>
</template>

<script setup lang="ts">
import SwipeOut from '@/components/core/SwipeOut.vue'
import { Icon } from '@last/core-ui/paprika'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import { computed } from 'vue'
import { ref } from 'vue'
import { useMoney } from '@/composables/useMoney'

const props = withDefaults(
  defineProps<{
    product: any
    disabledQuantitySelector?: boolean
    quantityIsMaxed?: boolean
  }>(),
  {
    disabledQuantitySelector: false,
    quantityIsMaxed: false
  }
)

const emit = defineEmits(['updateQuantity', 'delete', 'selected'])

const { currency } = useMoney()

const editingQuantity = ref(false)

function updateQuantity(quantity: number) {
  emit('updateQuantity', quantity)
}

function deleteProduct() {
  emit('delete')
}

const description = computed(() => {
  return (props.product.comboProducts || props.product.modifiers || [])
    .map((modifier: any) => {
      const quantity = modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
      return modifier.name + quantity
    })
    .join(', ')
})
</script>

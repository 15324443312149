<template>
  <div class="pt-safe bg-n-900">
    <div class="px-4 py-2 flex text-n-0 items-center justify-between h-14">
      <div class="flex items-center">
        <div v-if="showBack" class="pr-3 text-v-400" @click="$emit('back')">
          <div class="p-3 -m-3">
            <icon size="medium" name="arrow-left" />
          </div>
        </div>
        <slot v-else name="left" />
      </div>
      <div>
        <slot name="center" />
      </div>
      <div class="flex items-center gap-2 justify-end">
        <l-button v-if="!isConnected" icon="wifi-x" size="small" color="red" />
        <div v-if="showClose">
          <l-button
            size="medium"
            icon="close"
            type="text"
            color="white"
            @click="$emit('close')"
          />
        </div>
        <slot v-else name="right" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@last/core-ui/paprika'
import { LButton } from '@last/core-ui/paprika'
import { storeToRefs } from 'pinia'
import { useStatusStore } from '@/store/status'

withDefaults(
  defineProps<{
    showBack?: boolean
    showClose?: boolean
  }>(),
  {
    showBack: false,
    showClose: false
  }
)

defineEmits(['back', 'close'])

const statusStore = useStatusStore()

const { isConnected } = storeToRefs(statusStore)
</script>

import { PrinterConfig } from '../types'

declare global {
  interface Window {
    starprnt: {
      printBase64Image: (
        address: string,
        type: string,
        imageObj: { base64Image: string; width: number; cutReceipt: boolean },
        resolve: () => void,
        reject: (error: any) => void
      ) => void
      openCashDrawer: (address: string, type: string) => void
    }
  }
}

export default class StarPrint {
  private config: PrinterConfig

  constructor(config: PrinterConfig) {
    this.config = config
  }

  printImage(imageData: string) {
    if (!window.starprnt) return
    const imageObj = {
      base64Image: imageData.split(',')[1],
      width: 576,
      cutReceipt: true
    }

    return new Promise<void>((resolve, reject) => {
      window.starprnt.printBase64Image(
        `TCP:${this.config.address}`,
        this.config.type,
        imageObj,
        resolve,
        reject
      )
    })
  }

  openCashDrawer() {
    if (window.starprnt) {
      window.starprnt.openCashDrawer(
        `TCP:${this.config.address}`,
        this.config.type
      )
    }
  }
}

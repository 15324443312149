import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useConfigStore } from '@/store/config.js'
import { useFastMode } from '@/store/fast-mode'
import { useTablesStore } from '@/store/tables'
import { useTillStore } from '@/store/till'

export type HomeTab = 'all' | 'delivery' | 'takeAway' | 'fast' | 'floorplan'

export const useHome = () => {
  const configStore = useConfigStore()
  const tablesStore = useTablesStore()
  const tillStore = useTillStore()

  const { config } = storeToRefs(configStore)
  const { floorplanIds } = storeToRefs(tablesStore)
  const { shiftsEnabled, selectedCashTillIsStarted } = storeToRefs(tillStore)
  const router = useRouter()
  const route = useRoute()

  const showFloorplanButton = ref<boolean>(true)
  const tabBrowserSelectedTabId = ref<string>()

  const localSelectedTab = ref<HomeTab | undefined>()
  const selectedTab = computed({
    get: () => localSelectedTab.value,
    set: (value: HomeTab): void => {
      localSelectedTab.value = value
      if (value !== 'fast') {
        router.push({ name: 'pos', params: { section: value } })
      }
    }
  })

  if (
    shiftsEnabled &&
    !selectedCashTillIsStarted &&
    router.currentRoute.value.name !== 'employees'
  ) {
    router.push({ name: 'employees' })
  }

  if (configStore.config.fastModeHome) {
    useFastMode().load()
    localSelectedTab.value = 'fast'
  } else if (floorplanIds.value.length > 0) {
    localSelectedTab.value = 'floorplan'
  } else {
    localSelectedTab.value = 'delivery'
    showFloorplanButton.value = false
  }

  const showingFloorplan = computed(() => {
    return (
      (config.value.lastProductPosEnabled ||
        config.value.lastProductBookingsEnabled) &&
      !!selectedTab.value &&
      !['all', 'delivery', 'takeAway', 'fast'].includes(selectedTab.value)
    )
  })

  function updateSelectedTab(tab: HomeTab) {
    selectedTab.value = tab
  }

  watch(
    () => route.params,
    params => {
      if (params.section) {
        localSelectedTab.value = params.section as HomeTab
      }
    },
    { immediate: true }
  )

  watch(
    () => route.query,
    query => {
      if (query.preSelectedTabId) {
        const preSel: string = (
          Array.isArray(query.preSelectedTabId)
            ? query.preSelectedTabId[0]
            : query.preSelectedTabId
        ) as string

        tabBrowserSelectedTabId.value = preSel
        selectedTab.value = 'all'
      }
    },
    { immediate: true }
  )

  return {
    showingFloorplan,
    selectedTab,
    showFloorplanButton,
    tabBrowserSelectedTabId,
    updateSelectedTab
  }
}

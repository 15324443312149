<template>
  <tippy
    ref="tippyRef"
    class="w-full"
    :interactive="true"
    :arrow="false"
    trigger="click"
    placement="auto"
    :duration="100"
    theme="daytimepicker"
    :append-to="() => appendTarget"
  >
    <div class="w-full relative">
      <div
        class="flex rounded-xl justify-between border border-transparent items-center cursor-pointer min-w-[200px]"
        :class="[
          ...sizeClasses,
          ...paddingClasses,
          ...backgroundColorsClasses,
          ...focusClasses,
          ...textColorsClasses
        ]"
        @click="handleOpen"
      >
        <icon name="calendar" class="mr-2 text-n-200 dark:text-n-0" />
        <div class="flex-1 whitespace-nowrap">
          <div v-if="actualDate">
            {{ showTime ? dateText : dayText }}
          </div>
          <div v-else class="ellipsis text-n-200">
            {{ placeholder }}
          </div>
        </div>
        <icon
          name="arrow-down"
          class="transition-all duration-300"
          :class="{
            'rotate-180': showCalendar
          }"
        />
      </div>
    </div>
    <template #content>
      <div
        class="w-72 border border-n-50 dark:border-n-900 rounded-xl mt-2 px-5 py-6 flex flex-col gap-6 bg-n-0 dark:bg-n-800 z-10"
      >
        <l-calendar v-model="localModel" :only-future="onlyFuture" />
        <l-time-picker v-if="showTime" v-model:date="localModel" />
        <l-button class="w-full" size="small" @click="save">
          {{ $t('ctas.apply') }}
        </l-button>
      </div>
    </template>
  </tippy>
</template>

<script setup lang="ts">
import { computed, ref, watch, defineModel, useTemplateRef } from 'vue'
import { LCalendar, LTimePicker, Icon, LButton } from '@last/core-ui/paprika'
import {
  useSizeClasses,
  usePaddingClasses,
  useBackgroundColorsClasses,
  useFocusClasses,
  useTextColorsClasses
} from '../classes'
import { format } from 'date-fns'
import type { Size } from '../types'
import { Tippy } from 'vue-tippy'

type Props = {
  showTime?: boolean
  onlyFuture?: boolean
  placeholder: string
  size?: Size
}

const tippyRef = useTemplateRef<typeof Tippy>('tippyRef')
const appendTarget = document.body
const showCalendar = ref(false)

const props = withDefaults(defineProps<Props>(), {
  showTime: true,
  onlyFuture: false,
  size: 'small'
})

const model = defineModel<string | null>({
  default: null
})
const date = defineModel<Date | null>('date', {
  default: null
})

const localModel = ref<Date | null>(null)

watch(
  [model, date],
  value => {
    const currentDate = value[0] ?? value[1]
    localModel.value =
      typeof currentDate === 'string' ? new Date(currentDate) : currentDate
  },
  {
    immediate: true
  }
)

const actualDate = computed(() => {
  return model.value ? new Date(model.value) : date.value
})

const dateText = computed(() => {
  if (!actualDate.value) return null
  return format(actualDate.value, 'dd/MM/yyyy HH:mm')
})

const dayText = computed(() => {
  if (!actualDate.value) return null
  return format(actualDate.value, 'dd/MM/yyyy')
})

function save() {
  date.value = localModel.value
  model.value = localModel.value ? localModel.value.toISOString() : null
  showCalendar.value = false
  tippyRef.value?.hide()
}

function handleOpen() {
  showCalendar.value = !showCalendar.value
}

const sizeClasses = computed(() => useSizeClasses(props.size))
const paddingClasses = computed(() => usePaddingClasses(props.size))
const backgroundColorsClasses = computed(() => useBackgroundColorsClasses())
const focusClasses = computed(() => useFocusClasses())
const textColorsClasses = computed(() => useTextColorsClasses())
</script>

<style>
@import 'tippy.js/dist/tippy.css';

.tippy-box[data-theme~='daytimepicker'] {
  background: none !important;
}

.tippy-content[data-theme~='daytimepicker'] {
  padding: 0;
}

.tippy-box[data-theme~='daytimepicker'][data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}
</style>

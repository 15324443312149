<template>
  <div v-if="internalBooking">
    <table-selector
      v-model:is-active="selectingTable"
      :title="$t('reservations.title')"
      :button-text="$t('reservations.accept')"
      :blocked-tables="blockedTables"
      :multiple-select="true"
      :selected-tables="internalBooking.tables"
      @close="selectingTable = false"
      @tables-selected="selectTables"
    />
    <reservation-details
      v-model="internalBooking"
      :open="reservationDetailsOpen"
      @close="$emit('close')"
      @select-table="selectingTable = true"
    />
  </div>
</template>

<script setup lang="ts">
import { addMinutes, isWithinInterval, subMinutes } from 'date-fns'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import ReservationDetails from '@/components/ReservationDetails.vue'
import TableSelector from '@/components/tables/TableSelector.vue'
import { useBookingsStore } from '@/store/bookings'
import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import { Booking } from '@/types'

const props = defineProps<{
  reservation: Booking | null
}>()

defineEmits(['close'])

const route = useRoute()
const configStore = useConfigStore()
const bookingStore = useBookingsStore()
const tabsStore = useTabsStore()
const { tabs } = storeToRefs(tabsStore)

const { sortedBookings } = storeToRefs(bookingStore)

const internalBooking = ref<Partial<Booking>>({})
const selectingTable = ref(false)

const reservationDetailsOpen = computed(() => {
  return !!internalBooking.value && !selectingTable.value
})

onMounted(() => {
  if (route.query.showSelector) {
    nextTick(() => {
      selectingTable.value = true
    })
  }
  internalBooking.value = { ...props.reservation } as Booking
})

watch(
  () => internalBooking.value.dateTime,
  () => {
    const tables = internalBooking.value.tables
    internalBooking.value.tables = tables?.filter(
      table => !blockedTables.value.includes(table)
    )
  }
)

const blockedTables = computed(() => {
  if (!internalBooking.value) return []

  const date = internalBooking.value.dateTime
    ? new Date(internalBooking.value.dateTime)
    : new Date()
  const start = subMinutes(date, configStore.config.reservations.duration)
  const end = addMinutes(date, configStore.config.reservations.duration - 1)

  const bookedTables = Object.values(sortedBookings.value)
    .filter(reservation => {
      const inRange = isWithinInterval(new Date(reservation.dateTime), {
        start,
        end
      })
      const current = reservation.id === internalBooking.value!.id
      return inRange && !current
    })
    .flatMap(reservation => reservation.tables ?? [])

  const currentTabsTables = Object.values(tabs.value)
    .filter(
      tab =>
        tab.open &&
        isWithinInterval(new Date(tab.creationTime), {
          start,
          end
        })
    )
    .flatMap(tab => tab.tables)

  return [...bookedTables, ...currentTabsTables]
})

function selectTables(tables: string[]) {
  if (!internalBooking.value) return
  internalBooking.value.tables = tables
  selectingTable.value = false
}
</script>

<template>
  <teleport to="#appRoot">
    <div class="fixed inset-0 bg-n-800">
      <full-screen
        :title="
          isMobile && previewBill
            ? $t('print-bills.bill') + ' ' + previewBill.code
            : $t('print-bills.title')
        "
        :show-back="!!previewBill"
        :show-close="!previewBill"
        @close="$emit('close')"
        @back="previewBill = undefined"
      >
        <div class="text-n-0 flex h-full p-4 gap-4">
          <div
            class="flex flex-col shrink grow-0"
            :class="[
              isMobile ? 'w-full' : 'basis-80',
              isMobile && previewBill ? 'hidden' : ''
            ]"
          >
            <tab-title
              :tab-id="tab.id"
              class="pb-4 border-b border-n-700 mb-4"
            />
            <div class="overflow-y-auto">
              <div
                v-for="bill in allBills"
                :key="bill.id"
                class="bg-n-700 px-4 py-6 font-heading font-medium text-base rounded-xl border border-transparent flex flex-col cursor-pointer mb-4"
                :class="{
                  'border-v-300': true
                }"
                @click="previewBill = bill"
              >
                <div class="flex gap-2 items-center">
                  <icon name="ticket" />
                  {{ $t('print-bills.bill') }} {{ bill.code }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="previewBill"
            class="flex flex-col sm:p-4 sm:bg-n-700 font-body text-xs flex-1 grow shrink rounded-lg sm:min-w-96 h-full relative"
          >
            <div class="overflow-y-auto">
              <div class="flex justify-center">
                <div class="flex-1 hidden sm:block"></div>
                <div v-if="canvasImage" class="bg-n-0 rounded-lg p-2">
                  <img :src="canvasImage" />
                </div>
                <div class="flex-1 min-w-44 hidden sm:block"></div>
              </div>
            </div>
            <div class="pt-4 sm:fixed bottom-8 right-8">
              <l-button
                icon="printer"
                class="w-full sm:w-auto"
                @click="resendBill(previewBill)"
              >
                {{ $t('print-bills.resend') }}
              </l-button>
            </div>
          </div>
        </div>
      </full-screen>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import TicketGenerator from '@/tickets/generator.js'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { useTabs } from '@/composables/useTabs'
import type { BillWithPayments } from '@/types'
import { LButton, Icon } from '@last/core-ui/paprika'
import FullScreen from '../core/FullScreen.vue'
import TabTitle from '../tabs/TabTitle.vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'

interface Props {
  tabId: string
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const { allBills, tab } = useTabs(props.tabId)

const previewBill = ref<BillWithPayments>()
const canvasImage = ref<string>()

const isMobile = useBreakpoints(breakpointsTailwind).smallerOrEqual('sm')

onMounted(() => {
  if (isMobile.value) return
  previewBill.value = allBills.value[0]
})

function resendBill(bill: BillWithPayments) {
  TicketPrinter.printBill(bill)
  emit('close')
}

watch(previewBill, async bill => {
  if (bill) {
    const canvas = await TicketGenerator.bill(bill, {
      barcode: config.value.organizationConfig.barcodes,
      logoImageId: config.value.virtualBrands.find(
        v => v.id === bill.virtualBrandId
      )?.imageId
    })
    canvasImage.value = canvas.toDataURL('image/png')
  }
})
</script>

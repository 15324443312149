import { PrinterConfig, PrinterDriver } from '../types.js'
import EscPosEncoder from './esc-pos-encoder.js'
import SocketFactory from '@/drivers/socket.js'

export default class EscPos implements PrinterDriver {
  private config: PrinterConfig & { matrixMode?: boolean }

  constructor(config: PrinterConfig & { matrixMode?: boolean }) {
    this.config = config
  }

  printImage(imageData: string) {
    return new Promise<void>((resolve, reject) => {
      const rejectMiddleware = (error: unknown) => {
        reject(error)
      }
      const socket = SocketFactory.getSocket()
      const config = this.config
      socket.open(
        this.config.address,
        9100,
        () => {
          const encoder = new EscPosEncoder()
          const img = new Image()
          img.onload = function () {
            const width = config.matrixMode ? 200 : 504
            const ratio = width / img.width
            const height = Math.round((img.height * ratio) / 8) * 8
            encoder.align('center')
            if (config.matrixMode) {
              encoder
                .imageEsc(img, width, height)
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
            } else {
              encoder.image(img, width, height)
            }
            const result = encoder
              .newline()
              .newline()
              .newline()
              .newline()
              .newline()
              .raw([0x1d, 0x56, 0x01])
              .align('left')
              .encode()
            socket.write(
              result,
              () => {
                socket.shutdownWrite(() => {
                  resolve()
                }, rejectMiddleware)
              },
              rejectMiddleware
            )
          }
          img.src = imageData
        },
        rejectMiddleware
      )
    })
  }

  openCashDrawer() {
    return new Promise<void>((resolve, reject) => {
      const socket = SocketFactory.getSocket()
      socket.open(
        this.config.address,
        9100,
        () => {
          const encoder = new EscPosEncoder()
          const result = encoder
            .raw([0x1b, 0x70, 0x00])
            .raw([0x1b, 0x70, 0x01])
            .encode()

          socket.write(
            result,
            () => {
              socket.shutdownWrite(() => {
                resolve()
              }, reject)
            },
            reject
          )
        },
        reject
      )
    })
  }

  getStatus() {
    return new Promise<any | null>((resolve, reject) => {
      const socket = SocketFactory.getSocket()
      socket.open(
        this.config.address,
        9100,
        () => {
          const encoder = new EscPosEncoder()
          const result = encoder.raw([0x10, 0x04, 0x01]).encode()
          socket.write(result, () => {}, reject)
        },
        reject
      )
      socket.onData((data: any) => {
        socket.shutdownWrite(() => {
          resolve(data)
        }, reject)
      })
      setTimeout(() => {
        socket.shutdownWrite(() => {
          resolve(null)
        }, reject)
      }, 2000)
    })
  }
}

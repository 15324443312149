<template>
  <full-screen :title="$t('wrong-time.title')">
    <div class="w-full h-full flex items-center justify-center relative">
      <div
        class="max-w-4xl flex flex-col gap-2 items-center justify-center text-n-0 text-center px-4"
      >
        <img src="@/assets/wrong-time.svg" class="w-44 h-44" />
        <div class="font-title font-bold text-2xl">
          {{ $t('wrong-time.subtitle') }}
        </div>
        <div class="font-text text-base">
          {{ $t('wrong-time.description') }}
        </div>
      </div>
    </div>
    <template #footer>
      <l-button
        class="w-48"
        size="medium"
        :loading="buttonLoading"
        @click="checkStatus"
      >
        {{ $t('wrong-time.ready') }}
      </l-button>
    </template>
  </full-screen>
</template>
<script setup lang="ts">
import FullScreen from '@/components/core/FullScreen.vue'
import { LButton } from '@last/core-ui/paprika'
import { useStatusStore } from '@/store/status'
import { ref } from 'vue'

const statusStore = useStatusStore()
const { updateCurrentStatus } = statusStore

const buttonLoading = ref(false)

async function checkStatus() {
  buttonLoading.value = true
  await updateCurrentStatus()
  buttonLoading.value = false
}
</script>

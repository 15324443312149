import TicketPrinter from '@/integrations/printer/ticketPrinter'
import { logger } from '@/monitoring'
import { useTabsStore } from '@/store/tabs'
import { storeToRefs } from 'pinia'
import { useTabs } from '@/composables/useTabs'
import { computed, MaybeRefOrGetter, toRef } from 'vue'
import { TabProduct } from '@/types'

export const useKitchenOrders = (mixedTabId: MaybeRefOrGetter<string>) => {
  const tabId = toRef(mixedTabId)

  const tabsStore = useTabsStore()
  const {
    createKitchenOrders,
    addKitchenOrderVersion,
    createKitchenOrderByProducts
  } = tabsStore
  const { kitchenOrders, products } = storeToRefs(tabsStore)
  const { unsentProducts, tab } = useTabs(tabId)

  const printOrders = (orderIds: string[]) => {
    orderIds
      .map(orderId => getKitchenOrder(orderId))
      .forEach(order => {
        setTimeout(() => {
          TicketPrinter.printKitchenOrder(order)
        })
      })
  }

  const sendKitchenOrderByProducts = (
    kitchenOrderProducts: TabProduct[],
    print = true
  ) => {
    const orderIds = createKitchenOrderByProducts({
      tabId: tabId.value,
      products: kitchenOrderProducts
    })
    if (print) {
      printOrders(orderIds)
    }
  }

  const sendKitchenOrder = async (course: string) => {
    if (course === 'all' && modifiedKitchenOrders.value.length > 0) {
      sendKitchenOrderModifications()
    }
    const orderIds = await createKitchenOrders({
      tabId: tabId.value,
      course
    })
    printOrders(orderIds)
  }

  const sendKitchenOrderModifications = (print = true) => {
    const orderIds = modifiedKitchenOrders.value
    orderIds.forEach(addKitchenOrderVersion)
    if (print) {
      printOrders(orderIds)
    }
  }

  const getKitchenOrder = (id: string) => {
    return kitchenOrders.value[id]
  }

  const compareModifiers = (oldModifiers = [], newModifiers = []) => {
    const oldModifierIds = oldModifiers.map(modifier =>
      String(modifier.catalogModifierId || modifier.tabModifierId)
    )
    const newModifierIds = newModifiers.map(modifier =>
      String(modifier.parentModifierId || modifier.id)
    )
    return (
      [
        ...oldModifierIds.filter(m => !newModifierIds.includes(m)),
        ...newModifierIds.filter(m => !oldModifierIds.includes(m))
      ].length > 0
    )
  }

  const modifiedKitchenOrders = computed(() => {
    const kitchenProducts = Object.values(products.value)
      .flatMap(product => {
        if (product.comboProducts && product.comboProducts.length > 0) {
          return product.comboProducts.map(comboProduct => ({
            ...comboProduct,
            quantity: comboProduct.quantity * product.quantity,
            fromCombo: true
          }))
        } else {
          return [product]
        }
      })
      .reduce((res, product) => {
        res[product.id] = product
        return res
      }, {})
    return (tab.value?.kitchenOrders || [])
      .map(id => kitchenOrders.value[id])
      .filter(order => {
        const lastVersionProducts = order.versions.slice(-1)[0].products
        return lastVersionProducts.some(product => {
          const tabProduct = kitchenProducts[product.tabProductId]
          if (!tabProduct) return true

          const productId = product.fromCombo
            ? product.comboId
            : product.tabProductId
          const productTabId = products.value[productId]?.tab
          const movedCheck = !!productTabId && productTabId !== tabId.value
          const quantityCheck = tabProduct.quantity !== product.quantity
          const modifiersCheck = compareModifiers(
            product.modifiers,
            tabProduct.modifiers
          )
          const commentsCheck =
            tabProduct.comments !== product.comments &&
            (tabProduct.comments || product.comments)
          if (quantityCheck || modifiersCheck || commentsCheck || movedCheck) {
            logger.info('kitchen order modified', {
              order,
              product,
              tabProduct,
              quantityCheck,
              modifiersCheck,
              commentsCheck,
              movedCheck
            })
          }
          return quantityCheck || modifiersCheck || commentsCheck || movedCheck
        })
      })
      .map(order => order.id)
  })

  const ordersNotPrinted = computed(() => {
    return (
      (tab?.value.kitchenOrders || [])
        .map(id => kitchenOrders.value[id])
        .filter(order => !order.printedTime && order.copies > 0).length > 0
    )
  })

  const pendingOrders = computed(() => {
    return (
      unsentProducts.value.length > 0 || modifiedKitchenOrders.value.length > 0
    )
  })

  return {
    printOrders,
    sendKitchenOrderByProducts,
    sendKitchenOrder,
    sendKitchenOrderModifications,
    getKitchenOrder,
    compareModifiers,
    modifiedKitchenOrders,
    ordersNotPrinted,
    pendingOrders
  }
}

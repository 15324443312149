<template>
  <div v-if="lastCustomerTabs && lastCustomerTabs.length > 0" class="mt-6">
    <div class="font-heading font-medium text-base mb-4">
      {{ $t('new-delivery.last-customer-tabs') }}
    </div>
    <div v-if="lastCustomerTabs.length === 0">
      <EmptyCase
        item-translation-key="new-delivery.last-customer-tabs-empty"
        type="empty"
      />
    </div>

    <div v-else class="flex overflow-x-auto">
      <div
        v-for="tab in lastCustomerTabs"
        :key="tab.id"
        class="shrink-0 bg-n-700 rounded-xl border border-n-600 mr-6 h-56 w-56 flex flex-col"
        :class="{
          'border-v-300': selectedLastTabsId === tab.id
        }"
        @click="setTheLastTab(tab)"
      >
        <div class="px-4 pt-6 flex flex-col min-h-0">
          <div class="flex justify-between font-heading">
            <div class="font-medium">
              {{ $d(tab.creationTime, 'day') }}
            </div>
            <div class="font-semibold">
              {{ $d(tab.creationTime, 'time') }}
            </div>
          </div>

          <div class="overflow-y-auto min-h-0 flex flex-col text-sm">
            <div
              v-for="product in tab.products"
              :key="product.id"
              class="flex gap-2 h-8 items-center shrink-0"
            >
              <div class="w-5 text-center">
                {{ product.quantity }}
              </div>
              <div class="">
                {{ product.name }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-4 py-4 flex justify-between items-center shrink-0 border-t border-n-600 mt-auto"
        >
          <div class="">
            {{ $t('new-delivery.total') }}
          </div>
          <div class="font-heading font-bold text-xl h-6">
            {{ currency(tab.total) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMoney } from '@/composables/useMoney'
import { EmptyCase } from '@last/core-ui/paprika'
import { ref } from 'vue'
import { v4 as uuid } from 'uuid'
import { Tab } from '@/types'

defineProps<{
  lastCustomerTabs: Tab[]
}>()

const { currency } = useMoney()

const selectedLastTabsId = ref<string>('')

const internalTab = defineModel<Tab>('tab', {
  default: {}
})

function setTheLastTab(tab: any) {
  setTheSharedProductsIntoTheNewTab(tab)
  internalTab.value.kitchenNote = tab.kitchenNote
  internalTab.value.customerNote = tab.customerNote
  selectedLastTabsId.value = tab.id
}

function setTheSharedProductsIntoTheNewTab(tab: Tab) {
  internalTab.value.shared = tab.shared
    ?.filter(product => product.price > 0)
    .map(product => {
      const mappedProduct = {
        ...product,
        id: uuid(),
        tab: internalTab.value.id,
        tabId: internalTab.value.id,
        modifiers: (product.modifiers || []).map(modifier => ({
          ...modifier,
          id: uuid()
        }))
      }
      if (product.comboProducts) {
        mappedProduct.comboProducts = product.comboProducts.map(
          comboProduct => {
            return {
              ...comboProduct,
              id: uuid(),
              modifiers: (comboProduct.modifiers || []).map(modifier => ({
                ...modifier,
                id: uuid()
              }))
            }
          }
        )
      }
      return mappedProduct
    })
}
</script>

<template>
  <div>
    <div class="flex gap-4">
      <l-field
        class="flex-1"
        :label="$t('new-delivery.client-name-label')"
        mandatory
      >
        <l-input
          v-model="formValues.name"
          :placeholder="$t('new-delivery.client-name-placeholder')"
          :wrong-value="formValues.name !== '' && formErrors.name"
          @input="onChange"
        />
      </l-field>
      <l-field
        class="flex-1"
        :label="$t('new-delivery.client-last-name-label')"
      >
        <l-input
          v-model="formValues.surname"
          :placeholder="$t('new-delivery.client-last-name-placeholder')"
          @input="onChange"
        />
      </l-field>
    </div>

    <l-field
      class="flex-1"
      :label="$t('new-delivery.client-phone-label')"
      mandatory
    >
      <l-phone-input
        v-model="formValues.phoneNumber"
        :placeholder="$t('new-delivery.client-phone-placeholder')"
        :wrong-value="formErrors.phone"
        :default-country-code="config.locationCountryCode"
        @input="onChange"
      />
    </l-field>

    <l-field class="flex-1" :label="$t('new-delivery.client-email-label')">
      <l-input
        v-model="formValues.email"
        type="email"
        :placeholder="$t('new-delivery.client-email-placeholder')"
        @input="onChange"
      />
    </l-field>

    <l-field class="flex-1" :label="$t('new-delivery.internal-note')">
      <l-input
        v-model="formValues.internalNote"
        type="textarea"
        :placeholder="$t('new-delivery.internal-note-placeholder')"
        @input="onChange"
      />
    </l-field>

    <div v-if="mode === 'edition'" class="flex justify-end mt-4 mb-10 gap-4">
      <l-button
        type="secondary"
        size="small"
        class="w-32"
        @click="$emit('cancel')"
      >
        {{ $t('ctas.cancel') }}
      </l-button>
      <l-button
        size="small"
        class="w-32"
        :disabled="!isValid || !isChanged"
        @click="onSaveChanges"
      >
        {{ $t('ctas.apply') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LButton, LField, LInput, LPhoneInput } from '@last/core-ui/paprika'
import { computed, defineModel, nextTick, ref, watch } from 'vue'
import { CustomerInfo } from '@/types'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'

type Props = {
  customer: CustomerInfo
  mode?: 'edition' | 'creation'
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'creation'
})

const emit = defineEmits<{
  change: [customer: CustomerInfo]
  save: [customer: CustomerInfo]
  cancel: []
}>()

const configStore = useConfigStore()

const { config } = storeToRefs(configStore)

type ClientFormModel = {
  name: string
  surname: string
  phoneNumber: string
  email: string
  internalNote: string
}

const formValues = ref<ClientFormModel>({
  name: '',
  surname: '',
  phoneNumber: '',
  email: '',
  internalNote: ''
})

const isValid = defineModel<boolean>('isValid')

watch(
  () => props.customer,
  () => {
    formValues.value = {
      name: props.customer.name,
      surname: props.customer.surname,
      phoneNumber: props.customer.phoneNumber,
      email: props.customer.email,
      internalNote: props.customer.internalNote
    }
    nextTick(() => {
      checkValidity()
    })
  },
  {
    immediate: true
  }
)

const formErrors = computed<{ phone: boolean; name: boolean }>(() => {
  return {
    phone:
      !(
        formValues.value.phoneNumber &&
        parsePhoneNumberFromString(
          formValues.value.phoneNumber || ''
        )?.isValid()
      ) || false,
    name: !(formValues.value.name && formValues.value.name !== '')
  }
})

function onChange(): void {
  if (props.mode === 'creation') {
    checkValidity()
    if (isValid.value) {
      emit('change', { ...props.customer, ...formValues.value })
    }
  }
}

function checkValidity(): void {
  isValid.value = !formErrors.value?.phone && !formErrors.value?.name
}

const isChanged = computed(() => {
  const initial = props.customer
  return (
    formValues.value.name !== initial.name ||
    formValues.value.surname !== initial.surname ||
    formValues.value.phoneNumber !== initial.phoneNumber ||
    formValues.value.email !== initial.email ||
    formValues.value.internalNote !== initial.internalNote
  )
})

function onSaveChanges(): void {
  checkValidity()
  if (isValid.value) {
    emit('save', { ...props.customer, ...formValues.value })
  }
}
</script>

import type { DirectiveBinding, ObjectDirective } from 'vue'

interface ExtendedDirective extends ObjectDirective<HTMLElement, never> {
  wheelEvent: (e: WheelEvent) => void
}

const getDir = (binding: DirectiveBinding<never>) => {
  return binding.dir as ExtendedDirective
}

export const vHorizontalScroll: ObjectDirective<HTMLElement, never> = {
  mounted(el, binding) {
    getDir(binding).wheelEvent = e => {
      if (e.deltaY > 0) el.scrollLeft += 80
      else el.scrollLeft -= 80
    }
    el.addEventListener('wheel', getDir(binding).wheelEvent, { passive: true })
  },
  unmounted(el, binding) {
    el.removeEventListener('wheel', getDir(binding).wheelEvent)
  }
}

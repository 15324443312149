import { lastUtils } from '@last/core'

class DevDataphone {
  constructor() {
    this.initialized = true
  }

  async init() {
    return true
  }

  async charge() {
    await lastUtils.sleep(5000)
    return true
  }

  async cancel() {
    await lastUtils.sleep(2000)
    return
  }

  async refund() {
    await lastUtils.sleep(2000)
    return { error: null }
  }
}

export default DevDataphone

<template>
  <div
    ref="el"
    class="h-9 bg-n-50 dark:bg-n-900 rounded-xl flex cursor-pointer relative overflow-hidden"
  >
    <div
      v-for="(option, index) in options"
      ref="optionList"
      :key="option.value.toString()"
      class="h-full flex items-center rounded-xl px-4 z-10 transition flex-1 justify-center overflow-hidden gap-1"
      :class="{
        'text-n-800 dark:text-n-0': model === option.value,
        'text-n-600 opacity-30 dark:text-n-0': model !== option.value
      }"
      @click="select(index)"
    >
      <icon
        v-if="option.icon"
        :name="option.icon"
        :original-color="option.iconOriginalColor"
      />
      <div v-if="option.label" class="truncate text-center text-sm">
        {{ option.label }}
      </div>
    </div>
    <div
      v-if="selectionBox.width > 0"
      class="absolute h-full p-px top-0 transition-all"
      :style="selectionStyle"
    >
      <div class="bg-n-0 dark:bg-n-700 h-full rounded-[0.6875rem]"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core'
import { computed, defineModel, onMounted, ref, watch } from 'vue'

import { Icon } from '@last/core-ui/paprika'

export type OptionValue = string | boolean

export interface Option {
  label?: string
  value: OptionValue
  icon?: string
  iconOriginalColor?: boolean
}

interface Props {
  options: Option[]
}

const model = defineModel<OptionValue>()

const props = withDefaults(defineProps<Props>(), {
  options: () => []
})

const el = ref()
const optionList = ref()
const selectionBox = ref({
  width: 0,
  left: 0
})
const { width } = useElementSize(el)

function updateSelectBox() {
  const index = Math.max(
    props.options.findIndex(option => option.value === model.value),
    0
  )
  const item = optionList.value[index]
  selectionBox.value = {
    width: item.clientWidth,
    left: item.offsetLeft
  }
}

onMounted(() => {
  updateSelectBox()
})

watch(width, () => {
  updateSelectBox()
})

watch(
  () => model.value,
  () => {
    updateSelectBox()
  }
)

function select(index: number) {
  model.value = props.options[index].value
}

const selectionStyle = computed(() => {
  return {
    width: `${selectionBox.value.width}px`,
    left: `${selectionBox.value.left}px`
  }
})
</script>

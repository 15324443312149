<template>
  <div
    class="px-5 py-3 text-n-0 rounded-lg cursor-pointer border"
    :class="{
      'bg-n-600 border-n-700': !selected,
      'bg-v-300 border-v-400': selected
    }"
  >
    <div class="pb-2">
      {{ promotion.name }}
    </div>
    <div class="flex flex-row text-sm">
      <div v-if="promotion.discountType === 'percentage'">
        {{ promotion.discountAmount }}%
      </div>
      <div v-else>
        {{ currency(promotion.discountAmount) }}
      </div>
      <div class="pl-1">
        {{ $t('bill-discount.of-discount') }}
      </div>
    </div>
    <p v-if="promotion.remainingRedemptions">
      {{
        promotion.remainingRedemptions +
        ' ' +
        $t('bill-discount.remaining-redemptions')
      }}
    </p>
    <div v-if="promotion.pointsExpense" class="mt-2 text-right text-g-300">
      {{ promotion.pointsExpense || 0 }}
      {{ $t('bill-discount.points') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMoney } from '@/composables/useMoney'
import type { Promotion } from '@/types'

interface Props {
  promotion: Promotion
  selected: boolean
}

defineProps<Props>()

const { currency } = useMoney()
</script>

import mqtt from 'mqtt'
import pinia from '@/store'
import sync from '@/sync/service'
import { logger } from '@/monitoring'
import printerDiscovery from '@/integrations/printer/printerDiscovery'
import { useStatusStore } from '@/store/status'
import { useConfigStore } from '@/store/config'
import { useAuthStore } from '@/store/auth'
import { useCatalogStore } from '@/store/catalog'
import { usePromotionsStore } from '@/store/promotions'
import { useCouriersStore } from '@/store/couriers'

const client = mqtt.connect(import.meta.env.VITE_APP_MQTT_URL)

function subscribe(locationId: string) {
  const topic = import.meta.env.VITE_APP_MQTT_TOPIC + '-' + locationId
  const status = useStatusStore(pinia)
  client.subscribe(topic, err => {
    if (err) {
      logger.error('Mqtt subscribe error', { err })
    }
    status.updateMqttStatus(true)
  })
  sync.resync()
}

client.on('connect', () => {
  const config = useConfigStore(pinia)
  const auth = useAuthStore(pinia)
  const locationId = !config.demoMode && auth.locationId
  if (locationId) {
    subscribe(locationId)
  }
})

client.on('error', error => {
  logger.info('Mqtt error', { error })
})

client.on('end', () => {
  const status = useStatusStore(pinia)
  status.updateMqttStatus(false)
})

client.on('offline', () => {
  const status = useStatusStore(pinia)
  status.updateMqttStatus(false)
})

client.on('disconnect', () => {
  const status = useStatusStore(pinia)
  status.updateMqttStatus(false)
})

client.on('message', (_, data: Buffer) => {
  const message = JSON.parse(data.toString())
  const auth = useAuthStore(pinia)
  const catalog = useCatalogStore(pinia)
  const promotions = usePromotionsStore(pinia)
  const config = useConfigStore(pinia)
  const status = useStatusStore(pinia)
  const couriers = useCouriersStore(pinia)

  status.updateMqttStatus(true)

  switch (message.type) {
    case 'catalogs':
      catalog.refreshCatalogs(message.catalogs)
      break
    case 'normalizedCatalog':
      catalog.refreshCatalog(message.catalog)
      break
    case 'promotions':
      promotions.refreshPromotions(message.promotions)
      break
    case 'couriers':
      couriers.refreshCouriers(message.couriers)
      break
    case 'employees':
      auth.refreshEmployees()
      break
    case 'event':
      sync.playRemote(message.event)
      break
    case 'configUpdate':
      config.refreshConfig(message.config)
      break
    case 'errorMessages':
      status.refreshErrorMessages(message.errorMessages)
      break
    case 'resync':
      sync.resync()
      break
    case 'discoverPrinters':
      printerDiscovery.discoverPrinters(message.existingPrinters)
      break
  }
})

export default {
  subscribe: subscribe,
  isConnected: client.connected
}

<template>
  <h2 class="text-n-0 text-2xl font-heading font-bold">
    {{ $t('inputs.pin-label') }}
  </h2>
  <pin-pad
    class="flex-1 max-h-[20.5rem] mb-8 !items-start"
    @complete="pinEntered"
  />
  <l-button
    class="w-full max-w-72"
    size="small"
    type="text"
    @click="$emit('disablePIN')"
  >
    {{ $t('login.login-email') }}
  </l-button>
</template>

<script setup lang="ts">
import { LButton } from '@last/core-ui/paprika'
import { computed, ref, watch } from 'vue'
import PinPad from '@/components/core/PinPad.vue'
import { useAuthStore } from '@/store/auth'

const authStore = useAuthStore()

const pin = ref<string>('')
const loggingIn = ref<boolean>(false)
const error = ref<boolean>(false)

defineEmits(['disablePIN'])

const isComplete = computed(() => pin.value.length === 4)

watch(pin, () => {
  if (isComplete.value) {
    startLogin()
  }
})

async function startLogin() {
  if (!isComplete.value) return
  loggingIn.value = true
  try {
    await authStore.login({ pin: pin.value })
  } catch {
    error.value = true
  }
  loggingIn.value = false
}

function pinEntered(input: string) {
  pin.value = input
}
</script>

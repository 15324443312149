<template>
  <div class="grid grid-cols-3 gap-4">
    <div
      v-for="company in Object.keys(
        virtualBrand.externalDeliveryCompaniesClosingTimes
      )"
      :key="company"
      class="bg-n-600 rounded-xl p-3"
    >
      <div class="flex flex-row mb-3">
        <div
          v-if="!company.includes('Glovo') && !company.includes('Uber')"
          class="flex-none flex items-center justify-center w-8 h-8 rounded-full bg-n-10 mr-1"
        >
          <icon name="diners" class="w-4 h-4" />
        </div>
        <img
          v-else
          class="flex-none w-8 h-8 mr-1"
          :src="getCompanyIcon(company)"
        />

        <div
          class="flex-1 text-n-0 text-sm font-body line-clamp-2 leading-[1.1rem] self-center"
        >
          {{ company }}
        </div>
      </div>
      <div class="flex flex-row justify-end">
        <div class="flex flex-row items-center gap-2">
          <div
            v-if="
              deliveryIsClosed(
                virtualBrand.externalDeliveryCompaniesClosingTimes[company]
              )
            "
            class="font-body text-xs text-n-0"
          >
            {{
              timeFrom(
                virtualBrand.externalDeliveryCompaniesClosingTimes[company]!
              )
            }}
          </div>
          <l-switch
            :model-value="
              !deliveryIsClosed(
                virtualBrand.externalDeliveryCompaniesClosingTimes[company]
              )
            "
            @update:model-value="
              value => setValue(virtualBrand.id, company, value)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment'
import { LSwitch, Icon } from '@last/core-ui/paprika'
import { VirtualBrand } from '@/types/virtualBrands'
import { useI18n } from 'vue-i18n'
import logoGlovo from '@last/core-ui/components/assets/logo_glovo.png'
import logoUber from '@last/core-ui/components/assets/logo_uber.png'

const props = defineProps<{
  virtualBrand: VirtualBrand
  currentTime: Date
}>()

const { t } = useI18n()
const emit = defineEmits(['openDelivery', 'closeDelivery'])

function getCompanyIcon(company: string) {
  if (company.includes('Glovo')) {
    return logoGlovo
  } else if (company.includes('Uber')) {
    return logoUber
  }

  return ''
}

function setValue(brandId: string, company: string, value: string) {
  if (value) {
    emit('openDelivery', brandId, company)
  } else {
    emit('closeDelivery', brandId, company)
  }
}

function deliveryIsClosed(time: Date | null) {
  return time && moment(time).isAfter(moment(props.currentTime))
}

function timeFrom(date: Date) {
  const momentDate = moment(date)
  const currentTime = moment(props.currentTime)
  if (momentDate.diff(currentTime, 'days') > 365) {
    return t('close-delivery.indefinitely')
  } else {
    return moment(date).from(currentTime)
  }
}
</script>

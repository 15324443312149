import { Howl } from 'howler'
import moment from 'moment/moment'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Bills from '@last/core/src/billsGenerator'
import Orders from '@last/core/src/kitchenOrderGenerator'

import { useNotifications } from '@/composables/useNotifications'
import { useTabs } from '@/composables/useTabs'
import i18n from '@/i18n'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import Dataphone from '@/integrations/dataphone/dataphone'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import localDb from '@/localDb'
import { logger } from '@/monitoring'
import { normalizeTab, normalizeTabs } from '@/normalizr'
import { useAuthStore } from '@/store/auth'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { usePromotionsStore } from '@/store/promotions'
import { useTablesStore } from '@/store/tables'
import { useTillStore } from '@/store/till'
import sync from '@/sync/service'
import {
  Bill,
  BillWithPayments,
  CatalogModifier,
  CatalogProductNormalized,
  Courier,
  DeliveryStatus,
  Discount,
  KitchenOrder,
  Modifier,
  OrderingMode,
  Payment,
  PickupType,
  Product,
  ProductPricing,
  SentToKitchenProduct,
  Tab,
  TabComboProduct,
  TabProduct
} from '@/types'
import { CustomerInfo } from '@/types/customerInfo'

export const useTabsStore = defineStore('tabs', () => {
  const tabs = ref<Record<string, Tab>>({})
  const products = ref<Record<string, TabProduct>>({})
  const bills = ref<Record<string, Bill>>({})
  const billsMetadata = ref<Record<string, string>>({})
  const payments = ref<Record<string, Payment>>({})
  const kitchenOrders = ref<Record<string, KitchenOrder>>({})
  const refundablePaymentMethods = ref(['dataphone'])

  const tablesStore = useTablesStore()
  const configStore = useConfigStore()
  const promotionsStore = usePromotionsStore()
  const authStore = useAuthStore()
  const tillStore = useTillStore()

  const route = useRoute()
  const router = useRouter()
  const { t } = i18n.global

  const parseKitchenProducts = computed(() => {
    return (tabId: string, products: TabProduct[]): any[] => {
      const catalogStore = useCatalogStore()
      return products.map(product => {
        let productCatalogId: string | undefined
        if (product.fromCombo && product.combo) {
          productCatalogId = catalogStore.getProductById(
            product.combo.parentProduct
          )?.catalogId
        }
        const originalProduct = catalogStore.getProductById(
          product.parentProduct,
          productCatalogId
        )

        let categoryPosition = 0
        if (product.fromCombo && product.combo) {
          const originalCombo: CatalogProductNormalized =
            catalogStore.getProductById(product.combo.parentProduct)

          categoryPosition =
            originalCombo?.categories?.reduce(
              (
                res: Record<string, number>,
                category,
                categoryIndex: number
              ) => {
                category.products.forEach(p => (res[p.id] = categoryIndex))
                return res
              },
              {}
            )[originalProduct?.id] || 0
        }

        let modifiers: Modifier[] = product.modifiers || ([] as Modifier[])
        if (
          product.modifiers &&
          originalProduct &&
          originalProduct.modifierGroups
        ) {
          const originalModifiers = originalProduct.modifierGroups
            .flatMap(group => {
              return group.modifiers.map(modifier => {
                return {
                  ...modifier,
                  fullPosition: group.position * 1000 + modifier.position
                }
              })
            })
            .reduce((res: Record<string, CatalogModifier>, modifier) => {
              res[modifier.id] = modifier
              return res
            }, {})

          modifiers =
            product.modifiers &&
            product.modifiers
              .map((modifier: Modifier) => ({
                ...modifier,
                fullPosition:
                  modifier &&
                  modifier.parentModifierId &&
                  originalModifiers[modifier.parentModifierId]
                    ? originalModifiers[modifier.parentModifierId].fullPosition
                    : undefined,
                kitchenName:
                  modifier &&
                  modifier.parentModifierId &&
                  originalModifiers[modifier.parentModifierId]
                    ? originalModifiers[modifier.parentModifierId].kitchenName
                    : undefined
              }))
              .sort((a, b) => (a.fullPosition || 0) - (b.fullPosition || 0))
        }
        const category = catalogStore.categories[product.categoryId]
        let defaultPrinters: string[] = []
        const config = useConfigStore()
        if (config.config.defaultKitchenPrinter) {
          defaultPrinters = [config.config.defaultKitchenPrinter]
        }
        let printerIds =
          (originalProduct && originalProduct.printerIds) ||
          (category && category.printerIds) ||
          defaultPrinters
        const pickupType = tabs.value[tabId].pickupType
        const tabFloorplan = tablesStore.getTabFloorplan(tabId)
        const controlPrinter =
          tabFloorplan?.controlPrinter || config.config.controlPrinter
        if (
          controlPrinter &&
          (!pickupType || tabs.value[tabId].source === 'Restaurant') &&
          (!originalProduct || originalProduct.controlEnabled)
        ) {
          printerIds = [...new Set([...printerIds, controlPrinter])]
        }

        return {
          ...originalProduct,
          ...product,
          modifiers,
          printerIds,
          copies:
            (originalProduct && originalProduct.copies) ??
            (category && category.copies) ??
            1,
          categoryPosition
        }
      })
    }
  })

  const getTab = computed(() => {
    return (id: string): Tab => {
      return tabs.value[id]
    }
  })

  const isTabOpen = computed(() => {
    return (id: string) => {
      return id in tabs.value
    }
  })

  const getPayment = computed(() => {
    return (id: string) => {
      return payments.value[id]
    }
  })

  function formatTab({
    tab,
    tableId
  }: {
    tab: Partial<Tab>
    tableId?: string | null
  }) {
    const tableIds = (tableId && [tableId]) || []

    const table =
      tablesStore.tables[Array.isArray(tableId) ? tableId[0] : tableId]
    const virtualBrandId =
      tab.virtualBrandId || configStore.config.locationBrandId!
    const marginTime = virtualBrandId
      ? getActivationMarginPerVirtualBrandId.value(virtualBrandId)
      : 0
    const shouldBeActivated =
      !tab.schedulingTime ||
      moment(tab.schedulingTime).isBefore(moment().add(marginTime, 'minutes'))
    const now = new Date().toISOString()

    return {
      ...tab,
      id: tab.id || uuid(),
      tables: tableIds,
      open: true,
      source: tab.source || 'Restaurant',
      creationTime: now,
      schedulingTime: tab.schedulingTime,
      activationTime: shouldBeActivated ? now : null,
      tableName: getTableName.value(
        tab.name || '',
        Array.isArray(tableId) ? [tableId[0]] : [tableId]
      ),
      billingStartedTime: null,
      virtualBrandId,
      orderingMode: configStore.config.tabOrdering || 'seats',
      terraceSurchargePercentage: table?.terraceSurchargePercentage || 0
    }
  }

  const getBillById = computed(() => {
    return (billId: string): BillWithPayments => {
      const bill = bills.value[billId]
      const payment = bill.payments.map(paymentId => payments.value[paymentId])
      const metadata = billsMetadata.value[billId]

      return {
        ...bill,
        payments: payment,
        metadata
      }
    }
  })

  const getBills = computed(() => {
    return (tabId: string) => {
      const tab = tabs.value[tabId]
      if (!tab) return []
      return tab.bills
        .map(billId => ({
          ...bills.value[billId],
          payments: bills.value[billId].payments.map(
            paymentId => payments.value[paymentId]
          )
        }))
        .map(bill =>
          Bills.addPaymentInfo(
            bill,
            bill.payments.filter(payment => !!payment)
          )
        )
    }
  })

  const getTableName = computed(() => {
    return (tableName: string, tables: string[]) => {
      const joinedName = tables
        .map(tableId => {
          const table = tablesStore.tables[tableId]
          return table ? table.name : ''
        })
        .filter(name => name)
        .join(',')
      if (tableName) {
        return tableName + (joinedName ? ` (${joinedName})` : '')
      } else {
        return `${joinedName}`
      }
    }
  })

  const getSentToKitchenProductsByTabId = computed(
    (): Record<string, SentToKitchenProduct[]> => {
      return Object.keys(tabs.value).reduce(
        (res: Record<string, any>, tabId: string) => {
          res[tabId] = tabs.value[tabId].kitchenOrders
            .map(id => kitchenOrders.value[id])
            .flatMap(order =>
              order.versions.slice(-1)[0].products.map(product => {
                return {
                  productId: product.tabProductId,
                  sent: order.creationTime,
                  kitchenOrder: order.id
                }
              })
            )
          return res
        },
        {}
      )
    }
  )

  const getSentToKitchenProducts = computed(() => {
    return (tabId: string): SentToKitchenProduct[] => {
      return getSentToKitchenProductsByTabId.value[tabId] || []
    }
  })

  const hasSentToKitchenProducts = computed(() => {
    return (tabId: string): boolean => {
      return getSentToKitchenProducts.value(tabId).length > 0
    }
  })

  const getSentToKitchenTime = computed(() => {
    return (productId: string): Date | void => {
      const product = products.value[productId]
      if (!product) return
      let productIds = [product.id]
      if (product.comboProducts && product.comboProducts.length > 0) {
        productIds = product.comboProducts.map(comboProduct => comboProduct.id)
      }
      const sentInfo = getSentToKitchenProducts
        .value(product.tab)
        .find(data => productIds.includes(data.productId))
      if (sentInfo) {
        return sentInfo.sent
      }
      return
    }
  })

  const getProductKitchenOrderIds = computed(() => {
    return (productId: string) => {
      const product = products.value[productId]
      let productIds = [product.id]
      if (product.comboProducts && product.comboProducts.length > 0) {
        productIds = product.comboProducts.map(comboProduct => comboProduct.id)
      }
      return getSentToKitchenProducts
        .value(product.tab)
        .filter(data => productIds.includes(data.productId))
        .map(sentInfo => sentInfo.kitchenOrder)
    }
  })

  const getDeliveryFee = computed(() => {
    return (tabId: string) => {
      const deliveryFee = { isFree: false, value: 0 }
      if (!tabs.value[tabId]) return deliveryFee
      const discount = getGlobalDiscountByTabId.value(tabId)
      const deliveryOrder = tabs.value[tabId].deliveryOrder
      if (discount && discount.freeDelivery) {
        deliveryFee.isFree = true
      } else if (deliveryOrder) {
        deliveryFee.value = deliveryOrder.deliveryFee || 0
      }

      return deliveryFee
    }
  })

  const getMinimumBasketSurcharge = computed(() => {
    return (tabId: string): number => {
      return tabs.value[tabId]?.deliveryOrder?.minimumBasketSurcharge || 0
    }
  })

  const getGlobalDiscountByTabId = computed(() => {
    return (tabId: string): Discount | undefined => {
      const promotion = promotionsStore.getTabGlobalPromotion(tabId)
      return promotion
        ? {
            type: promotion.discountType,
            amount: promotion.discountAmount,
            freeDelivery: promotion.freeDelivery
          }
        : undefined
    }
  })

  const getByTableId = computed(() => {
    return (tableId: string): Tab[] => {
      return Object.values(tabs.value).filter(
        tab => tab.open && tab.tables.includes(tableId)
      )
    }
  })

  const getByBillId = computed(() => {
    return (billId: string): Tab | void => {
      return Object.values(tabs.value).find(tab => tab.bills.includes(billId))
    }
  })

  const getBillPayments = computed(() => {
    return (billId: string): Payment[] => {
      if (!bills.value[billId]) return []
      return bills.value[billId].payments.map(
        paymentId => payments.value[paymentId]
      )
    }
  })

  const getNegativeTabPayments = computed(() => {
    return (tabId: string): Payment[] => {
      const tab = tabs.value[tabId]
      if (!tab) return []
      const negativePayments = []
      for (const billId of tab.bills) {
        const bill = bills.value[billId]
        for (const paymentId of bill.payments) {
          const payment = payments.value[paymentId]
          if (payment.amount < 0) {
            negativePayments.push(payment)
          }
        }
      }
      return negativePayments
    }
  })

  const getLastInteraction = computed(() => {
    return (tabId: string): Date => {
      const tab = tabs.value[tabId]
      return tab.kitchenOrders
        .map(id => kitchenOrders.value[id])
        .map(order => new Date(order.creationTime))
        .reduce((max, val) => {
          return new Date(Math.max(max.getTime(), val.getTime()))
        }, new Date(tab.creationTime))
    }
  })

  const getPendingBill = computed(() => {
    return (tabId: string): Bill | undefined => {
      const { allProducts } = useTabs(tabId)
      const products = allProducts.value
        .map(product => {
          return {
            ...product,
            quantity: product.notBilledQuantity ?? 0
          }
        })
        .filter(product => product.quantity > 0)
      if (products.length === 0) {
        return
      }
      return Bills.generateProductsBill({
        products,
        company: configStore.config.company,
        tab: tabs.value[tabId],
        discount: getGlobalDiscountByTabId.value(tabId),
        taxRate: configStore.config.taxRate,
        ticketInfo: configStore.config.ticketInfo
      })
    }
  })

  const isDeliveryTab = computed(() => {
    return (tabId: string) => {
      return (
        tabs.value[tabId].deliveryOrder &&
        tabs.value[tabId].pickupType !== 'takeAway'
      )
    }
  })

  const getDeliveryTillId = computed(() => {
    return (): string | undefined => {
      const config = useConfigStore()
      const deliveryTillId = config.config.deliveryTillId
      if (
        deliveryTillId &&
        config.tills.cash.some(till => till.id === deliveryTillId)
      ) {
        return deliveryTillId
      }
    }
  })

  const getPaymentTillId = computed(() => {
    return (tabId: string, type: string): string | undefined => {
      if (isDeliveryTab.value(tabId)) {
        const deliveryTillId = getDeliveryTillId.value()
        if (deliveryTillId) return deliveryTillId
      }
      if (
        authStore.currentEmployee?.tillEnabled &&
        authStore.currentEmployee?.tillId &&
        type &&
        type === 'cash'
      ) {
        return authStore.currentEmployee?.tillId
      }
      if (tillStore.selectedCashTill) {
        return tillStore.selectedCashTill.id
      }
    }
  })

  const getTabPreparationMinutes = computed(() => {
    return (virtualBrandId: string): number => {
      const virtualBrand = configStore.getVirtualBrand(virtualBrandId)
      const preparationMinutes = configStore.config.preparationMinutes
      const brandDeliveryConfig = virtualBrand?.deliveryConfig

      return brandDeliveryConfig?.preparationMinutes || preparationMinutes || 0
    }
  })

  const getActivationMarginPerVirtualBrandId = computed(() => {
    return (virtualBrandId: string): number => {
      const virtualBrand = configStore.getVirtualBrand(virtualBrandId)
      const brandDeliveryConfig = virtualBrand?.deliveryConfig

      const preparationMargin = getTabPreparationMinutes.value(virtualBrandId)
      const schedulingMargin =
        brandDeliveryConfig?.schedulingMarginMinutes ||
        configStore.config.schedulingMarginMinutes ||
        0

      return Math.max(preparationMargin, schedulingMargin)
    }
  })

  function openTab({ tableId, tab }: { tableId: string | null; tab: Tab }) {
    const newTab = formatTab({ tableId, tab })
    sync.record('tabOpened', newTab)

    return newTab.id
  }

  function openTabWithCustomer({
    tableId,
    tab,
    customer
  }: {
    tableId: string | null
    tab: Tab
    customer: CustomerInfo
  }): string {
    const newTab = formatTab({ tableId, tab })
    sync.record('tabOpenedWithCustomer', { tab: newTab, customer })

    return newTab.id
  }

  function addProduct({
    tabId,
    seat,
    product
  }: {
    tabId: string
    seat: number | null
    product: TabProduct
  }): void {
    const newProduct = {
      parentProduct: product.parentProduct,
      id: product.id || uuid(),
      name: product.name,
      price: product.price,
      fullPrice: product.fullPrice,
      finalPrice: product.finalPrice,
      discountType: product.discountType || null,
      discountAmount: product.discountAmount || null,
      discountConcept: product.discountConcept || null,
      promotionId: product.promotionId || null,
      pointsExpense: product.pointsExpense || 0,
      modifiers: product.modifiers,
      comboProducts: product.comboProducts,
      comments: product.comments,
      quantity: product.quantity || 1,
      tab: tabId,
      course: product.course || 'Main',
      categoryId: product.categoryId,
      combine: product.combine || false,
      seat
    }
    sync.record('productAdded', { tabId, seat, product: newProduct })
    tabProductsUpdated(tabId)
  }

  function moveProductTab({
    productId,
    fromTabId,
    toTabId
  }: {
    productId: string
    fromTabId: string
    toTabId: string
  }): void {
    const product = products.value[productId]
    sync.record('productTabMoved', { product, fromTabId, toTabId })

    const tabProducts = tabs.value[fromTabId].seats?.reduce((acc, seat) => {
      return acc + seat.length
    }, tabs.value[fromTabId].shared?.length || 0)
    if (tabProducts === 0) {
      sync.record('tabClosedWithInfo', {
        tabId: fromTabId,
        closeTime: new Date(),
        closedWithPin: false,
        showPaidNotification: false
      })
    }
    tabProductsUpdated(fromTabId)
    tabProductsUpdated(toTabId)
  }

  function moveProduct({
    tabId,
    seat,
    position,
    productId
  }: {
    tabId: string
    seat: number
    position: number
    productId: string
  }) {
    const product = products.value[productId]
    sync.record('productMoved', { tabId, seat, position, product })
  }

  function setPreferredPaymentMethod({
    tabId,
    preferredPaymentMethod
  }: {
    tabId: string
    preferredPaymentMethod: string
  }): void {
    sync.record('preferredPaymentMethodSelected', {
      tabId,
      preferredPaymentMethod
    })
  }

  function updateProductModifiers({
    productId,
    modifiers,
    comments,
    productPricing
  }: {
    productId: string
    modifiers: CatalogModifier[]
    comments: string
    productPricing: ProductPricing
  }): void {
    sync.record('productModifiersUpdated', {
      productId,
      modifiers,
      comments,
      productPricing
    })
  }

  function updateComboProducts({
    comboId,
    products,
    productPricing
  }: {
    comboId: string
    products: Product[]
    productPricing: ProductPricing
  }): void {
    sync.record('comboProductsUpdated', {
      comboId,
      products,
      productPricing: {
        fullPrice: Math.max(productPricing.fullPrice, 0),
        finalPrice: Math.max(productPricing.finalPrice, 0)
      }
    })
  }

  function splitProduct({
    productId,
    seat
  }: {
    productId: string
    seat: number
  }): string | void {
    const product = products.value[productId]
    if (product.quantity < 2) {
      return
    }
    updateProductQuantity({
      productId,
      quantity: product.quantity - 1
    })
    const splittedProductId: string = uuid()
    const splittedProduct: TabProduct = {
      ...product,
      modifiers: product.modifiers?.map(m => {
        return {
          ...m,
          id: uuid()
        }
      }),
      comboProducts: product.comboProducts?.map(cp => {
        return {
          ...cp,
          id: uuid(),
          modifiers: cp.modifiers?.map(m => {
            return {
              ...m,
              id: uuid()
            }
          })
        }
      }),
      id: splittedProductId,
      quantity: 1,
      promotionId: undefined
    }
    addProduct({
      tabId: product.tab,
      seat: seat,
      product: splittedProduct
    })

    const kitchenOrderIds = getProductKitchenOrderIds.value(productId)
    kitchenOrderIds
      .map(kitchenOrderId => kitchenOrders.value[kitchenOrderId])
      .forEach(order => {
        const version = Orders.generateOrderVersionForSplittedProduct(
          order.versions.slice(-1)[0].products,
          productId,
          splittedProduct
        )
        sync.record('kitchenOrderVersionAdded', {
          orderId: order.id,
          version
        })
      })

    return splittedProductId
  }

  function updateProductQuantity({
    productId,
    quantity
  }: {
    productId: string
    quantity: number
  }): void {
    sync.record('productQuantityUpdated', { productId, quantity })
    const tabId = products.value[productId].tab
    tabProductsUpdated(tabId)
  }

  function updateProductDiscount({
    productId,
    discount,
    productPricing
  }: {
    productId: string
    discount: Discount
    productPricing: ProductPricing
  }): void {
    sync.record('productDiscountUpdated', {
      productId,
      discount,
      productPricing
    })
  }

  function removeProduct(productId: string): void {
    const product = products.value[productId]
    if (!product) return

    const tabId = product.tab
    sync.record('productRemoved', productId)
    tabProductsUpdated(tabId)
  }

  function updateDeliveryOrderStatus({
    tabId,
    newStatus,
    courier
  }: {
    tabId: string
    newStatus: DeliveryStatus
    courier?: Courier
  }): void {
    sync.record('deliveryOrderStatusUpdated', {
      tabId,
      newStatus,
      courier,
      date: new Date()
    })
  }

  function removeSeat({
    tabId,
    selectedSeatIndex
  }: {
    tabId: string
    selectedSeatIndex: number
  }): void {
    sync.record('seatRemoved', { tabId, selectedSeatIndex })
  }

  function addBill({ tabId, bill }: { tabId: string; bill: Bill }): void {
    if (!bills.value[bill.id]) {
      if (tabs.value[tabId].deliveryOrder?.status === 'CREATED') {
        sync.record('deliveryOrderStatusUpdated', {
          tabId,
          newStatus: 'KITCHEN',
          date: new Date()
        })
      }
      if (bill.discount?.promotionId) {
        sync.record('promotionRedeemed', {
          promotionId: bill.discount?.promotionId
        })
      }
      sync.record('billAdded', { tabId, bill })
      startBilling(tabId)
    }
  }

  function generatePendingBill({
    tabId,
    discount,
    bill,
    payments = []
  }: {
    tabId: string
    discount?: Discount
    bill?: Bill
    payments?: Payment[]
  }): void {
    if (!bill) bill = getPendingBill.value(tabId)
    if (bill && discount) {
      bill.discount = discount
    }
    if (bill) {
      if (bill.products?.length || 0 > 0) {
        addBill({ tabId, bill })
        for (const payment of payments) {
          addPayment({
            ...payment,
            paymentId: payment.id,
            billId: bill.id,
            tabId
          })
        }
      }
    }
  }

  function removeTabBills(tabId: string): void {
    tabs.value[tabId].bills.forEach(billId => {
      sync.record('billRemoved', { tabId, billId })
    })
  }

  async function removeBill({
    tabId,
    billId
  }: {
    tabId: string
    billId: string
  }): Promise<void> {
    const { notifyError } = useNotifications()
    const hasPayments = bills.value[billId].payments.some(paymentId => {
      return payments.value[paymentId].deleted !== true
    })
    if (hasPayments) {
      notifyError({
        title: i18n.global.t('tabs.remove-bill-error'),
        description: i18n.global.t('tabs.refundable-payments-error')
      })
      return
    }
    const totalCashMachineAmount = bills.value[billId].payments
      .map(paymentId => {
        return payments.value[paymentId]
      })
      .filter(payment => CashMachine.methods.includes(payment.type))
      .reduce((sum, payment) => sum + payment.amount, 0)
    if (totalCashMachineAmount) {
      await CashMachine.payOut(totalCashMachineAmount)
    }
    sync.record('billRemoved', { tabId, billId })
  }

  function addPayment({
    billId,
    paymentId,
    amount,
    change = 0,
    tip = 0,
    type,
    tillId,
    metadata = '',
    tabId
  }: {
    billId: string
    paymentId?: string
    amount: number
    change?: number
    tip?: number
    type: string
    tillId?: string
    metadata?: string
    tabId: string
  }): void {
    if (amount == null || isNaN(amount)) {
      const error = new Error('Amount is required')
      logger.error('paymentAdded with no amount', {
        billId,
        paymentId,
        amount,
        wasNull: amount === null,
        wasUndefined: amount === undefined,
        wasNaN: isNaN(amount),
        change,
        tip,
        type,
        tillId,
        metadata,
        tabId,
        stackTrace: error.stack
      })
    }
    const payment: Payment = {
      id: paymentId || uuid(),
      amount,
      change,
      tip,
      type,
      creationTime: new Date(),
      metadata
    }

    if (tillId) {
      payment.tillId = tillId
    } else {
      payment.tillId = getPaymentTillId.value(tabId, type)
    }
    sync.record('paymentAdded', { billId, payment })
  }

  async function deletePayment({
    billId,
    paymentId
  }: {
    billId: string
    paymentId: string
  }): Promise<void> {
    const { notifyError } = useNotifications()
    const payment = payments.value[paymentId]
    const bill = bills.value[billId]
    if (CashMachine.methods.includes(payment.type)) {
      await CashMachine.payOut(payment.amount)
    }
    if (payment.type === 'dataphone') {
      const { error } = await Dataphone.refund(payment, bill.tabId)
      if (error) {
        notifyError({
          title: i18n.global.t('tabs.refund-payment-error')
        })
        return
      }
    }
    sync.record('paymentDeleted', { billId, paymentId })
  }

  function closeTab({
    tabId,
    closedWithPin = false
  }: {
    tabId: string
    closedWithPin?: boolean
  }): void {
    sync.record('tabClosedWithInfo', {
      tabId,
      closeTime: new Date(),
      closedWithPin,
      showPaidNotification: false
    })
    const tab = tabs.value[tabId]
    if (tab && tab.pickupType === 'ownDelivery' && tab.deliveryOrder) {
      updateDeliveryOrderStatus({
        tabId,
        newStatus: 'CLOSED'
      })
    }
  }

  function reopenTab({ tabId }: { tabId: string }) {
    sync.record('tabReopened', {
      tabId
    })
  }

  function createKitchenOrderByProducts({
    tabId,
    products
  }: {
    tabId: string
    products: TabProduct[]
  }): string[] {
    const kitchenProducts = parseKitchenProducts
      .value(tabId, products)
      .flatMap(product => {
        if (product.comboProducts) {
          return product.comboProducts.map((comboProduct: TabComboProduct) => ({
            ...comboProduct,
            quantity: comboProduct.quantity * product.quantity,
            fromCombo: true,
            combo: product
          }))
        } else {
          return [product]
        }
      })
    const printers = Object.values(configStore.config.printers)
    const orderVirtualBrandName = configStore.virtualBrands.filter(
      vb => vb.id === tabs.value[tabId].virtualBrandId
    )[0]?.name

    if (
      kitchenProducts.some(
        pr =>
          !Array.isArray(pr.modifiers) && typeof pr.modifiers !== 'undefined'
      )
    ) {
      logger.error('modifiers.map error', kitchenProducts)
    }

    const orders = Orders.generateOrders(
      kitchenProducts,
      tabs.value[tabId],
      tabs.value[tabId].deliveryOrder,
      tabs.value[tabId].tableName,
      authStore.currentEmployee?.name,
      printers,
      orderVirtualBrandName
    )

    return orders.map(order => {
      sync.record('kitchenOrderAdded', { tabId, order })

      return order.id
    })
  }

  function createKitchenOrders({
    tabId,
    course
  }: {
    tabId: string
    course: string
  }): string[] {
    const { unsentProducts } = useTabs(tabId)
    const parsedKitchenProducts = parseKitchenProducts.value(
      tabId,
      unsentProducts.value.filter(
        product => course === 'all' || product.course === course
      )
    )
    const printers = Object.values(configStore.config.printers)
    const orderVirtualBrandName = configStore.virtualBrands.filter(
      vb => vb.id === tabs.value[tabId].virtualBrandId
    )[0]?.name
    const orders = Orders.generateOrders(
      parsedKitchenProducts,
      tabs.value[tabId],
      tabs.value[tabId].deliveryOrder,
      tabs.value[tabId].tableName,
      authStore.currentEmployee?.name,
      printers,
      orderVirtualBrandName
    )

    const tab = tabs.value[tabId]
    if (tab && tab.deliveryOrder?.status === 'CREATED') {
      sync.record('deliveryOrderStatusUpdated', {
        tabId,
        newStatus: 'KITCHEN',
        date: new Date()
      })
    }
    return orders.map(order => {
      sync.record('kitchenOrderAdded', { tabId, order })
      return order.id
    })
  }

  function addKitchenOrderVersion(orderId: string): string {
    const order = kitchenOrders.value[orderId]
    const { allProducts } = useTabs(order.tabId)
    const productIds = order.versions
      .slice(-1)[0]
      .products.map(product => product.tabProductId)
    const products = parseKitchenProducts.value(
      order.tabId,
      allProducts.value
        .flatMap((product: TabProduct): TabProduct[] => {
          if (product.comboProducts && product.comboProducts.length > 0) {
            return product.comboProducts.map(
              comboProduct =>
                ({
                  ...comboProduct,
                  quantity: comboProduct.quantity * product.quantity,
                  fromCombo: true,
                  combo: product
                }) as unknown as TabProduct
            )
          } else {
            return [product]
          }
        })
        .filter(product => productIds.includes(product.id))
    )
    const version = Orders.generateOrderVersion(products)
    sync.record('kitchenOrderVersionAdded', { orderId, version })

    return order.id
  }

  type NormalizedTabsData = {
    products?: Record<string, TabProduct>
    tabs?: Record<string, Tab>
    bills?: Record<string, Bill>
    payments?: Record<string, Payment>
    kitchenOrders?: Record<string, KitchenOrder>
  }

  async function refreshCurrentTabs(tabsPayload: Tab[]): Promise<void> {
    const tabsData: NormalizedTabsData = normalizeTabs(tabsPayload)
    replaceTabs(tabsData)
    updateTabsActivationTime()
  }

  function replaceTabs(tabData: NormalizedTabsData): void {
    products.value = (tabData && tabData.products) || {}
    tabs.value = (tabData && tabData.tabs) || {}
    bills.value = (tabData && tabData.bills) || {}
    payments.value = (tabData && tabData.payments) || {}
    kitchenOrders.value = (tabData && tabData.kitchenOrders) || {}

    localDb.updateTables({
      'tabs:products': (tabData && tabData.products) || {},
      'tabs:tabs': (tabData && tabData.tabs) || {},
      'tabs:bills': (tabData && tabData.bills) || {},
      'tabs:payments': (tabData && tabData.payments) || {},
      'tabs:kitchenOrders': (tabData && tabData.kitchenOrders) || {}
    })
  }

  async function addFullTab(tab: Tab): Promise<void> {
    const isMaster = configStore.device.mode === 'master'
    if (isMaster && tab.activationTime) {
      const sound = new Howl({
        src: ['/notification.mp3']
      })
      sound.play()
      if (configStore.config.autoprintKitchenOrders) {
        tab.kitchenOrders.forEach(order => {
          TicketPrinter.printKitchenOrder(order)
        })
      }
      if (configStore.config.autoprintBills) {
        tab.bills.forEach(bill => {
          TicketPrinter.printBill(bill)
        })
      }
    }
    const tabData: NormalizedTabsData = normalizeTab(tab)
    refreshTab(tabData)
    updateTabsActivationTime()
  }

  function refreshTab(tabData: NormalizedTabsData): void {
    products.value = { ...products.value, ...tabData.products }
    tabs.value = { ...tabs.value, ...tabData.tabs }
    bills.value = { ...bills.value, ...tabData.bills }
    payments.value = { ...payments.value, ...tabData.payments }
    kitchenOrders.value = { ...kitchenOrders.value, ...tabData.kitchenOrders }

    localDb.patchTables({
      'tabs:products': tabData.products || {},
      'tabs:tabs': tabData.tabs || {},
      'tabs:bills': tabData.bills || {},
      'tabs:payments': tabData.payments || {},
      'tabs:kitchenOrders': tabData.kitchenOrders || {}
    })
  }

  function assignTables({
    tabId,
    tables
  }: {
    tabId: string
    tables: string[]
  }): void {
    const tab = tabs.value[tabId]
    sync.record('tablesAssigned', {
      tabId,
      tables,
      tableName: getTableName.value(tab.name, tables)
    })
  }

  function updateCourse({
    productId,
    course,
    comboProductId
  }: {
    productId: string
    course: string
    comboProductId?: string
  }): void {
    course = course || 'Main'
    if (!comboProductId) {
      sync.record('courseUpdated', { productId, course })
    } else {
      const comboProducts = [...products.value[productId].comboProducts].map(
        comboProduct => ({
          ...comboProduct,
          course:
            comboProduct.id === comboProductId ? course : comboProduct.course
        })
      )

      sync.record('comboProductsUpdated', {
        comboId: productId,
        products: comboProducts,
        productPricing: {
          fullPrice: Math.max(products.value[productId].fullPrice, 0),
          finalPrice: Math.max(products.value[productId].finalPrice, 0)
        }
      })
    }
  }

  function addSeat(tabId: string): void {
    sync.record('seatAdded', tabId)
  }

  function changePickupType({
    tabId,
    pickupType
  }: {
    tabId: string
    pickupType?: PickupType
  }): void {
    if (!tabs.value[tabId]) return
    sync.record('updateTabPickupType', { tabId, pickupType })
  }

  function mergeTabs({
    tabAId,
    tabBId
  }: {
    tabAId: string
    tabBId: string
  }): void {
    const tabA = tabs.value[tabAId]
    const tabB = tabs.value[tabBId]
    const newTab = {
      id: uuid(),
      open: true,
      source: 'Restaurant',
      lang: tabA.lang,
      name: [tabA.name, tabB.name].filter(name => name).join(' '),
      pickupType: tabA.pickupType || tabB.pickupType,
      creationTime: new Date(
        Math.min(
          new Date(tabA.creationTime).getTime(),
          new Date(tabB.creationTime).getTime()
        )
      ),
      activationTime: new Date(
        Math.min(
          new Date(tabA.activationTime).getTime(),
          new Date(tabB.activationTime).getTime()
        )
      ),
      tableName: getTableName.value(
        [tabA.name, tabB.name].filter(name => name).join(' '),
        [...tabA.tables, ...tabB.tables]
      ),
      terraceSurchargePercentage:
        Math.max(
          tabA.terraceSurchargePercentage || 0,
          tabB.terraceSurchargePercentage || 0
        ) || 0,
      virtualBrandId: tabA.virtualBrandId || tabB.virtualBrandId
    }
    sync.record('mergeTabs', {
      tabAId,
      tabBId,
      newTab
    })
  }

  function regenerateBill({
    tabId,
    billId,
    customerCompany
  }: {
    tabId: string
    billId: string
    customerCompany?: string
  }): string {
    let bill = bills.value[billId]
    if (!!bill.customerCompany !== !!customerCompany) {
      bill = Bills.generateInvoice(bill, customerCompany)
    } else {
      bill = {
        ...bill,
        customerCompany
      }
    }
    sync.record('billRegenerated', { tabId, oldBillId: billId, bill })

    return bill.id
  }

  function startBilling(tabId: string) {
    if (!tabs.value[tabId].billingStartedTime) {
      sync.record('billingStarted', { tabId, timestamp: new Date() })
    }
  }

  function deleteOldTabs(): void {
    let minSeconds = 60 * 60 * 24
    const featureToggles = configStore.config.featureToggles
    if (featureToggles['fast_tab_deletion']) {
      minSeconds = 60 * 60 * 4
    }
    const oldTabs = Object.values(tabs.value)
      .filter(tab => tab.closeTime)
      .filter(tab => {
        const diff = (Date.now() - new Date(tab.closeTime).getTime()) / 1000

        return diff > minSeconds
      })
    oldTabs.forEach(tab => deleteTabLocally({ tabId: tab.id }))
  }

  function deleteTabLocally({ tabId }: { tabId: string }): void {
    const tab = tabs.value[tabId]
    delete tabs.value[tabId]
    localDb.removeItem('tabs:tabs', tabId)
    tab.bills.forEach(billId => {
      const bill = bills.value[billId]
      bill.payments.forEach(paymentId => {
        delete payments.value[paymentId]
        localDb.removeItem('tabs:payments', paymentId)
      })
      delete bills.value[billId]
      delete billsMetadata.value[billId]
      localDb.removeItem('tabs:bills', billId)
    })
    tab.kitchenOrders.forEach(orderId => {
      delete kitchenOrders.value[orderId]
      localDb.removeItem('tabs:kitchenOrders', orderId)
    })
    tab.shared?.forEach(productId => {
      delete products.value[productId]
      localDb.removeItem('tabs:products', productId)
    })
    tab.seats?.flat().forEach(productId => {
      delete products.value[productId]
      localDb.removeItem('tabs:products', productId)
    })
  }

  function updateTabActivationTime({
    tabId,
    activationTime
  }: {
    tabId: string
    activationTime: string
  }): void {
    tabs.value[tabId].activationTime = activationTime
    const tab = tabs.value[tabId]
    tabs.value[tabId].bills.forEach(billId => {
      const bill = bills.value[billId]
      bills.value[billId] = {
        ...bill,
        activationTime,
        schedulingTime: tab?.schedulingTime
      }
    })
    tabs.value[tabId].kitchenOrders.forEach(orderId => {
      const order = kitchenOrders.value[orderId]
      kitchenOrders.value[orderId] = { ...order, activationTime }
    })
    localDb?.setItem('tabs:tabs', tabId, tabs.value[tabId])
  }

  async function activateTab({
    tab,
    sendEvent = false
  }: {
    tab: Tab
    sendEvent: boolean
  }): Promise<void> {
    const _tab = {
      ...tab,
      activationTime: moment(tab.schedulingTime).add(
        -configStore.config.preparationMinutes,
        'minutes'
      )
    }
    if (sendEvent) {
      sync.record('updateTabActivationTime', {
        tabId: _tab.id,
        activationTime: new Date()
      })
      if (configStore.config.autoprintKitchenOrders) {
        createKitchenOrders({
          tabId: _tab.id,
          course: 'all'
        })
        tabs.value[_tab.id].kitchenOrders
          .map(orderId => kitchenOrders.value[orderId])
          .forEach(order => TicketPrinter.printKitchenOrder(order))
      }
      if (
        tabs.value[_tab.id].kitchenOrders.length > 0 &&
        tab &&
        tab.deliveryOrder?.status === 'CREATED'
      ) {
        sync.record('deliveryOrderStatusUpdated', {
          tabId: tab.id,
          newStatus: 'KITCHEN',
          date: new Date()
        })
      }
      if (configStore.config.autoprintBills) {
        generatePendingBill({ tabId: _tab.id })
        const bills = getBills.value(_tab.id)
        bills.forEach((bill: Bill) => TicketPrinter.printBill(bill))
      }
    } else {
      updateTabActivationTime({
        tabId: _tab.id,
        activationTime: new Date().toISOString()
      })
    }
  }

  function updateTabsActivationTime() {
    if (!tillStore.shiftIsStarted && tillStore.shiftsEnabled) return

    const now = moment()
    const config = useConfigStore()

    for (const tab of Object.values(tabs.value)) {
      try {
        if (!tab.schedulingTime || !!tab.activationTime) continue

        const marginTime = getActivationMarginPerVirtualBrandId.value(
          tab.virtualBrandId
        )
        const activationTime = moment(
          tab.deliveryOrder?.pickupTime || tab.schedulingTime
        ).subtract(marginTime, 'minutes')

        if (now.isAfter(activationTime)) {
          activateTab({
            tab,
            sendEvent: config.device.mode === 'master' && !!config.device.id
          })
        }
      } catch (e) {
        logger.error('Error updating tab activation time', e as any)
      }
    }
  }

  function updateTerraceSurchargePercentage({
    tabId,
    enabled
  }: {
    tabId: string
    enabled: boolean
  }): void {
    const table = tablesStore.tables[tabs.value[tabId].tables[0]]
    if (enabled) {
      sync.record('terraceSurchargePercentageUpdated', {
        tabId,
        newPercentage: table.terraceSurchargePercentage
      })
    } else {
      sync.record('terraceSurchargePercentageUpdated', {
        tabId,
        newPercentage: 0
      })
    }
  }

  function updateTabOrdering({
    tabId,
    orderingMode
  }: {
    tabId: string
    orderingMode: OrderingMode
  }): void {
    sync.record('tabOrderingUpdated', { tabId, orderingMode })
  }

  function tabProductsUpdated(tabId: string) {
    promotionsStore.recalculatePromotionsInProducts(tabId)
  }

  return {
    route,
    router,
    t,
    // State
    tabs,
    products,
    bills,
    billsMetadata,
    payments,
    kitchenOrders,
    refundablePaymentMethods,
    // Computed
    parseKitchenProducts,
    getTab,
    isTabOpen,
    getPayment,
    getBillById,
    getBills,
    getTableName,
    getSentToKitchenProductsByTabId,
    getSentToKitchenProducts,
    hasSentToKitchenProducts,
    getSentToKitchenTime,
    getProductKitchenOrderIds,
    getDeliveryFee,
    getMinimumBasketSurcharge,
    getGlobalDiscountByTabId,
    getByTableId,
    getByBillId,
    getBillPayments,
    getNegativeTabPayments,
    getLastInteraction,
    getPendingBill,
    isDeliveryTab,
    getDeliveryTillId,
    getPaymentTillId,
    getTabPreparationMinutes,
    getActivationMarginPerVirtualBrandId,
    // Methods
    formatTab,
    openTab,
    openTabWithCustomer,
    addProduct,
    moveProductTab,
    moveProduct,
    setPreferredPaymentMethod,
    updateProductModifiers,
    updateComboProducts,
    splitProduct,
    updateProductDiscount,
    updateProductQuantity,
    removeProduct,
    updateDeliveryOrderStatus,
    removeSeat,
    changePickupType,
    addBill,
    generatePendingBill,
    removeTabBills,
    removeBill,
    addPayment,
    deletePayment,
    closeTab,
    reopenTab,
    createKitchenOrderByProducts,
    createKitchenOrders,
    addKitchenOrderVersion,
    refreshCurrentTabs,
    replaceTabs,
    addFullTab,
    refreshTab,
    assignTables,
    updateCourse,
    addSeat,
    mergeTabs,
    regenerateBill,
    startBilling,
    deleteOldTabs,
    deleteTabLocally,
    updateTabActivationTime,
    activateTab,
    updateTabsActivationTime,
    updateTerraceSurchargePercentage,
    updateTabOrdering
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTablesStore, import.meta.hot))
}

import PrinterQueue from './printerQueue'
import { useTillStore } from '@/store/till'
import { useConfigStore } from '@/store/config'
import { Printer } from './types'
import { EventName } from '@/sync/service'

let queues: Record<string, PrinterQueue> = {}

function loadPrinters(printers: Printer[]) {
  const ipQueues = printers.reduce(
    (res, printer) => {
      try {
        res[printer.ip] = new PrinterQueue(printer)
      } catch (_) {
        // Unsupported printer
      }
      return res
    },
    {} as Record<string, PrinterQueue>
  )
  queues = printers.reduce(
    (res, printer) => {
      try {
        if (printer.ip) res[printer.id] = ipQueues[printer.ip]
        else res[printer.id] = new PrinterQueue(printer)
      } catch (_) {
        // Unsupported printer
      }
      return res
    },
    {} as Record<string, PrinterQueue>
  )
  const { currentAdyenDataphone } = useConfigStore()
  if (currentAdyenDataphone) {
    queues['self'] = new PrinterQueue({
      name: 'Adyen Dataphone',
      type: 'AdyenPrinter',
      id: 'self',
      metadata: {
        poiid: currentAdyenDataphone.poiid
      },
      ip: 'none',
      ticketWidth: 0,
      ticketHeight: 0,
      ticketPaddingLeft: 0
    })
  }
}

function printImage(
  printerId: string,
  imageData: string,
  copies: number = 1,
  printedEvent?: {
    name: EventName
    data: any
  }
) {
  for (let i = 0; i < copies; ++i) {
    const queue = queues[printerId]
    if (queue) {
      queue.addJob({
        type: 'printImage',
        image: imageData,
        printedEvent,
        time: new Date()
      })
    }
  }
}

function openCashDrawer() {
  const till = useTillStore()
  const config = useConfigStore()
  const printerId =
    (till.selectedCashTill && till.selectedCashTill.printerId) ||
    config.config.defaultBillPrinter
  if (!printerId) return
  const queue = queues[printerId]
  if (queue) {
    queue.addJob({
      type: 'openCashDrawer'
    })
  }
}

export default { printImage, openCashDrawer, loadPrinters }

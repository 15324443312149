<template>
  <tippy
    ref="contextMenu"
    :interactive="true"
    :arrow="false"
    trigger="auto"
    theme="contextmenu"
    :placement="placement"
    :duration="100"
    :append-to="appendTo"
    :on-click-outside="onClickOutside"
    :hide-on-click="false"
    :on-hidden="() => (open = false)"
    @show="scrollToElement"
  >
    <slot />
    <template #content>
      <div
        class="py-2 z-20 overflow-y-scroll text-n-600 bg-n-0 dark:bg-n-800 dark:text-n-0 dark:border-n-700"
        :class="[
          fullscreen
            ? 'w-screen h-dvh fixed left-0 right-0'
            : 'max-w-80 max-h-80 border border-n-100',
          size === 'x-small'
            ? '-mt-1 rounded-lg min-w-32'
            : 'mt-2 rounded-xl min-w-48'
        ]"
      >
        <div
          class=""
          :class="[size === 'x-small' ? 'px-2 text-xs' : 'px-4 py-3 text-sm']"
        >
          <slot name="content" :options="options">
            <l-search-bar v-if="search" v-model="query" class="mb-2" />
            <div
              v-for="option in filteredOptions"
              :key="option.value"
              ref="optionEl"
            >
              <dropdown-option
                :model-value="models[option.value]"
                :option="option"
                :multiselect="multiselect"
                :has-checkbox="hasCheckbox"
                :has-radio="hasRadio"
                :mark-selected="markSelected"
                :size="size"
                :partially-selected="
                  partiallySelectedValues.includes(option.value)
                "
                @partial-removed="removeFromPartiallySelected"
                @update:model-value="value => selectOption(option, value)"
              />
            </div>
          </slot>
        </div>
      </div>
    </template>
  </tippy>
</template>

<script setup lang="ts">
import {
  computed,
  defineModel,
  defineProps,
  nextTick,
  onMounted,
  ref,
  useTemplateRef,
  watch
} from 'vue'
import { Tippy, type TippyOptions } from 'vue-tippy'

import { lastUtils } from '@last/core'
import { LSearchBar } from '@last/core-ui/paprika'
import type { Size } from '@last/core-ui/paprika/components/types'

import DropdownOption from './DropdownOption.vue'
import type { Model, Option } from './types'

type Props = {
  options: Option[]
  allowRemove?: boolean
  search?: boolean
  multiselect?: boolean
  fullscreen?: boolean
  markSelected?: boolean
  /** Change placement of the open content */
  placement?: TippyOptions['placement']
  /** Change default `appendTo` of Tippy */
  appendTo?: TippyOptions['appendTo']
  size?: Size
  blockHideOnClick?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  markSelected: true,
  placement: 'bottom-start',
  size: 'medium',
  blockHideOnClick: false,
  appendTo: () => document.body
})

const selected = defineModel<string[]>({
  default: [],
  required: false
})

const partiallySelectedValues = defineModel<(string | number)[]>(
  'partiallySelectedValues',
  {
    default: []
  }
)

const open = defineModel<boolean>('open')
const contextMenuRef = useTemplateRef<typeof Tippy>('contextMenu')
const listOptions = useTemplateRef('optionEl')

function onClickOutside(): void {
  if (!!contextMenuRef.value && !props.blockHideOnClick) {
    contextMenuRef?.value?.hide()
  }
}

const query = ref('')

const models = computed(() => {
  return props.options.reduce(
    (acc, option) => {
      acc[option.value] = {
        value: option.value,
        selected: selected.value.includes(option.value),
        children: option.children
          ? option.children.map(child => ({
              value: child.value,
              selected: selected.value.includes(child.value)
            }))
          : []
      }
      return acc
    },
    {} as Record<string, Model>
  )
})

const filteredOptions = computed(() => {
  if (!query.value) return props.options
  return props.options.flatMap(option => {
    if (lastUtils.fuzzy(option.label, query.value)) return [option]
    if (!option.children) return []
    const filteredChildren = option.children.filter(child =>
      lastUtils.fuzzy(child.label, query.value)
    )
    return filteredChildren.length
      ? [{ ...option, children: filteredChildren }]
      : []
  })
})

const hasCheckbox = computed<boolean>(() => {
  return props.options.some(option => {
    return option.checkbox || option.children?.some(child => child.checkbox)
  })
})

const hasRadio = computed<boolean>(() => {
  return props.options.some(option => {
    return option.radio || option.children?.some(child => child.radio)
  })
})

function selectOption(option: Option, selection: Model) {
  const optionValues = [
    option.value,
    ...(option.children?.map(child => child.value) || [])
  ]
  const selectedValues: string[] = selection.selected ? [selection.value] : []
  for (const child of selection.children ?? []) {
    if (child.selected) selectedValues.push(child.value)
  }
  if (!props.multiselect) {
    selected.value = selectedValues
    if (!props.blockHideOnClick) {
      open.value = false
    }
  } else {
    const newSelected = selected.value
      .filter(value => !optionValues.includes(value))
      .concat(selectedValues)
    selected.value = newSelected
  }
}

function scrollToElement() {
  const selectedIndex = Object.values(models.value).findIndex(
    model => model.selected
  )
  if (selectedIndex === -1) return
  nextTick(() => {
    listOptions.value?.at(selectedIndex)?.scrollIntoView({
      block: 'center'
    })
  })
}

watch(open, value => {
  if (value) {
    contextMenuRef.value?.show()
  } else {
    contextMenuRef.value?.hide()
  }
})

onMounted(() => {
  if (open.value) {
    contextMenuRef.value?.show()
  }
})

function removeFromPartiallySelected(value: string | number): void {
  const index = partiallySelectedValues.value.indexOf(value)
  if (index === -1) return
  partiallySelectedValues.value.splice(index, 1)
}
</script>

<style>
@import 'tippy.js/dist/tippy.css';

.tippy-box[data-theme~='contextmenu'] {
  background: none !important;
}

.tippy-box[data-theme~='contextmenu'] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~='contextmenu'][data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

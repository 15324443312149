import type { Router } from 'vue-router'
import { inject } from 'vue'
import type { Plugin } from 'vue'
import { ConsoleTracker } from './consoleTracker'

const TRACKER_KEY = 'tracker'

export interface UserData {
  id: string
  name?: string
}

export interface Tracker {
  identify(userData: UserData): void
  track(eventName: string, eventProperties?: Record<string, any>): void
  getFeatureFlag(name: string): Promise<boolean | string | undefined> | boolean
  hasFeatureFlag(name: string): Promise<boolean> | boolean
  clear(): void
}

export const trackerPlugin: Plugin<[{ router?: Router; tracker: Tracker }]> = {
  install(app, options) {
    const router: Router | undefined = options.router
    const tracker: Tracker = options.tracker
    app.provide(TRACKER_KEY, tracker)
    if (router) {
      router.afterEach(to => {
        tracker.track('$pageview', {
          path: to.path
        })
      })
    }
  }
}

export function useTracker() {
  return inject<Tracker>(TRACKER_KEY, new ConsoleTracker())
}

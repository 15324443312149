<template>
  <search-customer
    v-if="activeStep === 'search'"
    :title="
      type === 'delivery'
        ? $t('new-tab.new-tab-delivery')
        : $t('new-tab.new-tab-take-away')
    "
    :type="type"
    @continue="onSearchResults"
    @close="$emit('close')"
  />
  <client-details
    v-else-if="activeStep === 'client'"
    :customer="internalCustomer"
    :delivery="delivery"
    :tab="internalTab"
    :last-customer-tabs="lastCustomerTabs"
    :is-edit="isEdit"
    @continue="onClientResults"
    @close="$emit('close')"
  />
  <tab-details
    v-else
    :customer="internalCustomer"
    :is-edit="isEdit"
    :is-own-delivery="true"
    :tab="internalTab"
    :address="internalAddress"
    @create="onCreate"
    @update="onUpdate"
    @close="$emit('close')"
  />
</template>

<script setup lang="ts">
import { Address, CustomerInfo, Tab } from '@/types'
import ClientDetails from './ClientDetails.vue'
import SearchCustomer from './SearchCustomer.vue'
import TabDetails from './TabDetails.vue'
import { onMounted, ref } from 'vue'
import { computed } from 'vue'
import { SearchCustomerResult } from './types'
import { useBillingStore } from '@/store/billing'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import { useTabsStore } from '@/store/tabs'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import sync from '@/sync/service'

const emit = defineEmits(['close', 'tabCreated'])

const props = defineProps<{
  type: 'takeAway' | 'delivery'
  customer?: CustomerInfo
  tab?: Tab
}>()

const router = useRouter()
const tabsStore = useTabsStore()
const billingStore = useBillingStore()

const { removeTabBills, generatePendingBill, openTabWithCustomer, openTab } =
  tabsStore
const { getBills, getGlobalDiscountByTabId } = storeToRefs(tabsStore)
const { getCurrentBill } = storeToRefs(billingStore)

const internalTab = ref<Partial<Tab>>({
  source: props.type === 'takeAway' ? undefined : 'OwnDelivery',
  pickupType: props.type === 'takeAway' ? 'takeAway' : 'ownDelivery'
})

const internalCustomer = ref<any>({})
const lastCustomerTabs = ref<Tab[]>([])
const internalAddress = ref<Partial<Address>>({})

const activeStep = ref<'search' | 'client' | 'tab'>('search')
const isEdit = ref(false)

onMounted(() => {
  if (props.customer && props.tab) {
    internalCustomer.value = props.customer
    internalTab.value = props.tab
    activeStep.value = 'client'
    isEdit.value = true
  }
})

const delivery = computed(() => {
  return internalCustomer.value.addresses
    ? internalCustomer.value.addresses[0]
    : {}
})

function onSearchResults(results: SearchCustomerResult) {
  if (!results.found) {
    createTakeAwayTab(results.tab)
  } else {
    activeStep.value = 'client'
    internalCustomer.value = results.customer
    lastCustomerTabs.value = results.tabs
    internalTab.value = {
      ...internalTab.value,
      ...results.tab,
      virtualBrandId: results.virtualBrandId
    }
  }
}

function onClientResults(value: any) {
  activeStep.value = 'tab'
  internalCustomer.value = { ...internalCustomer.value, ...value.customer }
  internalAddress.value = { ...internalAddress.value, ...value.address }
  internalTab.value = { ...internalTab.value, ...value.tab }
}

function onCreate({ tab: inputTab, customer: inputCustomer }: any) {
  const tabId = openTabWithCustomer({
    tableId: null,
    tab: inputTab,
    customer: inputCustomer
  })
  emit('tabCreated', tabId)
  emit('close')
  router.push({
    name: 'orderManagement',
    params: { tabId: tabId }
  })
}

function onUpdate({ tab: inputTab }: any) {
  internalTab.value = inputTab
  sync.record('tabDetailsUpdated', internalTab.value)

  if (billNeedsRegeneration.value) {
    removeTabBills(internalTab.value.id)
    printBill()
  }
  emit('close')
}

const billNeedsRegeneration = computed(() => {
  if (!props.customer) return false
  return (
    props.customer.name !== internalCustomer.value.name ||
    props.customer.surname !== internalCustomer.value.surname ||
    props.customer.phoneNumber !== internalCustomer.value.phoneNumber ||
    props.customer.email !== internalCustomer.value.email ||
    props.customer.addresses[0].address !==
      internalCustomer.value.addresses[0].address ||
    props.customer.addresses[0].details !==
      internalCustomer.value.addresses[0].details ||
    props.customer.addresses[0].postalCode !==
      internalCustomer.value.addresses[0].postalCode
  )
})

async function createTakeAwayTab(partialTab: any) {
  const tab: Tab = {
    ...partialTab,
    pickupType: 'takeAway',
    deliveryOrder: {
      source: null,
      status: 'CREATED',
      deliveryOrderStatuses: [
        {
          status: 'CREATED',
          creationTime: new Date()
        }
      ]
    }
  }
  const tabId = openTab({ tableId: null, tab })
  emit('tabCreated', tabId)
  emit('close')
  router.push({
    name: 'orderManagement',
    params: { tabId }
  })
}

function printBill() {
  generatePendingBill({
    tabId: internalTab.value.id,
    bill: getCurrentBill.value({
      tabId: internalTab.value.id,
      discount: getGlobalDiscountByTabId.value(internalTab.value.id), // FIXME
      selectedProductIds: internalTab.value.products,
      includeAlreadyBilled: true
    })
  })
  const bills = getBills.value(internalTab.value.id)
  bills.forEach(TicketPrinter.printBill)
}
</script>

import { addMinutes, isWithinInterval, subHours, subMinutes } from 'date-fns'
import { normalize, schema } from 'normalizr'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

import sync from '@/sync/service'
import { Booking, Bookings } from '@/types'

import { useConfigStore } from './config'

const reservationSchema = new schema.Entity('reservations')

export const useBookingsStore = defineStore(
  'reservations',
  () => {
    const bookings = ref<Bookings>({})

    const nearBookings = computed(() => {
      return Object.entries(bookings.value).reduce(
        (res: Record<string, Booking>, [reservationId, reservation]) => {
          const isInRange = isWithinInterval(new Date(reservation.dateTime), {
            start: subMinutes(new Date(), 240),
            end: addMinutes(new Date(), 240)
          })
          if (isInRange) {
            res[reservationId] = reservation
          }
          return res
        },
        {}
      )
    })

    const nearBookingsList = computed(() => {
      return Object.values(nearBookings.value)
    })

    const sortedBookings = computed(() => {
      return Object.values(bookings.value)
        .sort(
          (a, b) =>
            new Date(a.dateTime).valueOf() - new Date(b.dateTime).valueOf()
        )
        .filter(
          reservation =>
            new Date(reservation.dateTime).valueOf() >
              subHours(new Date(), 1).getTime() && !reservation.cancelled
        )
    })

    const getBooking = computed(() => (tableId: string, date?: Date) => {
      const config = useConfigStore()

      return Object.values(bookings.value).find(reservation => {
        const inRange = isWithinInterval(new Date(reservation.dateTime), {
          start: subMinutes(date ?? new Date(), 15),
          end: addMinutes(date ?? new Date(), config.reservations.duration)
        })
        const hasTab = reservation.tabId
        const rightTable = reservation.tables?.includes(tableId)

        return !hasTab && rightTable && inRange
      })
    })

    function createBooking(reservation: Booking): void {
      sync.record('reservationCreated', { ...reservation, source: 'pos' })
    }

    function editBooking(reservation: Booking): void {
      sync.record('reservationChanged', reservation)
    }

    function cancelReservation(reservationId: string): void {
      sync.record('reservationCancelled', { reservationId })
    }

    function refreshBookings(inputReservations: Bookings): void {
      const entities = normalize(inputReservations, [
        reservationSchema
      ]).entities
      bookings.value = entities.reservations || {}
    }

    return {
      bookings,
      nearBookings,
      nearBookingsList,
      getBooking,
      sortedBookings,
      createBooking,
      editBooking,
      cancelReservation,
      refreshBookings
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBookingsStore, import.meta.hot))
}

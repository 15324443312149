import posthog from 'posthog-js'

import type { Tracker, UserData } from './tracker'

export class PosthogTracker implements Tracker {
  constructor(apiKey: string) {
    posthog.init(apiKey, {
      api_host: 'https://us.i.posthog.com',
      autocapture: false,
      capture_pageview: false,
      disable_session_recording: true
    })
  }

  track(eventName: string, eventProperties: Record<string, any>) {
    posthog.capture(eventName, eventProperties)
  }

  identify(userData: UserData) {
    posthog.identify(userData.id, { name: userData.name })
  }

  async getFeatureFlag(name: string): Promise<boolean | string | undefined> {
    return new Promise(resolve => {
      posthog.onFeatureFlags(() => {
        const flag = posthog.getFeatureFlag(name)
        resolve(flag)
      })
    })
  }

  async hasFeatureFlag(name: string): Promise<boolean> {
    return new Promise(resolve => {
      posthog.onFeatureFlags(() => {
        const flag = posthog.isFeatureEnabled(name)
        resolve(Boolean(flag))
      })
    })
  }

  clear() {
    posthog.reset()
  }
}

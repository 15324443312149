<template>
  <table class="w-full">
    <tbody>
      <tr class="border-b border-n-200 font-body text-sm text-n-200 text-left">
        <th class="px-2 py-3">{{ $t('end-shift.time') }}</th>
        <th class="px-2 py-3">{{ $t('end-shift.concept') }}</th>
        <th class="px-2 py-3">{{ $t('end-shift.amount') }}</th>
      </tr>
      <tr v-for="(movement, index) in movements" :key="index">
        <td class="px-2 py-3">{{ $d(movement.creationTime, 'time') }}</td>
        <td class="px-2 py-3">{{ movement.detail || movement.tabCode }}</td>
        <td class="px-2 py-3">{{ currency(movement.diff) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { useMoney } from '@/composables/useMoney'
import { ShiftMovement } from '@/types'

defineProps<{
  movements: ShiftMovement[]
  currentMethod: string
}>()

const { currency } = useMoney()
</script>

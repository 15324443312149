<template>
  <full-screen :title="$t('report.real-time-report')" @close="$router.go(-1)">
    <div class="w-full h-auto flex items-center justify-center">
      <div class="w-[33rem] h-full">
        <div class="py-4">
          <report-preview v-if="report" :report="report" @print="print()" />
        </div>
      </div>
    </div>
  </full-screen>
</template>

<script setup lang="ts">
import FullScreen from '@/components/core/FullScreen.vue'
import ReportPreview from './ReportPreview.vue'
import moment from 'moment'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { getReportZ } from '@/api/reports'

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const report = ref<any>(null)
const endOfDay = computed(() => config.value.workingTime.end)

const today = computed(() => {
  const endTimeParts = endOfDay.value.split(':')
  const hour = parseInt(endTimeParts[0])
  const minute = parseInt(endTimeParts[1])
  return moment()
    .subtract(hour, 'hours')
    .subtract(minute, 'minutes')
    .format('YYYY-MM-DD')
})

async function refreshReport() {
  report.value = await getReportZ({
    startDate: today.value,
    endDate: today.value
  })
}

function print() {
  TicketPrinter.printRealTimeReport()
}

onMounted(() => {
  refreshReport()
})
</script>

<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen flex justify-center items-center z-50 bg-n-900/80 backdrop-blur-sm p-4"
    @click="dismissable && cancel()"
  >
    <div
      class="bg-n-0 dark:bg-n-700 rounded-2xl shadow-lg p-4 pb-8 text-center relative w-[500px] m-auto flex flex-col gap-3 text-n-800 dark:text-n-0"
      @click.stop
    >
      <icon
        v-if="!hiddenClose"
        name="close"
        class="text-n-600 dark:text-n-0 cursor-pointer self-end"
        @click="cancel()"
      />
      <div
        v-if="icon || image || avatar"
        class="flex justify-center items-center pb-2"
      >
        <div v-if="icon" :class="(iconColor, iconAnimation)">
          <icon :name="icon" size="medium" />
        </div>
        <div v-else-if="image" class="w-40 h-40">
          <img :src="image" />
        </div>
        <l-avatar v-else :value="avatar" mode="image" size="extra-large" />
      </div>
      <div class="font-heading text-2xl font-bold whitespace-normal">
        {{ title }}
      </div>
      <div v-if="content" class="whitespace-pre-line">
        {{ content }}
      </div>
      <div v-if="html" v-html="html"></div>
      <div v-if="subContent" class="text-n-300 whitespace-pre-line">
        {{ subContent }}
      </div>
      <div v-if="!noLabels" class="flex gap-3 pt-6 px-8">
        <l-button
          v-if="secondaryLabel"
          type="secondary"
          class="w-full"
          :loading="loadingSecondary"
          :disabled="loading"
          @click="secondary()"
        >
          {{ secondaryLabel ?? $t('ctas.cancel') }}
        </l-button>
        <l-button
          type="primary"
          class="w-full"
          @click="confirm()"
          :loading="loading"
          :disabled="loadingSecondary || disabled"
        >
          {{ mainLabel ?? $t('ctas.confirm') }}
        </l-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon, LButton, LAvatar } from '@last/core-ui/paprika'
import { ref } from 'vue'

export type Props = {
  title: string
  mainLabel?: string
  secondaryLabel?: string
  content?: string
  html?: string
  disabled?: boolean 
  subContent?: string
  icon?: string
  iconAnimation?: 'animate-spin'
  iconColor?: string
  noLabels?: boolean
  hiddenClose?: boolean
  dismissable?: boolean
  image?: string
  avatar?: string
  onConfirm?: () => void | Promise<void>
  onSecondary?: () => void | Promise<void>
}

const props = defineProps<Props>()

const emit = defineEmits(['cancel', 'close'])

const loading = ref(false)
const loadingSecondary = ref(false)

function close() {
  emit('close')
}

function cancel() {
  emit('cancel')
  close()
}

function secondary() {
  loadingSecondary.value = true
  props.onSecondary?.()
  close()
  loadingSecondary.value = false
}

async function confirm() {
  loading.value = true
  await props.onConfirm?.()
  close()
  loading.value = false
}
</script>

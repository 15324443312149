<template>
  <l-modal
    :title="title"
    :button-text="$t('new-delivery.cta-continue')"
    :button-disabled="!isComplete"
    @action="confirm"
    @close="$emit('close')"
  >
    <l-field :label="$t('new-delivery.client-phone-label')">
      <l-phone-input
        v-model="form.phoneNumber"
        :placeholder="$t('new-delivery.client-phone-placeholder')"
        :wrong-value="phoneValidated && !validPhoneNumber"
        :default-country-code="config.locationCountryCode"
      />
    </l-field>

    <l-field
      v-if="enabledBrands.length > 1"
      :label="$t('new-delivery.select-virtual-brand')"
    >
      <l-select v-model="form.virtualBrandId" :options="enabledBrands" />
    </l-field>

    <div v-if="type === 'takeAway'" class="flex justify-between">
      <l-field class="flex-1 mr-4" :label="$t('new-delivery.tab-name-label')">
        <l-input
          v-model="form.name"
          :placeholder="$t('new-delivery.tab-name-placeholder')"
        />
      </l-field>
      <l-field class="flex-1" :label="$t('new-delivery.client-number-label')">
        <l-select
          v-model="form.seats"
          :options="numberOfPeople"
          :placeholder="$t('new-delivery.client-number-label')"
        />
      </l-field>
    </div>

    <external-delivery-tab v-if="type === 'delivery'" v-model="form" />
  </l-modal>
</template>

<script setup lang="ts">
import {
  LModal,
  LField,
  LPhoneInput,
  LSelect,
  LInput
} from '@last/core-ui/paprika'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import ExternalDeliveryTab from './ManualDeliveryTab.vue'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { computed } from 'vue'
import { getCustomer } from '@/api/customers'
import { NewTabForm, SearchCustomerResult } from './types'
import { v4 as uuid } from 'uuid'
import { useTabsStore } from '@/store/tabs'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

type Props = {
  title: string
  type: 'takeAway' | 'delivery'
}

const props = defineProps<Props>()

const emit = defineEmits<{
  close: []
  continue: [data: SearchCustomerResult]
}>()

const configStore = useConfigStore()
const { config, virtualBrands } = storeToRefs(configStore)
const router = useRouter()
const { t } = useI18n()

const tabsStore = useTabsStore()
const { openTab } = tabsStore

const form = ref<NewTabForm>({})

const loading = ref(false)
const phoneValidated = ref(false)

onMounted(() => {
  form.value.virtualBrandId = enabledBrands.value[0].value
})

const enabledBrands = computed(() => {
  return virtualBrands.value
    .filter(virtualBrand => virtualBrand.enabled)
    .map(virtualBrand => {
      return {
        value: virtualBrand.id,
        label: virtualBrand.name
      }
    })
})

const isComplete = computed(() => {
  if (props.type === 'takeAway') {
    return true
  }
  return form.value.source && validPhoneNumber.value
})

const validPhoneNumber = computed(() => {
  const parsed = parsePhoneNumberFromString(form.value.phoneNumber || '')
  return parsed?.isValid()
})

const isDeliveryComplete = computed(() => {
  return !!form.value.source && !!form.value.name
})

const numberOfPeople = computed(() => {
  const numberOfPeople = []
  for (let i = 0; i < 20; i++) {
    numberOfPeople[i] = {
      label: `${i < 9 ? '0' : ''}${i + 1} ${t('new-delivery.client-number')}`,
      value: i + 1
    }
  }
  return numberOfPeople
})

function confirm() {
  if (props.type === 'delivery' && form.value.source !== 'OwnDelivery') {
    manualExternalDelivery()
  } else {
    checkNextStep()
  }
}

async function checkNextStep() {
  phoneValidated.value = true
  if (!isComplete.value) return
  if (form.value.phoneNumber && validPhoneNumber.value) {
    if (loading.value) return
    loading.value = true
    let customer: any = {
      phoneNumber: form.value.phoneNumber
    }
    let tabs = []
    try {
      const data = await getCustomer(form.value.phoneNumber)
      customer = {
        ...data.customer,
        points: data.points
      }
      tabs = data.tabs
    } catch (_) {
      // Customer not found
    } finally {
      emit('continue', {
        found: true,
        customer,
        tabs,
        virtualBrandId: form.value.virtualBrandId!,
        tab: {
          name: form.value.name,
          seats: form.value.seats
        }
      })
      loading.value = false
    }
  } else {
    emit('continue', {
      found: false,
      tab: {
        name: form.value.name,
        seats: form.value.seats,
        virtualBrandId: form.value.virtualBrandId!
      }
    })
  }
}

function manualExternalDelivery() {
  if (!isDeliveryComplete.value) return
  const { orderId, courierName, phoneNumber, name, ...rest } = form.value
  const tab: any = {
    id: uuid(),
    pickupType: 'ownDelivery',
    ...rest,
    name: orderId
  }
  tab.deliveryOrder = {
    courierName: form.value.courierName,
    pickupTime: form.value.schedulingTime,
    deliveryOrderStatuses: [
      {
        status: 'CREATED',
        creationTime: new Date()
      }
    ]
  }
  tab.customerInfo = {
    phoneNumber: phoneNumber,
    name: name
  }
  tab.pickupType = form.value.pickupType || 'delivery'
  openTab({ tableId: null, tab })
  emit('close')
  router.push({
    name: 'orderManagement',
    params: { tabId: tab.id }
  })
}
</script>

import moment from 'moment'
import i18n from '@/i18n'
import JsBarcode from 'jsbarcode'
import { useMoney } from '@/composables/useMoney'

const BODY_FONT = 'Nunito Sans'
const TITLE_FONT = 'Poppins'

function splitLines(ctx, text, maxWidth, firstMaxWidth) {
  if (!text) return []
  let words = String(text).split(' ')
  let line = ''
  let lines = []
  let currentMaxWidth = Math.max(firstMaxWidth, 0) || maxWidth

  for (let i = 0; i < words.length; i++) {
    let test = words[i]
    let metrics = ctx.measureText(test)
    while (metrics.width > currentMaxWidth) {
      // Determine how much of the word will fit
      test = test.substring(0, test.length - 1)
      metrics = ctx.measureText(test)
    }
    if (words[i] != test) {
      words.splice(i + 1, 0, words[i].substr(test.length))
      words[i] = test
    }

    test = line + words[i] + ' '
    metrics = ctx.measureText(test)
    if (metrics.width > currentMaxWidth && i > 0) {
      lines.push(line)
      currentMaxWidth = maxWidth
      line = words[i] + ' '
    } else {
      line = test
    }
  }

  lines.push(line)
  return lines
}

function wrapText(
  ctx,
  text,
  x,
  y,
  maxWidth,
  {
    lineHeight = 22,
    decreaseIndent = false,
    strike = false,
    firstMaxWidth
  } = {}
) {
  let lines = splitLines(ctx, text, maxWidth, firstMaxWidth)
  for (let i = 0; i < lines.length; ++i) {
    if (i > 0 && decreaseIndent) {
      x = 0
    }
    ctx.fillText(lines[i], x, y)
    if (strike) {
      let metrics = ctx.measureText(lines[i])
      ctx.fillRect(x, y - lineHeight / 2, metrics.width, 2)
    }
    y += lineHeight
  }
  return lines.length
}

function formatDate(date) {
  return moment(date).format('DD/MM/YYYY HH:mm')
}
function formatDateWithoutTime(date) {
  return moment(date).format('DD/MM/YYYY')
}
function formatCurrency(value) {
  const { currency } = useMoney()
  return currency(value)
}

class Logo {
  constructor(image, { ratio } = {}) {
    this.image = image
    this.ratio = ratio || 1
  }

  async loadImage(width) {
    this.imageNode = new Image()
    this.imageNode.crossOrigin = 'anonymous'
    this.imageNode.src = this.image
    let imageNode = this.imageNode
    let ratio = this.ratio

    this.imageHeight = await Promise.race([
      new Promise(resolve => {
        imageNode.onload = function () {
          let height = imageNode.height * (width / imageNode.width) * ratio
          resolve(height)
        }
      }),
      new Promise(resolve =>
        setTimeout(() => {
          return resolve(0)
        }, 500)
      )
    ])

    return this.imageHeight
  }

  async height(ctx, width) {
    if (!this.image || this.imageHeight === 0) return 0
    if (this.imageHeight) return this.imageHeight
    return await this.loadImage(width)
  }

  render(top, ctx, width) {
    if (!this.imageHeight) return
    let ratio = this.ratio
    let height = this.imageHeight
    ctx.drawImage(
      this.imageNode,
      width * 0.5 * (1 - ratio),
      top,
      width * ratio,
      height
    )
  }
}

class AdditionalInfo {
  constructor({ ticketInfo }) {
    this.lineHeight = 22
    this.topMargin = 20
    this.additionalInfo = ticketInfo
  }

  height(ctx, width) {
    if (!this.additionalInfo) return 0
    ctx.font = `14px ${BODY_FONT}`
    let lines = splitLines(ctx, this.additionalInfo, (2 / 3) * width).length
    return this.lineHeight * lines + this.topMargin
  }

  render(top, ctx, width) {
    if (!this.additionalInfo) return
    top += this.topMargin
    ctx.font = `14px ${BODY_FONT}`
    ctx.textAlign = 'center'
    ctx.fillStyle = 'black'
    let lines = wrapText(
      ctx,
      this.additionalInfo,
      width / 2,
      top,
      (2 / 3) * width
    )
    top += this.lineHeight * lines
  }
}

class Company {
  constructor(company) {
    this.company = company
    this.lineHeight = 22
    this.nameMargin = 10
    this.topMargin = 20
  }

  height(ctx, width) {
    if (!this.company.name || !this.company.taxId) return 0
    ctx.font = `16px ${BODY_FONT}`
    let singleLines = 2
    let lines =
      singleLines + splitLines(ctx, this.company.address, width).length
    return this.lineHeight * lines + this.nameMargin + this.topMargin
  }

  render(top, ctx, width) {
    if (!this.company.name || !this.company.taxId) return
    top += this.topMargin
    ctx.font = `bold 16px ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textAlign = 'center'
    ctx.fillText(this.company.name, width / 2, top)
    top += this.lineHeight + this.nameMargin
    ctx.font = `16px ${BODY_FONT}`
    ctx.fillText(this.company.taxId, width / 2, top)
    top += this.lineHeight
    wrapText(ctx, this.company.address, width / 2, top, width)
  }
}

class Title {
  constructor(text, { fontSize, height } = {}) {
    this.text = text
    this.paddingTop = 30
    this.paddingBottom = 20
    this.boxHeight = height || 60
    this.fontSize = fontSize || 30
  }

  height() {
    return this.paddingTop + this.boxHeight + this.paddingBottom
  }

  render(top, ctx, width) {
    top += this.paddingTop
    ctx.fillStyle = 'black'
    ctx.fillRect(0, top, width, this.boxHeight)

    ctx.font = `bold ${this.fontSize}px ${TITLE_FONT}`
    if (ctx.measureText(this.text).width > width) {
      this.fontSize = Math.floor(
        (this.fontSize * width) / ctx.measureText(this.text).width
      )
      ctx.font = `bold ${this.fontSize}px ${TITLE_FONT}`
    }

    ctx.fillStyle = 'white'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.fillText(this.text, width / 2, top + this.boxHeight / 2)
  }
}

class SubTitle {
  constructor(text, { fontSize } = {}) {
    this.text = text
    this.paddingTop = 4
    this.paddingBottom = 4
    this.fontSize = fontSize || 20
  }

  height() {
    return this.paddingTop + this.fontSize + this.paddingBottom
  }

  render(top, ctx, width) {
    top += this.paddingTop

    ctx.font = `bold ${this.fontSize}px ${BODY_FONT}`
    if (ctx.measureText(this.text).width > width) {
      this.fontSize = Math.floor(
        (this.fontSize * width) / ctx.measureText(this.text).width
      )
      ctx.font = `bold ${this.fontSize}px ${BODY_FONT}`
    }

    ctx.fillStyle = 'black'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.fillText(this.text, width / 2, top + this.fontSize / 2)
  }
}

class DoubleTitle {
  constructor(
    first,
    second,
    { firstStyle, secondStyle, firstLineHeight, secondLineHeight } = {}
  ) {
    this.first = first
    this.second = second
    this.paddingTop = 30
    this.paddingBottom = 20
    this.boxHeight = firstLineHeight + secondLineHeight
    this.firstFont = `${firstStyle || 'bold 60px'} ${TITLE_FONT}`
    this.secondFont = `${secondStyle || '40px'} ${BODY_FONT}`
    this.firstLineHeight = firstLineHeight || 70
    this.secondLineHeight = secondLineHeight || 45
  }

  height() {
    return this.paddingTop + this.boxHeight + this.paddingBottom
  }

  render(top, ctx, width) {
    top += this.paddingTop
    ctx.fillStyle = 'black'
    ctx.fillRect(0, top, width, this.boxHeight)
    ctx.font = this.font
    ctx.fillStyle = 'white'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.font = this.firstFont
    ctx.fillText(this.first, width / 2, top + this.firstLineHeight / 2)
    ctx.font = this.secondFont
    ctx.fillText(
      this.second,
      width / 2,
      top + this.firstLineHeight + this.secondLineHeight / 2
    )
  }
}

class Section {
  constructor(text) {
    this.text = text
    this.paddingTop = 30
    this.paddingBottom = 20
    this.boxHeight = 48
  }

  height() {
    return this.paddingTop + this.boxHeight + this.paddingBottom
  }

  render(top, ctx, width) {
    top += this.paddingTop
    ctx.fillStyle = 'black'
    ctx.fillRect(0, top, width, this.boxHeight)
    ctx.font = `bold 20px ${TITLE_FONT}`
    ctx.fillStyle = 'white'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'left'
    ctx.fillText(this.text, 15, top + this.boxHeight / 2)
  }
}

class Label {
  constructor(label, { style, valueType, lineHeight, align, baseline } = {}) {
    this.label = label
    this.style = style || 'bold 16px'
    this.valueType = valueType || 'string'
    this.lineHeight = lineHeight || 22
    this.align = align || 'left'
    this.baseline = baseline || 'middle'
    if (this.valueType === 'date') {
      this.formattedLabel = formatDate(this.label)
    } else if (this.valueType === 'currency') {
      this.formattedLabel = formatCurrency(this.label)
    } else {
      this.formattedLabel = String(this.label)
    }
  }

  height(ctx, width) {
    ctx.font = `${this.style} ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textBaseline = this.baseline
    ctx.textAlign = this.align
    return this.lineHeight * splitLines(ctx, this.formattedLabel, width).length
  }

  render(top, ctx, width) {
    let paddingTop = 20
    top += paddingTop
    ctx.font = `${this.style} ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textBaseline = this.baseline
    ctx.textAlign = this.align
    let left = 0
    if (this.align === 'center') {
      left = width / 2
    }
    wrapText(ctx, this.formattedLabel, left, top, width, {
      lineHeight: this.lineHeight,
      decreaseIndent: true
    })
  }
}

class InfoItem {
  constructor(
    label,
    value,
    { valueType, important, light, keepTogether, fontSize = 16 } = {}
  ) {
    this.label = label + ':'
    this.value = value
    this.valueType = valueType || 'string'
    this.important = important || false
    this.labelFont = light
      ? `${fontSize}px ${BODY_FONT}`
      : `bold ${fontSize}px ${BODY_FONT}`
    this.fontSize = fontSize
    this.lineHeight = fontSize * 1.5
    this.keepTogether = !!keepTogether
    if (this.valueType === 'date') {
      this.formattedValue = formatDate(this.value)
    } else if (this.valueType === 'currency') {
      this.formattedValue = formatCurrency(this.value)
    } else if (this.valueType === 'dateWithoutTime') {
      this.formattedValue = formatDateWithoutTime(this.value)
    } else {
      this.formattedValue = String(this.value)
    }
  }

  height(ctx, width) {
    if (this.value === undefined || this.value === null || this.value === '')
      return 0
    ctx.font = this.labelFont
    ctx.font = `${this.fontSize}px ${BODY_FONT}`
    ctx.textAlign = 'right'
    if (this.important) {
      ctx.font = `bold 18px ${BODY_FONT}`
    }
    let height
    if (!this.keepTogether) {
      let valueWidth = width - (ctx.measureText(this.label).width + 10)
      let linesLabel = splitLines(ctx, this.label, width)
      let linesValue = splitLines(ctx, this.formattedValue, width, valueWidth)
      height = this.lineHeight * (linesLabel.length + linesValue.length - 1)
    } else {
      let fullText = this.label + ' ' + this.formattedValue
      let fullTextWidth = width - (ctx.measureText(this.fullLabel).width + 10)
      let linesFullText = splitLines(ctx, fullText, width, fullTextWidth)
      height = this.lineHeight * linesFullText.length
    }
    return height
  }

  render(top, ctx, width) {
    if (this.value === undefined || this.value === null || this.value === '')
      return
    top += this.lineHeight
    ctx.font = this.labelFont
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    let valueWidth = width - (ctx.measureText(this.label).width + 10)
    ctx.font = `${this.fontSize}px ${BODY_FONT}`
    if (this.important) {
      ctx.font = `bold ${this.fontSize * 1.2}px ${BODY_FONT}`
    }
    if (!this.keepTogether) {
      wrapText(ctx, this.label, 0, top, width, {
        lineHeight: this.fontSize * 1.5
      })
      ctx.textAlign = 'right'
      let lines = splitLines(ctx, this.label, width)
      top += this.lineHeight * (lines.length - 1)
      wrapText(ctx, this.formattedValue, width, top, width, {
        firstMaxWidth: valueWidth
      })
    } else {
      ctx.fillText(this.label, 0, top)
      wrapText(
        ctx,
        this.formattedValue,
        ctx.measureText(this.label).width + 5,
        top,
        width,
        {
          lineHeight: this.lineHeight,
          decreaseIndent: true,
          firstMaxWidth: valueWidth
        }
      )
    }
  }
}

class DoubleInfoItem {
  constructor(
    firstLabel,
    firstValue,
    secondLabel,
    secondValue,
    { firstLabelFont, secondLabelFont, secondType } = {}
  ) {
    this.firstLabel = firstLabel + ':'
    this.firstValue = firstValue
    this.secondLabel = secondLabel + ':'
    this.secondValue = secondValue
    this.secondType = secondType || 'string'
    this.firstLabelFont = firstLabelFont || `bold 16px ${BODY_FONT}`
    this.secondLabelFont = secondLabelFont || `bold 16px ${BODY_FONT}`
    this.font = '16px ${BODY_FONT}'
    this.lineHeight = 25
    if (this.secondType === 'date') {
      this.secondValue = formatDate(this.secondValue)
    } else if (this.valueType === 'currency') {
      this.secondValue = formatCurrency(this.secondValue)
    }
  }

  height(ctx, width) {
    if (!this.firstValue && !this.secondValue) {
      return 0
    }
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    ctx.font = this.firstLabelFont
    let fullFirstValue = this.firstLabel + ' ' + this.firstValue + ' '
    let fullSecondValue = this.secondLabel + ' ' + this.secondValue
    if (splitLines(ctx, fullFirstValue + fullSecondValue, width).length === 1) {
      // Both first item and second item fit in a line, total height will be one line
      return this.lineHeight * 1
    } else {
      // First and second items don't fit in one line, so total lines is the sum of the lines of each
      return (
        this.lineHeight *
        (splitLines(ctx, fullFirstValue, width).length +
          splitLines(ctx, fullSecondValue, width).length)
      )
    }
  }

  render(top, ctx, width) {
    if (!this.firstValue && !this.secondValue) return
    top += this.lineHeight
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    let fullFirstValue = this.firstLabel + ' ' + this.firstValue + ' '
    let fullSecondValue = this.secondLabel + ' ' + this.secondValue
    ctx.font = this.firstLabelFont
    let bothItemsDontFitInOneLine =
      splitLines(ctx, fullFirstValue + fullSecondValue, width).length > 1
    if (bothItemsDontFitInOneLine) {
      ctx.fillText(this.firstLabel, 0, top)
      ctx.font = this.font
      let firstItemLines = wrapText(
        ctx,
        this.firstValue,
        ctx.measureText(this.firstLabel).width + 5,
        top,
        width - (ctx.measureText(this.firstLabel).width + 10),
        {
          lineHeight: this.lineHeight,
          decreaseIndent: true
        }
      )
      ctx.font = this.secondLabelFont
      ctx.fillText(this.secondLabel, 0, top + this.lineHeight * firstItemLines)
      ctx.font = this.font
      wrapText(
        ctx,
        this.secondValue,
        ctx.measureText(this.secondLabel).width + 5,
        top + this.lineHeight * firstItemLines,
        width - (ctx.measureText(this.secondLabel).width + 10),
        {
          lineHeight: this.lineHeight,
          decreaseIndent: true
        }
      )
    } else {
      if (this.firstValue) {
        ctx.font = this.firstLabelFont
        ctx.fillText(this.firstLabel, 0, top)
        ctx.font = this.font
        ctx.fillText(
          this.firstValue,
          ctx.measureText(this.firstLabel).width + 10,
          top
        )
        if (this.secondValue) {
          ctx.textAlign = 'right'
          ctx.fillText(this.secondValue, width, top)
          ctx.font = this.secondLabelFont
          ctx.fillText(
            this.secondLabel,
            width - (ctx.measureText(this.secondValue).width + 10),
            top
          )
        }
      } else {
        ctx.font = this.secondLabelFont
        ctx.fillText(this.secondLabel, 0, top)
        ctx.font = this.font
        ctx.fillText(
          this.secondValue,
          ctx.measureText(this.secondLabel).width + 10,
          top
        )
      }
    }

    if (this.important) {
      ctx.font = `bold 18px ${BODY_FONT}`
    }
  }
}

class DoubleLabel {
  constructor(first, second, { firstStyle, secondStyle, secondType } = {}) {
    this.first = first
    this.second = second
    this.firstStyle = firstStyle
    this.secondStyle = secondStyle
    this.lineHeight = 30
    if (secondType === 'date') {
      this.formattedSecond = formatDate(this.second)
    } else if (this.secondType === 'currency') {
      this.formattedSecond = formatCurrency(this.second)
    } else {
      this.formattedSecond = String(this.second)
    }
  }

  height(ctx, width) {
    if (!this.first || !this.second) return 0
    ctx.font = this.firstStyle || `16px ${BODY_FONT}`
    let valueWidth = width - (ctx.measureText(this.first).width + 10)
    ctx.textAlign = 'right'
    return (
      this.lineHeight * splitLines(ctx, this.formattedSecond, valueWidth).length
    )
  }

  render(top, ctx, width) {
    if (!this.first || !this.second) return
    top += this.lineHeight
    ctx.font = this.firstStyle || `16px ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    ctx.fillText(this.first, 0, top)
    let valueWidth = width - (ctx.measureText(this.first).width + 10)
    ctx.font = this.secondStyle || `16px ${BODY_FONT}`
    ctx.textAlign = 'right'
    if (this.important) {
      ctx.font = `bold 18px ${BODY_FONT}`
    }
    wrapText(ctx, this.formattedSecond, width, top, valueWidth)
  }
}

class Tab {
  constructor(code, date, total) {
    this.code = code
    this.date = formatDate(date)
    this.total = formatCurrency(total)
    this.lineHeight = 25
  }

  height() {
    return this.lineHeight
  }

  render(top, ctx, width) {
    if (!this.code) return
    top += this.lineHeight
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    ctx.font = `bold 18px ${BODY_FONT}`
    ctx.fillText(this.code, 0, top)
    ctx.font = `16px ${BODY_FONT}`
    ctx.textAlign = 'center'
    ctx.fillText(this.date, width / 2, top)
    let valueWidth = width - (ctx.measureText(this.code).width + 10)
    ctx.textAlign = 'right'
    wrapText(ctx, this.total, width, top, valueWidth)
  }
}

class Total {
  constructor(value, diners, type) {
    this.label =
      (type === 'with-tips'
        ? i18n.global.t('bill.total-without-tips')
        : i18n.global.t('bill.total')) + ':'
    this.fontSize = type === 'with-tips' ? 22 : 26
    this.value = value
    this.lineHeight = 25
    this.formattedValue = formatCurrency(this.value)
    this.paddingTop = 40
    this.diners = diners
    this.isFullBill = type === 'total'
    this.secondaryLabel = i18n.global.t('bill.total-per-person')
  }

  height(ctx, width) {
    ctx.font = `bold ${this.fontSize}px ${BODY_FONT}`
    let valueWidth = width - (ctx.measureText(this.label).width + 10)
    ctx.textAlign = 'right'
    let height =
      this.lineHeight *
        splitLines(ctx, this.formattedValue, valueWidth).length +
      this.paddingTop
    if (this.diners > 1 && this.isFullBill) height += this.lineHeight
    return height
  }

  render(top, ctx, width) {
    if (!this.value) return
    top += this.paddingTop
    ctx.font = `bold ${this.fontSize}px ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    ctx.fillText(this.label, 0, top)
    let valueWidth = width - (ctx.measureText(this.label).width + 10)
    ctx.textAlign = 'right'
    wrapText(ctx, this.formattedValue, width, top, valueWidth)
    if (this.diners > 1 && this.isFullBill) {
      this.secondaryLabel += ` (${this.diners}) :`
      top +=
        splitLines(ctx, this.formattedValue, valueWidth).length *
        this.lineHeight
      ctx.font = `16px ${BODY_FONT}`
      ctx.textAlign = 'left'
      ctx.fillText(this.secondaryLabel, 0, top)
      ctx.textAlign = 'right'
      valueWidth = width - (ctx.measureText(this.secondaryLabel).width + 10)
      wrapText(
        ctx,
        formatCurrency(Math.ceil(this.value / this.diners)),
        width,
        top,
        valueWidth
      )
    }
  }
}

class Separator {
  constructor({ size = 1, padding = 20 } = {}) {
    this.padding = padding
    this.lineHeight = size
  }

  height() {
    return this.padding * 2 + this.lineHeight
  }

  render(top, ctx, width) {
    ctx.fillStyle = 'black'
    ctx.fillRect(0, top + this.padding, width, this.lineHeight)
    return this.padding * 2 + this.lineHeight
  }
}

class DottedSeparator {
  constructor({ size = 1, padding = 20 } = {}) {
    this.padding = padding
    this.lineHeight = size
  }

  height() {
    return this.padding * 2 + this.lineHeight
  }

  render(top, ctx, width) {
    ctx.fillStyle = 'black'
    ctx.setLineDash([3, 3])
    ctx.moveTo(0, top + this.padding)
    ctx.lineTo(width, top + this.padding)
    ctx.stroke()
    ctx.setLineDash([])
    return this.padding * 2 + this.lineHeight
  }
}

class EmptySeparator {
  constructor({ lineHeight } = {}) {
    this.lineHeight = lineHeight || 22
  }

  height() {
    return this.lineHeight
  }

  render() {
    //No render, just space
  }
}

class KitchenProduct {
  constructor(
    product,
    pickupType,
    { fontSize = 24, showQuantity = true } = {}
  ) {
    this.product = product
    this.pickupType = pickupType
    this.quantityWidth = 50
    this.priceWidth = 50
    this.lineHeight = fontSize
    this.paddingBottom = 10
    this.fontSize = fontSize
    this.showQuantity = showQuantity
    if (!showQuantity) this.quantityWidth = 0
  }

  productName() {
    return this.product.name
  }

  height(ctx, width) {
    ctx.font = this.font({ bold: true })
    let quantityWidth = this.quantityWidth
    if (!this.product.quantity.current) {
      quantityWidth *= 2
    }
    let infoWidth = width - quantityWidth
    let nameLines = splitLines(ctx, this.productName(), infoWidth).length
    let modifierLines = this.modifierLines()
      .map(modifier => {
        if (modifier.modifier.added) {
          ctx.font = this.font({ bold: true, small: true })
        } else {
          ctx.font = this.font({ small: true })
        }
        return splitLines(ctx, modifier.text, infoWidth).length
      })
      .reduce((total, lines) => (total += lines), 0)
    let comments = this.product?.comments?.text || ''
    ctx.font = this.font({ small: true })
    let commentsLines = splitLines(ctx, comments, infoWidth).length
    return (
      (nameLines + modifierLines + commentsLines) * this.lineHeight +
      this.paddingBottom
    )
  }

  modifierLines(modifiers, prefix = '') {
    return (modifiers || this.product.modifiers).map(modifier => {
      let modifierLine = prefix + '+ ' + modifier.name
      if (modifier.quantity > 1) {
        modifierLine += ' x ' + modifier.quantity
      }
      return { text: modifierLine, modifier }
    })
  }

  font({ bold = false, small = false } = {}) {
    let fontSize = this.fontSize
    if (small) fontSize *= 0.8
    let font = `${fontSize}px ${BODY_FONT}`
    if (bold) font = 'bold ' + font
    return font
  }

  render(top, ctx, width) {
    let paddingTop = 20
    top += paddingTop
    ctx.font = this.font({ bold: true })
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    let quantityWidth = this.quantityWidth
    if (this.showQuantity) {
      if (this.product.quantity.current) {
        ctx.fillText(this.product.quantity.current + 'x', 0, top)
      } else {
        ctx.fillRect(0, top - 13, quantityWidth, 2)
        ctx.font = this.font()
        ctx.fillText(this.product.quantity.old + 'x', 0, top)
        ctx.font = this.font({ bold: true })
        ctx.fillText(this.product.quantity.new + 'x', quantityWidth, top)
        quantityWidth *= 2
        ctx.font = this.font()
      }
    }
    ctx.textAlign = 'right'
    ctx.font = this.font()
    ctx.textAlign = 'left'
    let infoWidth = width - quantityWidth
    top +=
      wrapText(ctx, this.productName(), quantityWidth, top, infoWidth, {
        strike: this.product.deleted
      }) * this.lineHeight
    this.modifierLines().forEach(modifierLine => {
      if (modifierLine.modifier.added) {
        ctx.font = this.font({ bold: true, small: true })
      } else {
        ctx.font = this.font({ small: true })
      }
      top +=
        wrapText(ctx, modifierLine.text, quantityWidth, top, infoWidth, {
          strike: modifierLine.modifier.deleted || this.product.deleted
        }) * this.lineHeight
    })
    ctx.font = this.font({ small: true })
    top +=
      wrapText(ctx, this.product.comments.text, quantityWidth, top, infoWidth, {
        strike: this.product.deleted
      }) * this.lineHeight
  }
}

class Product {
  constructor(product, pickupType) {
    this.product = product
    this.pickupType = pickupType
    this.quantityWidth = 35
    this.priceWidth = 50
    this.lineHeight = 22
    this.paddingBottom = 10
  }

  height(ctx, width) {
    let infoWidth = width - this.quantityWidth - this.priceWidth
    let nameLines = splitLines(ctx, this.product.name, infoWidth).length
    let modifierLines = this.modifierLines()
      .map(modifier => splitLines(ctx, modifier, infoWidth).length)
      .reduce((total, lines) => (total += lines), 0)
    let comboProductLines = this.comboProductLines()
      .map(comboProduct => splitLines(ctx, comboProduct, infoWidth).length)
      .reduce((total, lines) => (total += lines), 0)
    let commentsLines = 0
    if (this.pickupType) {
      commentsLines = splitLines(ctx, this.product.comments, infoWidth).length
    }
    let discountLines = this.product.discountType ? 1 : 0
    return (
      (nameLines +
        modifierLines +
        discountLines +
        comboProductLines +
        commentsLines) *
        this.lineHeight +
      this.paddingBottom
    )
  }

  modifierLines(modifiers, prefix = '') {
    return (modifiers || this.product.modifiers).map(modifier => {
      let modifierLine = prefix + '+ ' + modifier.name
      if (modifier.quantity > 1) {
        modifierLine += ' x ' + modifier.quantity
      }
      return modifierLine
    })
  }

  comboProductLines() {
    return (this.product.comboProducts || []).flatMap(comboProduct => {
      let prefix = '  '
      let comboProductLine = prefix + comboProduct.name
      if (comboProduct.quantity > 1) {
        comboProductLine += ' x ' + comboProduct.quantity
      }
      return [
        comboProductLine,
        ...this.modifierLines(comboProduct.modifiers, prefix)
      ]
    })
  }

  render(top, ctx, width) {
    let paddingTop = 20
    top += paddingTop
    ctx.font = `bold 16px ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'left'
    ctx.fillText(this.product.quantity + 'x', 0, top)
    ctx.textAlign = 'right'
    if (this.product.discountType) {
      ctx.font = `16px ${BODY_FONT}`
      let formattedPrice = formatCurrency(this.product.originalPrice)
      ctx.fillText(formattedPrice, width, top)
      let size = ctx.measureText(formattedPrice)
      ctx.fillRect(width - size.width, top - this.lineHeight / 2, size.width, 2)
      ctx.font = `bold 16px ${BODY_FONT}`
      ctx.fillText(
        formatCurrency(this.product.price),
        width,
        top + this.lineHeight
      )
    } else {
      ctx.fillText(formatCurrency(this.product.price), width, top)
    }
    ctx.font = `16px ${BODY_FONT}`
    ctx.textAlign = 'left'
    let infoWidth = width - this.quantityWidth - this.priceWidth
    top +=
      wrapText(ctx, this.product.name, this.quantityWidth, top, infoWidth) *
      this.lineHeight
    if (this.product.discountType) {
      ctx.font = `bold 16px ${BODY_FONT}`
      let discountLabel = i18n.global.t('bill.discount')
      if (this.product.discountType === '2x1') {
        discountLabel += ' 2x1'
      } else if (this.product.discountType === 'percentage') {
        let amount = formatCurrency(
          this.product.originalPrice - this.product.price
        )
        discountLabel += ` ${this.product.discountAmount}% (-${amount})`
      } else {
        discountLabel += ' ' + formatCurrency(this.product.discountAmount)
      }
      top +=
        wrapText(ctx, discountLabel, this.quantityWidth, top, infoWidth) *
        this.lineHeight
    }
    ctx.font = `16px ${BODY_FONT}`
    this.modifierLines().forEach(modifier => {
      top +=
        wrapText(ctx, modifier, this.quantityWidth, top, infoWidth) *
        this.lineHeight
    })
    this.comboProductLines().forEach(comboProduct => {
      top +=
        wrapText(ctx, comboProduct, this.quantityWidth, top, infoWidth) *
        this.lineHeight
    })
    if (!!this.pickupType && this.product.comments) {
      top +=
        wrapText(
          ctx,
          this.product.comments,
          this.quantityWidth,
          top,
          infoWidth
        ) * this.lineHeight
    }
  }
}

class Box {
  constructor(title, content) {
    this.padding = 10
    this.marginTop = 20
    this.titleMarginBottom = 5
    this.lineHeight = 22
    this.title = title?.toUpperCase()
    this.content = content
  }

  height(ctx, width) {
    if (!this.title && !this.content) return 0
    ctx.font = `bold 16px ${BODY_FONT}`
    ctx.textAlign = 'left'
    let messageWidth = width - this.padding
    let lines = splitLines(ctx, this.title, messageWidth).length
    ctx.font = `16px ${BODY_FONT}`
    let contentLines = splitLines(ctx, this.content, messageWidth).length
    return (
      this.marginTop +
      this.titleMarginBottom +
      this.padding * 2 +
      this.lineHeight * (lines + contentLines)
    )
  }

  render(top, ctx, width) {
    ctx.fillStyle = 'black'
    ctx.font = `bold 16px ${BODY_FONT}`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    top += this.marginTop
    let messageWidth = width - this.padding * 2
    let height =
      wrapText(
        ctx,
        this.title,
        width / 2,
        top + this.padding + this.lineHeight / 2,
        messageWidth
      ) * this.lineHeight
    ctx.font = `14px ${BODY_FONT}`
    let contentHeight =
      wrapText(
        ctx,
        this.content,
        width / 2,
        top +
          height +
          this.titleMarginBottom +
          this.padding +
          this.lineHeight / 2,
        messageWidth
      ) * this.lineHeight
    ctx.strokeRect(
      0,
      top,
      width,
      height + contentHeight + this.titleMarginBottom + this.padding * 2
    )
  }
}

class PaymentsBox {
  constructor(
    title,
    payments,
    { border = true, padding = 20, important = false } = {}
  ) {
    this.padding = padding
    this.marginTop = 20
    this.titleMarginBottom = 10
    this.lineHeight = 22
    this.title = important ? title.toUpperCase() : title
    this.payments = payments
    this.border = border
    this.important = important
    this.titleFont = important ? `bold 18px ${BODY_FONT}` : `18px ${BODY_FONT}`
    this.methodFont = important ? `20px ${BODY_FONT}` : `bold 14px ${BODY_FONT}`
    this.amountFont = important ? `bold 22px ${BODY_FONT}` : `14px ${BODY_FONT}`
  }

  height(ctx, width) {
    ctx.font = this.titleFont
    ctx.textAlign = this.important ? 'center' : 'left'
    let messageWidth = width - this.padding
    let lines = splitLines(ctx, this.title, messageWidth).length
    ctx.font = this.font
    let paymentLines = this.payments.reduce((acc, payment) => {
      return (
        acc +
        !!(payment.change || (payment.tip && this.payments.length > 1)) +
        1
      )
    }, 0)
    return (
      this.marginTop +
      this.titleMarginBottom +
      this.padding * 2 +
      this.lineHeight * (lines + paymentLines)
    )
  }

  render(top, ctx, width) {
    ctx.textBaseline = 'middle'
    ctx.font = this.titleFont
    ctx.textAlign = this.important ? 'center' : 'left'
    top += this.marginTop
    let messageWidth = width - this.padding * 2
    let height =
      wrapText(
        ctx,
        this.title,
        this.important ? width / 2 : this.padding,
        top + this.padding + this.lineHeight / 2,
        messageWidth
      ) * this.lineHeight
    ctx.font = this.font
    let paymentsHeight
    let firstPaymentPosition =
      top + height + this.titleMarginBottom + this.padding + this.lineHeight / 2
    let lineCounter = 0
    for (let i = 0; i < this.payments.length; ++i) {
      ctx.font = this.methodFont
      ctx.textAlign = 'left'
      ctx.fillText(
        this.payments[i].label.charAt(0).toUpperCase() +
          this.payments[i].label.slice(1) +
          ':',
        this.padding,
        firstPaymentPosition + lineCounter * this.lineHeight
      )
      ctx.font = this.amountFont
      ctx.textAlign = 'right'
      ctx.fillText(
        formatCurrency(
          this.payments[i].amount -
            (this.payments[i].tip || 0) +
            (this.payments[i].change || 0)
        ),
        width - this.padding,
        firstPaymentPosition + lineCounter * this.lineHeight
      )
      if (
        this.payments[i].change ||
        (this.payments[i].tip && this.payments.length > 1)
      ) {
        lineCounter++
        ctx.font = `14px ${BODY_FONT}`
        ctx.textAlign = 'left'
        ctx.fillText(
          this.payments[i].change
            ? i18n.global.t('checkout.change')
            : i18n.global.t('checkout.tip'),
          this.padding + 10,
          firstPaymentPosition + lineCounter * this.lineHeight
        )
        ctx.font = this.amountFont
        ctx.textAlign = 'right'
        ctx.fillText(
          formatCurrency(this.payments[i].change || this.payments[i].tip),
          width - this.padding,
          firstPaymentPosition + lineCounter * this.lineHeight
        )
      }
      lineCounter++
    }
    paymentsHeight = this.payments.length * this.lineHeight
    if (this.border) {
      ctx.strokeRect(
        0,
        top,
        width,
        height + paymentsHeight + this.titleMarginBottom + this.padding * 2
      )
    }
  }
}

class If {
  constructor(condition, trueComponent, falseComponent) {
    this.condition = condition
    this.trueComponent = trueComponent
    this.falseComponent = falseComponent
  }

  height(ctx, width) {
    if (this.condition) {
      return this.trueComponent.height(ctx, width)
    } else if (this.falseComponent) {
      return this.falseComponent.height(ctx, width)
    }
    return 0
  }

  render(top, ctx, width) {
    if (this.condition) {
      this.trueComponent.render(top, ctx, width)
    } else if (this.falseComponent) {
      this.falseComponent.render(top, ctx, width)
    }
  }
}

class Barcode {
  constructor(code) {
    this.code = code
    this.barcodeHeight = 100
    this.paddingTop = 25
  }

  height() {
    return this.barcodeHeight + this.paddingTop
  }

  render(top, ctx, width) {
    top += this.paddingTop
    let canvas = document.createElement('canvas')
    JsBarcode(canvas, this.code, {
      width,
      height: this.barcodeHeight,
      displayValue: false
    })
    ctx.drawImage(canvas, 0, top, width, this.barcodeHeight)
  }
}

class TillBreakdown {
  constructor(methods) {
    this.methods = methods
    this.lineHeight = 22
    this.separatorPadding = 20
    this.topPadding = 30

    this.totalExpectedEndAmount = Object.values(methods).reduce(
      (total, shift) => {
        total += shift.expectedEndAmount
        return total
      },
      0
    )

    this.totalEndAmount = Object.values(methods).reduce((total, shift) => {
      total += shift.endAmount
      return total
    }, 0)
  }

  height() {
    return (
      this.lineHeight * Object.keys(this.methods).length +
      this.lineHeight +
      this.separatorPadding +
      this.topPadding
    )
  }

  render(top, ctx, width) {
    top += this.topPadding
    ctx.font = `bold 12px ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textAlign = 'right'
    ctx.fillText(i18n.global.t('report.difference'), width, top)
    ctx.fillText(i18n.global.t('report.declared'), width * 0.75, top)
    ctx.fillText(i18n.global.t('report.calculated'), width * 0.5, top)
    Object.keys(this.methods).forEach(method => {
      top += this.lineHeight
      ctx.font = `12px ${BODY_FONT}`
      let difference =
        this.methods[method].endAmount - this.methods[method].expectedEndAmount
      formatCurrency(this.methods[method].expectedEndAmount)
      ctx.textAlign = 'right'
      ctx.fillText(formatCurrency(difference), width, top)
      ctx.fillText(
        formatCurrency(this.methods[method].endAmount),
        width * 0.75,
        top
      )
      ctx.fillText(
        formatCurrency(this.methods[method].expectedEndAmount),
        width * 0.5,
        top
      )
      ctx.textAlign = 'left'
      ctx.font = `bold 12px ${BODY_FONT}`
      ctx.fillText(method.charAt(0).toUpperCase() + method.slice(1), 0, top)
    })
    ctx.fillStyle = 'black'
    ctx.font = `bold 12px ${BODY_FONT}`
    top += this.separatorPadding
    ctx.fillRect(0, top, width, 1)
    top += this.lineHeight
    ctx.fillText(i18n.global.t('report.total') + ':', 0, top)
    ctx.textAlign = 'right'
    ctx.fillText(
      formatCurrency(this.totalEndAmount - this.totalExpectedEndAmount),
      width,
      top
    )
    ctx.fillText(formatCurrency(this.totalEndAmount), width * 0.75, top)
    ctx.fillText(formatCurrency(this.totalExpectedEndAmount), width * 0.5, top)
  }
}

class TaxesBreakdown {
  constructor(taxRates, taxLabel) {
    this.taxRates = taxRates
    this.taxLabel = taxLabel
    this.lineHeight = 22
    this.separatorPadding = 20
    this.topPadding = 10
  }

  height() {
    return (
      this.lineHeight * this.taxRates.length + this.lineHeight + this.topPadding
    )
  }

  render(top, ctx, width) {
    top += this.topPadding
    ctx.font = `12px ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textAlign = 'right'
    ctx.fillText(i18n.global.t('report.total'), width, top)
    ctx.fillText(this.taxLabel, width * 0.75, top)
    ctx.fillText(i18n.global.t('report.subtotal'), width * 0.5, top)
    this.taxRates.forEach(taxRate => {
      top += this.lineHeight
      ctx.textAlign = 'right'
      ctx.font = `bold 16px ${BODY_FONT}`
      ctx.fillText(formatCurrency(taxRate.total), width, top)
      ctx.font = `16px ${BODY_FONT}`
      ctx.fillText(formatCurrency(taxRate.tax), width * 0.75, top)
      ctx.fillText(formatCurrency(taxRate.taxableBase), width * 0.5, top)
      ctx.textAlign = 'left'
      ctx.fillText(`${this.taxLabel} (${taxRate.taxPercentage}%):`, 0, top)
    })
  }
}

class MovementsBreakdown {
  constructor(movements) {
    this.movements = movements
    this.lineHeight = 22
    this.separatorPadding = 20
    this.topPadding = 30
    this.totalEndAmount = movements.reduce((total, movement) => {
      total += movement.diff
      return total
    }, 0)
  }

  height() {
    return (
      this.lineHeight * this.movements.length +
      this.lineHeight +
      this.separatorPadding +
      this.topPadding
    )
  }

  render(top, ctx, width) {
    ctx.font = `bold 12px ${BODY_FONT}`
    ctx.fillStyle = 'black'
    ctx.textAlign = 'right'
    ctx.fillText(i18n.global.t('movements-report.balance'), width, top)
    this.movements.forEach(movement => {
      ctx.textAlign = 'right'
      let inOrOut =
        movement.diff < 0
          ? i18n.global.t('movements-report.pay-out')
          : i18n.global.t('movements-report.pay-in')
      top += this.lineHeight

      ctx.font = `12px ${BODY_FONT}`
      ctx.fillText(formatCurrency(movement.diff), width, top)
      ctx.fillText(
        moment(movement.creationTime).format('HH:mm'),
        width * 0.5,
        top
      )
      ctx.textAlign = 'left'
      ctx.font = `bold 12px ${BODY_FONT}`
      ctx.fillText(movement.tableName || movement.tabCode || inOrOut, 0, top)
    })
    ctx.fillStyle = 'black'
    ctx.font = `bold 12px ${BODY_FONT}`
    top += this.separatorPadding
    ctx.fillRect(0, top, width, 1)
    top += this.lineHeight
    ctx.fillText(i18n.global.t('report.total') + ':', 0, top)
    ctx.textAlign = 'right'
    ctx.fillText(formatCurrency(this.totalEndAmount), width, top)
  }
}

export {
  Logo,
  Company,
  AdditionalInfo,
  Title,
  SubTitle,
  DoubleTitle,
  InfoItem,
  Section,
  Separator,
  Label,
  DoubleLabel,
  DoubleInfoItem,
  EmptySeparator,
  Product,
  Box,
  PaymentsBox,
  If,
  Total,
  Barcode,
  Tab,
  TillBreakdown,
  KitchenProduct,
  MovementsBreakdown,
  DottedSeparator,
  TaxesBreakdown
}
